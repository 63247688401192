import * as React from "react";
import { Grid, Card, Typography, Box, Button, Link } from "@mui/material";
import logoMisiones from "../../assets/images/logoMisiones.png";
import { tryGetUserManual } from "./commonSlice";
import { useDispatch, useSelector } from "react-redux";
import AppLoading from "./appLoading";
import VersionModal from "./versionModal";
import { useState } from "react";
import { tryGetApiVersions } from "./commonSlice";
import appSettings from "./../../appSettings";
import ConfettiExplosion from "react-confetti-explosion";

const Footer = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector((state) => state?.common?.isFetching);
  const [isExploding, setIsExploding] = React.useState(false);
  const handleGoToUserManual = () => {
    setIsExploding(true);
    dispatch(tryGetUserManual());
  };
  const [showModal, setShowModal] = useState(false);
  const cfgVersion = appSettings?.appSettings;

  const handleOpenModal = () => {
    dispatch(tryGetApiVersions());
    setShowModal(true);
  }

  const handleClose = () => {
    setShowModal(false);
  }

  return (
    <Card
      variant="footerCard"
      sx={{
        mt: "5rem",
        width: { xs: "100vw", sm: "100vw", md: "90vw", lg: "80vw", xl: "70vw" },
      }}
    >
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justify="center"
        variant="custom"
      >
        <Grid item md={6} sx={{ textAlign: "start" }}>
          <img src={logoMisiones} width="300" alt="logo-misiones"></img>
        </Grid>
        <Grid
          container
          direction="row"
          spacing={0}
          alignItems="start"
          justify="end"
          md={6}
          sx={{ textAlign: "start", justifyContent: "space-between" }}
        >
          <Grid item sx={{ mr: "2rem", height: "6rem" }}>
            {isFetching ? (
              <Box sx={{ mt: "0rem" }}>
                <AppLoading size={5} />
              </Box>
            ) : (
              <Grid>
                <Button
                  onClick={handleGoToUserManual}
                  underline="none"
                  variant="title3"
                  className="manual-button"
                  sx={{
                    border: "1px solid rgba(255, 255, 255, 0.2)",
                    borderRadius: "5rem",
                    textTransform: "none",
                    textAlign: "left",
                    color: "white",
                    fontSize: "1.6rem",
                    boxShadow: "0rem 0.2rem 1rem rgba(0, 0, 0, 0.25)",
                    mb: "2rem",
                    background:
                      "linear-gradient(200deg, rgba(28,60,107,1) 12%, rgba(28,166,224,1) 100%)",
                    transition: "background 0.3s ease",
                    "&:hover": {
                      color: "white",
                      background:
                        "linear-gradient(198deg, rgba(23,48,84,1) 12%, rgba(26,139,186,1) 100%)",
                      transition: "background 0.3s ease",
                    },
                  }}
                >
                  Manual de Usuario
                  <>
                    {isExploding && (
                      <ConfettiExplosion
                        colors={[
                          "#ea316d",
                          "#1c3c6b",
                          "#0976b5",
                          "#1ca6e0",
                          "#b3daec",
                        ]}
                      />
                    )}
                  </>
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Typography
              gutterBottom
              variant="title3"
              component="div"
              justifyContent="center"
              sx={{ color: "white" }}
            >
              Datos de contacto
            </Typography>
            <Typography
              variant="textParagraph"
              sx={{ fontWeight: "300", color: "white" }}
            >
              Sistema de Gestión de Proveedores
            </Typography>
            <br />
            <Typography
              variant="textParagraph"
              sx={{ fontWeight: "300", color: "white" }}
            >
              Contaduría General de Misiones
            </Typography>
            <br />
            <Typography
              variant="textParagraph"
              sx={{ fontWeight: "300", color: "white" }}
            >
              Av. Polonia Nº1223 - CP 3300TE
            </Typography>
            <br />
            <Typography
              variant="textParagraph"
              sx={{ fontWeight: "400", color: "white" }}
            >
              (0376) 4447965 / 4447520
            </Typography>
            <br />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          mt: "2rem",
          ml: "-5rem",
          mb: "-2rem",
          width: {
            xs: "100vw",
            sm: "100vw",
            md: "90vw",
            lg: "80vw",
            xl: "70vw",
          },
          backgroundColor: "rgba(0,0,0,0.3)",
          justifyContent: "end",
          // boxShadow: "0px -10px 10px rgba(0, 0, 0, 0.3)",
        }}
      >
        <Typography variant="textParagraph" sx={{ color: "white", mr: "5rem" }}>
          Sistema de Gestión Proveedores -{" "}
          <Link
            onClick={() => handleOpenModal()}
            variant="custom"
            sx={{ color: "white" }}
          >
            v{cfgVersion?.version}
          </Link>
        </Typography>
      </Grid>
      <VersionModal
        showModal={showModal}
        handleClose={() => handleClose()}
        cfgVersion={cfgVersion}
      ></VersionModal>
    </Card>
  );
};

export default Footer;