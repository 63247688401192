import { Delete } from "@mui/icons-material";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import TablePaginationActions from "../../common/tablePagination";
import { removeMember } from "./membersUploadSlice";
import { maskCUIT, showSuccess } from "../../../utils/utils";

const MembersUploadTable = (props) => {
  const { membersList } = props;
  const dispatch = useDispatch();

  const rows =
    membersList?.length > 0
      ? membersList
      : [{ id: 0, cuit: "-", name: "-", memberType: { id: 0, label: "-" } }];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const handleDelete = (id) => {
    dispatch(removeMember(id));
    showSuccess("Integrante eliminado con éxito.", "");
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",
        backgroundColor: "rgba(255,255,255,0.3)",
        borderRadious: "1rem",
      }}
    >
      <Table sx={{ minWidth: "40rem" }}>
        <TableHead sx={{ textAlign: "center" }}>
          <TableRow>
            <TableCell>CUIT</TableCell>
            <TableCell>Nombre y Apellido</TableCell>
            <TableCell>Cargo</TableCell>
            <TableCell sx={{ textAlign: "center" }}>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ textAlign: "center" }}>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row, index) => (
            <Tooltip
              variant="custom"
              placement="top-end"
              arrow
              // followCursor={true}
              title={
                <Typography fontSize="1.2rem">
                  <bold>Provincia:</bold> {row?.domiciles?.provinceId?.label}
                  <br />
                  <bold>Localidad:</bold> {row?.domiciles?.localityId?.label}
                  <br />
                  <bold>Dirección:</bold> {row?.domiciles?.address}
                </Typography>
              }
            >
              <TableRow key={index} size="small">
                <TableCell size="small">{maskCUIT(row?.cuit)}</TableCell>
                <TableCell size="small">{row?.name}</TableCell>
                <TableCell size="small">{row?.memberType?.label}</TableCell>
                <TableCell size="small" sx={{ textAlign: "center" }}>
                  {row?.cuit !== "-" && (
                    <Tooltip
                      title={
                        <Typography fontSize="1.2rem">
                          Eliminar integrante
                        </Typography>
                      }
                    >
                      <IconButton
                        aria-label="Eliminar integrante"
                        component="label"
                        onClick={() => {
                          handleDelete(row?.id);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            </Tooltip>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "Todo", value: -1 }]}
              colSpan={4}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "Elementos por página",
                },
                native: true,
              }}
              labelRowsPerPage={"Cantidad de filas"}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              sx={{
                ".MuiTablePagination-toolbar": {
                  fontSize: "1.2rem",
                },
                ".MuiTablePagination-displayedRows": {
                  fontSize: "1.2rem",
                },
                ".MuiTablePagination-selectLabel": {
                  fontSize: "1.2rem",
                },
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default MembersUploadTable;
