import { createTheme } from "@mui/material";
//import { experimental_sx as sx } from "@mui/material";

export const primaryColor = "#1B3A65";
export const primaryColorHover = "#152C4C";
export const lightPrimaryColor = "#0869AB";
export const secondaryColor = "#D7DFEA";

export const disabledPrimaryColor = "#0869AB";

const Theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      fontFamily: "Open sans",
    },
    active: {
      main: primaryColor,
      contrastText: "#000000",
    },
    success: {
      main: "#00753A",
    },
  },
  typography: {
    fontFamily: ["Open Sans", "Poppins", "Montserrat", "sans-serif"].join(","),
  },
  // html: {
  //   scrollBehavior: smooth,
  // },
  transitions: {
    easing: {
      // This is the most common easing curve.
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState && {
            borderRadius: "0.5rem",
            backgroundColor: "rgba(255,255,255,0.6)",
            fontFamily: "Open sans",
            color: primaryColor,
            minHeight: "4rem",
            width: "100%",
            boxShadow: "inset 0rem 0.2rem 0.6rem rgba(0, 0, 0, 0.1)",
            "&:before": {
              display: "none",
            },
            "&:hover": {
              backgroundColor: secondaryColor,
            },
          }),
        }),
      },
    },
    MuiGrid: {
      variants: [
        {
          props: { variant: "custom" },
          style: {
            // minHeight: '100vh',
            // display: "flex",
            justifyContent: "center",
          },
        },
        {
          props: { variant: "blueGrid" },
          style: {
            width: "18rem",
            minHeight: "3.5rem",
            fontWeight: 600,
            fontFamily: "Open sans",
            fontSize: "1.3rem",
            backgroundColor: primaryColor,
            borderRadius: "1rem",
            color: secondaryColor,
            textTransform: "none",
            alignContent: "center",
            alignItems: "center",
            boxShadow: "0rem 0.2rem 0.2rem rgb(0, 0, 0, 0.3)",
          },
        },
        {
          props: { variant: "lightBlueGrid" },
          style: {
            width: "38.6rem",
            minHeight: "3.5rem",
            fontWeight: 600,
            fontFamily: "Open sans",
            fontSize: "1.2rem",
            backgroundColor: secondaryColor,
            borderRadius: "1rem",
            color: primaryColor,
            textTransform: "none",
            alignContent: "center",
            alignItems: "center",
          },
        },
      ],
    },
    MuiTooltips: {
      variants: [
        {
          props: { variant: "custom" },
          style: {
            fontFamily: "Open Sans",
            fontSize: "1.4rem",
            backgroundColor: "red",
          },
        },
      ],
    },
    MuiAppBar: {
      variants: [
        {
          props: { variant: "custom" },
          style: {
            backgroundColor: "white",
            fontFamily: "Open sans",
            fontSize: "1.2rem",
          },
        },
      ],
    },
    MuiCard: {
      variants: [
        {
          props: { variant: "custom" },
          style: {
            backgroundColor: "white",
            borderRadius: "2rem",
            spacing: 0,
            direction: "column",
            alignItems: "center",
            justify: "center",
            display: "flex",
            flexDirection: "column",
          },
        },
        {
          props: { variant: "homeCard" },
          style: {
            // backgroundColor: "white",
            borderRadius: "1.6rem",
            spacing: 0,
            direction: "column",
            alignItems: "center",
            justify: "center",
            display: "flex",
            flexDirection: "column",
            background:
              "linear-gradient(to top right, RGBA(150,200,255,0.8),rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))",
            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
            // boxShadow: "inset 3px 3px 3px rgba(225,255,255,.7), inset -4px -4px 3px rgba(0,0,50,.2), 0px 10px 20px rgba(0, 0, 0, 0.2)",
            backdropFilter: "blur(7px)",
            ":-webkit-backdrop-filter": "blur(7px)",
          },
        },
        {
          props: { variant: "actionCard" },
          style: {
            boxShadow: "0rem 0.5rem 0.6rem rgba(0, 0, 0, 0.1)",
            backgroundColor: "white",
            borderRadius: "0.5rem",
            spacing: 0,
            direction: "column",
            alignItems: "center",
            justify: "center",
            display: "flex",
            flexDirection: "column",
          },
        },
        {
          props: { variant: "infoCard" },
          style: {
            boxShadow: "0rem 0.2rem 0.6rem rgba(0, 0, 0, 0.1)",
            backgroundColor: secondaryColor,
            width: "70%",
          },
        },
        {
          props: { variant: "dragCard" },
          style: {
            //background: "radial-gradient(circle, rgba(187,251,63,0.1) 30%, rgba(100,252,70,0.3) 100%)",
            background: "rgba(255,255,255,0)",
            width: "70%",
            border: "#1A3762 dashed",
            borderRadius: "2rem",
          },
        },
        {
          props: { variant: "footerCard" },
          style: {
            paddingLeft: "5rem",
            paddingRight: "5rem",
            paddingTop: "2rem",
            paddingBottom: "2rem",
            background: "rgba(26,55,98,0.3)",
            borderRadius: "2rem",
            backdropFilter: "blur(7px)",
            ":-webkit-backdrop-filter": "blur(7px)",
            boxShadow: "inset 0rem 0.2rem 0.6rem rgba(0, 0, 0, 0.2)",
          },
        },
        {
          props: { variant: "requirementCard" },
          style: {
            boxShadow: "0rem 0.4rem 0.6rem rgba(0, 0, 0, 0.25)",
            backgroundColor: "rgba (255, 255, 255, 0.7)",
            borderRadius: "1rem",
            spacing: 0,
            direction: "column",
            alignItems: "center",
            justify: "center",
            display: "flex",
            flexDirection: "column",
            padding: "1.5rem 2rem",
            width: "100%",
          },
        },
      ],
    },
    MuiAlert: {
      variants: [
        {
          props: { variant: "alertEnviroment" },
          style: {
            fontFamily: "Open sans",
            fontWeight: 500,
            fontSize: "1.6rem",
            color: "black",
            backgroundColor: "rgb(255,244,230)",
          },
        },
      ],
    },

    MuiLink: {
      variants: [
        {
          props: { variant: "custom" },
          style: {
            fontFamily: "Open sans",
            color: "#0B5E94",
            fontWeight: 700,
            textDecorationLine: "none",
            "&:hover": { cursor: "pointer", color:"#e12150" },
          },
        },
        {
          props: { variant: "blackLink" },
          style: {
            fontFamily: "Open Sans",
            color: "#000000",
            fontWeight: 400,
            fontSize: "2.4rem",
          },
        },
        {
          props: { variant: "linkBlue" },
          style: {
            fontFamily: "Open sans",
            color: primaryColor,
            fontWeight: 600,
            fontSize: "1.4rem",
            "&:hover": {
              cursor: "pointer",
              fontWeight: 800,
              color: "#70AAFF",
            },
          },
        },
      ],
    },

    MuiTypography: {
      variants: [
        {
          props: { variant: "title" },
          style: {
            fontFamily: "Poppins",
            fontSize: "3.2rem",
            fontWeight: 500,
            color: primaryColor,
          },
        },
        {
          props: { variant: "title2" },
          style: {
            fontFamily: "Poppins",
            fontSize: "2.6rem",
            color: primaryColor,
          },
        },
        {
          props: { variant: "title3" },
          style: {
            fontFamily: "Poppins",
            fontSize: "1.6rem",
            color: primaryColor,
            fontWeight: 600,
            alignText: "left",
            lineHeight: "2.4rem",
          },
        },
        {
          props: { variant: "textParagraph" },
          style: {
            fontFamily: "Open sans",
            fontSize: "1.6rem",
            color: primaryColor,
            fontWeight: 400,
            textAlign: "center",
            justifyContent: "center",
          },
        },
        {
          props: { variant: "textMenu" },
          style: {
            fontFamily: "Open sans",
            fontSize: "1.4rem",
            color: primaryColor,
            fontWeight: "bolder",
            textAlign: "center",
            justifyContent: "center",
          },
        },
        {
          props: { variant: "textDescription" },
          style: {
            fontFamily: "Open sans",
            fontSize: "1.4rem",
            fontWeight: 400,
            color: primaryColor,
          },
        },
        {
          props: { variant: "titleGreen" },
          style: {
            fontFamily: "Open Sans",
            fontSize: "3.5rem",
            fontWeight: 600,
            color: "#00753A",
          },
        },
        {
          props: { variant: "actionCardTitle" },
          style: {
            fontFamily: "Open Sans",
            fontSize: "2.6rem",
            fontWeight: 700,
            color: "#1A3762",
          },
        },
        {
          props: { variant: "actionCardDescription" },
          style: {
            fontFamily: "Open Sans",
            fontSize: "2rem",
            fontWeight: 500,
            color: "#1A3762",
          },
        },
        {
          props: { variant: "homeTitle" },
          style: {
            fontFamily: "Poppins",
            fontSize: "4rem",
            fontWeight: 500,
            color: "#1A3762",
          },
        },
        {
          props: { variant: "homeDescription" },
          style: {
            fontFamily: "Poppins",
            fontSize: "2.4rem",
            fontWeight: 400,
            color: "#1A3762",
          },
        },
      ],
    },

    MuiInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              border: "1px solid rgb(30,30,30, 0.23)",
              borderRadius: "10px",
              backgroundColor: "rgb(255,255,255,0.5)",
              fontFamily: "Open sans",
              color: "rgb(0,0,0,1)",
              minHeight: "4rem",
              width: "100%",
              ":before": { borderBottomColor: "rgb(0,0,0,0)" },
              // underline when selected
              ":after": { borderBottomColor: "rgb(0,0,0,0)" },
              disableUnderline: true,
              "&:hover": {
                disableUnderline: true,
                backgroundColor: "rgb(230, 230, 230)",
              },
            }),
        }),
      },
      variants: [
        {
          props: { variant: "inputText" },
          style: {
            border: "1px solid rgb(30,30,30, 0.23)",
            borderRadius: "10px",
            backgroundColor: "none",
            fontFamily: "Open sans",
            color: "none",
            minHeight: "4rem",
            width: "100%",
            ":before": { borderBottomColor: "rgb(0,0,0,0)" },
            // underline when selected
            ":after": { borderBottomColor: "rgb(0,0,0,0)" },
            underline: { borderColor: "rgb(0,0,0,0)" },
          },
        },
        {
          props: { variant: "codeInput" },
          style: {
            border: "1px solid rgb(30,30,30, 0.23)",
            borderRadius: "10px",
            length: 1,
            backgroundColor: secondaryColor,
            fontFamily: "Open sans",
            fontSize: "2rem",
            fontWeight: "bolder",
            textAlign: "center",
            color: primaryColor,
            height: "4rem",
            width: "4rem",
            marginLeft: "0.5rem",
            marginRight: "0.5rem",
            ":before": { borderBottomColor: "rgb(0,0,0,0)" },
            // underline when selected
            ":after": { borderBottomColor: "rgb(0,0,0,0)" },
            "& input[type=number]": {
              "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
          },
        },
        {
          props: { variant: "codeInput" },
          style: {
            border: "1px solid rgb(30,30,30, 0.23)",
            borderRadius: "10px",
            length: 1,
            backgroundColor: secondaryColor,
            fontFamily: "Open sans",
            fontSize: "2rem",
            fontWeight: "bolder",
            textAlign: "center",
            color: primaryColor,
            height: "4rem",
            width: "4rem",
            marginLeft: "0.5rem",
            marginRight: "0.5rem",
            ":before": { borderBottomColor: "rgb(0,0,0,0)" },
            // underline when selected
            ":after": { borderBottomColor: "rgb(0,0,0,0)" },
            "& input[type=number]": {
              "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
          },
        },
      ],
    },

    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "outlined" && {
            border: "0.1rem solid rgb(0,0,0, 0)",
            borderRadius: "10rem",
            fontSize: "1.6rem",
            width: "100%",
            "&:hover": {
              backgroundColor: "rgb(25, 55, 100, 0.05)",
            },
          }),
        }),
      },
    },

    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: "1.4rem",
        },
      },
    },

    MuiFormHelperText: {
      defaultProps: {
        sx: {
          fontSize: "1.2rem",
        },
      },
    },

    MuiFormLabel: {
      defaultProps: {
        sx: {
          margin: "-3px 0 0 0",
        },
      },
    },

    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: "1.6rem",
          borderRadius: "10px",
          border: "1px solid rgba(30, 30, 30, 0.23) !important",
          background: "#fff !important",
          height: "45px !important",
          display: "flex",
          alignContent: "center",
        },
      },
    },

    MuiAvatar: {
      variants: [
        {
          props: { variant: "buttonAvatar" },
          style: {
            position: "absolute",
            top: 5,
            left: 10,
          },
        },
      ],
    },

    MuiButton: {
      variants: [
        {
          props: { variant: "bold" },
          style: {
            color: primaryColor,
            fontSize: "1.6rem",
            height: "4rem",
            borderRadius: "10rem",
            border: "2px solid #1B3A65",
            textAlign: "center",
            justifyContent: "center",
            backgroundColor: "transparent",
            contrastText: "#000000",
            fontFamily: "Open sans",
            textTransform: "none",
            fontWeight: "bold",
            boxShadow: "0rem 0.2rem 0.2rem rgba(0, 0, 0, 0.25)",
            "&:hover": {
              background: "rgba(0, 25, 50, 0.1)",
              color: "#152C4C",
            },
            "&:disabled": {
              color: "#7d95b3",
            },
          },
        },
        {
          props: { variant: "danger" },
          style: {
            border: "1px solid transparent",
            borderRadius: "10rem",
            textAlign: "center",
            justifyContent: "center",
            backgroundColor: "#E13333",
            width: "18.9rem",
            height: "3.9rem",
            fontSize: "1.6rem",
            color: "white",
            contrastText: "#000000",
            fontFamily: "Open sans",
            textTransform: "none",
            fontWeight: "bold",
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
          },
        },
        {
          props: { variant: "inputText" },
          style: {
            border: "1rem solid black",
            borderRadius: "10rem",
            backgroundColor: "black",
            fontFamily: "Open sans",
            color: "black",
          },
        },
        {
          props: { variant: "voidBtn" },
          style: {
            width: "23rem",
            height: "4rem",
            border: "0.1rem solid",
            backgroundColor: "#FFFFFF",
            borderRadius: "10rem",
            borderColor: primaryColor,
            fontFamily: "Open sans",
            fontSize: "1.6rem",
            fontWeight: 400,
            color: primaryColor,
          },
        },
        {
          props: { variant: "success" },
          style: {
            borderRadius: "10rem",
            backgroundColor: primaryColor,
            fontFamily: "Open sans",
            color: "white",
            textTransform: "none",
            fontSize: "1.6rem",
            width: "18.9rem",
            height: "4rem",
            fontWeight: "bold",
            boxShadow: "0rem 0.2rem 0.2rem rgba(0, 0, 0, 0.25)",
            "&:hover": {
              background: primaryColorHover,
              color: "white",
            },
          },
        },
        {
          props: { variant: "blueBtn" },
          style: {
            width: "18rem",
            minHeight: "3.5rem",
            fontWeight: 600,
            fontFamily: "Open sans",
            fontSize: "1.4rem",
            backgroundColor: primaryColor,
            borderRadius: "10rem",
            color: secondaryColor,
            textTransform: "none",
            alignContent: "center",
            alignItems: "center",
            boxShadow: "0rem 0.2rem 0.2rem rgb(0, 0, 0, 0.3)",
            "&:hover": {
              background: primaryColorHover,
              color: "white",
            },
          },
        },
        {
          props: { variant: "lightBlueBtn" },
          style: {
            width: "38.6rem",
            minHeight: "3.5rem",
            fontWeight: 600,
            fontFamily: "Open sans",
            fontSize: "1.4rem",
            backgroundColor: secondaryColor,
            borderRadius: "10rem",
            color: primaryColor,
            textTransform: "none",
            alignContent: "center",
            alignItems: "center",
            "&:hover": {
              background: primaryColor,
              color: "white",
            },
          },
        },
      ],
    },

    // MuiTab: {
    //   styleOverrides: {
    //     root: ({ ownerState }) => ({
    //       ...(ownerState.variant === "menuTab" && {
    //           border: "0.1rem solid rgb(0,0,0, 0.3)",
    //           borderRadius: "1rem",
    //           backgroundColor: "rgb(0,0,0,0)",
    //           fontFamily: "Open sans",
    //           color: "rgb(0,0,0,1)",
    //           minHeight: "4rem",
    //           width: "100%",
    //           ":before": { borderBottomColor: "rgb(0,0,0,0)" },
    //           // underline when selected
    //           ":after": { borderBottomColor: "rgb(0,0,0,0)" },
    //           disableUnderline: true,
    //           "&:hover": {
    //             disableUnderline: true,
    //             backgroundColor: "rgb(230, 230, 230)",
    //           },
    //         }),
    //     }),
    //   },
    // },

    MuiTab: {
      variants: [
        {
          props: { variant: "menuTab" },
          style: {
            fontSize: "1.6rem",
            color: "black",
            textTransform: "none",
            fontFamily: "Open sans",
            fontWeight: 600,
            "&:hover": {
              animation: "menuFontSizeGrow 0.3s forwards",
            },
            "&.Mui-selected": {
              color: "black",
              fontWeight: 700,
              // "&.Mui-focusVisible": { background: "orange" }
            },
            "&.MuiTabs-indicator": {
              backgroundColor: "black",
            },
          },
        },
      ],
    },

    MuiTableRow: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...{
            "&:hover": {
              backgroundColor: "rgba(200,210,220,0.5)",
              "& .MUIDataTableBodyCell-root": {
                "&:last-child": {
                  color: "#FFFFFF",
                },
              },
            },
          },
        }),
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...{
            fontSize: "1.2rem",
          },
        }),
      },
    },

    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...{
            fontSize: "1.2rem",
            fontFamily: [
              "Montserrat",
              "Poppins",
              "Open Sans",
              "sans-serif",
            ].join(","),
          },
        }),
      },

      variants: [
        {
          props: { variant: "paginationPage" },
          style: {
            borderRadius: "0.8rem",
            "&:hover": {
              backgroundColor: "#106699",
              color: "#FFFFFF",
            },
          },
        },
        {
          props: { variant: "paginationNav" },
          style: {
            backgroundColor: "transparent",
            color: "#9E9E9E",
            "&:hover": {
              color: "#106699",
            },
          },
        },
      ],
    },
  },
});

export default Theme;
