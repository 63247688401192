import {
  Avatar,
  Grid,
  IconButton,
  Input,
  Tooltip,
  Typography,
  Link,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { CHANGE_PASSWORD } from "../../../routing/constants";

export default function ProfilePage(props) {
  const userData = useSelector((state) => state?.login?.authentication);
  const userLastName = userData?.username.split("|")[1];
  const userFirstName = userData?.username.split("|")[0];
  const urlAvatar = useSelector((state) => state?.login?.urlAvatar);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      variant="custom"
      justifyContent="center"
      sx={{ mb: "5rem" }}
    >
      <Grid item>
        <Typography variant="title">Mi Perfil</Typography>
      </Grid>
      <Grid item>
        <Tooltip
          variant="custom"
          title={
            <Typography fontSize="1.2rem">¡Tenga un excelente día!</Typography>
          }
        >
          <IconButton sx={{ p: 0, pb: "2rem", pt: "2rem" }}>
            <Avatar
              alt="Avatar usuario"
              src={urlAvatar}
              sx={{
                width: "9rem",
                height: "9rem",
                border: "0.3rem solid rgb(255, 255, 255, 1)",
                boxShadow: "0rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4)",
              }}
            />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item>
        <Grid container direction="row" sx={{ placeContent: "center" }}>
          <Grid item sx={{ pt: "1.5rem" }}>
            <Typography
              variant="title3"
              sx={{ fontSize: "1.6rem", pl: "1.5rem" }}
            >
              Nombre/s
            </Typography>
            <br />
            <Input
              value={userFirstName}
              name="aliasField"
              type="text"
              variant="contained"
              disableUnderline={true}
              sx={{
                width: "25rem",
                pl: "1.5rem",
                fontSize: "1.6rem",
                mt: "1rem",
                mb: "1rem",
                mr: "1rem",
                ml: "1rem",
              }}
            />
          </Grid>
          <Grid item sx={{ pt: "1.5rem" }}>
            <Typography
              variant="title3"
              sx={{ fontSize: "1.6rem", pl: "1.5rem" }}
            >
              Apellido
            </Typography>
            <br />
            <Input
              placeholder="Apellido"
              name="lastnameField"
              value={userLastName}
              type="text"
              variant="contained"
              disableUnderline={true}
              sx={{
                width: "25rem",
                pl: "1.5rem",
                fontSize: "1.6rem",
                mt: "1rem",
                mb: "1rem",
                mr: "1rem",
                ml: "1rem",
              }}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" sx={{ placeContent: "center" }}>
          <Grid
            item
            sx={{
              pt: "1.5rem",
              width: { xs: "25rem", sm: "-webkit-fill-available" },
              pr: "2rem"
            }}
          >
            <Typography
              variant="title3"
              sx={{ fontSize: "1.6rem", pl: "1.5rem" }}
            >
              Email
            </Typography>
            <br />
            <Input
              value={userData?.email}
              name="emailField"
              type="text"
              variant="contained"
              disableUnderline={true}
              sx={{
                pl: "1.5rem",
                fontSize: "1.6rem",
                mt: "1rem",
                mb: "1rem",
                mr: "1rem",
                ml: "1rem",
              }}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" sx={{ placeContent: "center" }}>
          <Grid
            item
            sx={{
              pt: "1.5rem",
              width: { xs: "25rem", sm: "-webkit-fill-available" },
              pr: "2rem"
            }}
          >
            <Grid container direction="column">
              <Grid item>
                <Typography
                  variant="title3"
                  sx={{ fontSize: "1.6rem", pl: "1.5rem" }}
                >
                  Contraseña
                </Typography>
                <br />
              </Grid>
              <Grid item>
                <Input
                  value="hola hermos@ ;)"
                  name="passField"
                  type="password"
                  variant="contained"
                  disabled
                  disableUnderline={true}
                  sx={{
                    width: "100%",
                    pl: "1.5rem",
                    fontSize: "1.6rem",
                    mt: "1rem",
                    mb: "1rem",
                    mr: "1rem",
                    ml: "1rem",
                  }}
                />
              </Grid>
              <Grid item sx={{ textAlign: "right" }}>
                <Link
                  href={CHANGE_PASSWORD}
                  variant="custom"
                  sx={{ pr: "1rem", fontSize: "1.4rem" }}
                >
                  {"Cambiar contraseña"}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
