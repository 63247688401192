import {
  Typography,
  Grid,
  Box,
  Button,
  Link,
  TextField,
  SvgIcon,
  InputAdornment,
} from "@mui/material";
import React, { useState } from "react";
import LoginStructure from "../../common/loginStructure";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { tryCreateAccount } from "./registerSlice";
import AppLoading from "../../common/appLoading";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LOGIN_URI } from "../../../routing/constants";

const RegisterPage = (props) => {
  const dispatch = useDispatch();
  const isFetching = useSelector((state) => state?.register?.isFetching);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validationSchema = yup.object({
    name: yup.string().required("Debe ingresar su nombre"),
    lastName: yup.string().required("Debe ingresar su apellido"),
    email: yup
      .string()
      .email("Ingrese un email válido")
      .required("Debe ingresar un correo electrónico"),
    pass: yup
      .string()
      .required("Debe ingresar una contraseña")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "Debe contener 8 caracteres, al menos una mayúscula, una minúscula y un número"
      ),
    passConfirmation: yup
      .string()
      .required("Debe reingresar su contraseña")
      .oneOf([yup.ref("pass"), null], "Las contraseñas deben coincidir"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      lastName: "",
      email: "",
      pass: "",
      passConfirmation: "",
    },
    onSubmit: (values) => {
      dispatch(tryCreateAccount(values));
    },
    validationSchema: validationSchema,
  });

  const handleVisibility = () => {
    setShowPassword(!showPassword);
  }

  const handleVisibilityConfirmPass = () => {
    setShowConfirmPassword(!showConfirmPassword);
  }

  return (
    <Grid container spacing={2}>
      <LoginStructure>
        <Typography variant="title">Crear cuenta</Typography>
        <br />
        <Typography variant="title2">¡Bienvenido!</Typography>
        {!isFetching ? (
          <Grid
            component="form"
            noValidate
            onSubmit={formik.handleSubmit}
            sx={{ mt: "2rem", mb: 0 }}
          >
            <Grid item textAlign="center" xs={7} md={7} >
              <TextField
                id="lastName"
                name="lastName"
                type="text"
                variant="outlined"
                placeholder="Ingrese su apellido"
                label="Apellido"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
                sx={{ mb: "1rem" }}
              ></TextField>
            </Grid>
            <Grid item textAlign="left" xs={7} md={7} >
              <TextField
                id="name"
                name="name"
                type="text"
                placeholder="Ingrese su nombre"
                label="Nombre"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                sx={{ mb: "1rem" }}
              ></TextField>
            </Grid>
            <Grid item textAlign="left" xs={7} md={7} >
              <TextField
                id="email"
                name="email"
                type="text"
                placeholder="Ingrese su email"
                label="Correo electrónico"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                sx={{ mb: "1rem" }}
              ></TextField>
            </Grid>

            <Grid item textAlign="left" xs={7} md={7} sx={{mb:"1rem", justifyItems:"flex-start"}}>
              <TextField
                id="pass"
                name="pass"
                type={showPassword ? "text" : "password"}
                placeholder="Ingrese su contraseña"
                label="Contraseña"
                value={formik.values.pass}
                onChange={formik.handleChange}
                error={formik.touched.pass && Boolean(formik.errors.pass)}
                helperText={formik.touched.pass && formik.errors.pass}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                        <SvgIcon 
                        component={showPassword ? Visibility : VisibilityOff}
                        onClick={handleVisibility}
                      />
                    </InputAdornment>
                  )}}
                >
              </TextField>
            </Grid>

            <Grid item textAlign="left" xs={7} md={7} sx={{ mt: "0.5rem", mb:"2rem"}}>
              <TextField
                id="passConfirmation"
                name="passConfirmation"
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Ingrese nuevamente su contraseña"
                label="Repita su contraseña"
                value={formik.values.passConfirmation}
                onChange={formik.handleChange}
                error={
                  formik.touched.passConfirmation && Boolean(formik.errors.passConfirmation)
                }
                helperText={formik.touched.passConfirmation && formik.errors.passConfirmation}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                        <SvgIcon 
                        component={showConfirmPassword ? Visibility : VisibilityOff}
                        onClick={handleVisibilityConfirmPass}
                      />
                    </InputAdornment>
                  )}}
              ></TextField>
            </Grid>
            <Grid item xs={8} md={8}>
              <Button type="submit" variant="success" sx={{ mb: "1rem" }}>
                Crear cuenta
              </Button>
            </Grid>
            <Grid item textAlign="center" xs={7} md={7}>
                <Typography variant="textDescription">
                  ¿Ya tiene una cuenta?
                  <Link href={LOGIN_URI} variant="custom" sx={{}}>
                    {" Inicie sesión"}
                  </Link>
                </Typography>
            </Grid>
          </Grid>
        ) : (
          <Box sx={{ mt: "1.5rem" }}>
            <AppLoading size={5}/>
          </Box>
        )}
      </LoginStructure>
    </Grid>
  );
};

export default RegisterPage;
