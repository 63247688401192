import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Alert, Button, Card, Grid, SvgIcon } from "@mui/material";
import DragAndDrop from "../../common/dragAndDrop";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getProcedureRequirements,
  tryChangeProcedureState,
  tryGetProcedureById,
} from "./requirementsSlice";
import RequirementModal from "./requirementModal";
import { tryCloseModal, tryShowModal } from "../../modals/modalSlice";
import { history } from "../../../_helpers/history";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  MUNICIPAL_AUTHORIZATION_URI,
  REPRESENTATIVES_URI,
} from "../../../routing/constants";
import AppLoading from "../../common/appLoading";
import { showWarning } from "../../../utils/utils";

const RequirementsPage = () => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const municipalAuthorizationLoaded = useSelector(
    (state) => state?.municipalAuthorization?.canFinish
  );
  const requirementsList = useSelector(
    (state) => state?.requirements?.requirementsFiles
  );
    const allFilesLoaded = useSelector(
    (state) => state?.requirements?.allFilesLoaded
  );
  const emailSelected = useSelector(
    (state) => state?.login?.authentication?.email
  );
  const transactState = useSelector(
    (state) => state?.requirements?.procedureData?.data?.procedureState?.name
  );
  const procedureStatus = useSelector(
    (state) => state?.requirements?.procedureStatus
  );
  const selectedProcedure = useSelector(
    (state) => state?.municipalAuthorization?.procedureId
  );
  const typeOfProcedureSelected = useSelector(
    (state) => state?.municipalAuthorization?.type
  );
  const isFetchingRequirements = useSelector(
    (state) => state?.requirements?.isFetchingRequirements
  );
  const mail = useSelector(
    state => {
      const data = state?.lastTransactsTable?.transacts?.data;
      const findProcedure = data?.find(objeto => objeto.procedureId === selectedProcedure);

      if (findProcedure) {
        const confirmedMails = findProcedure.provider.person.emails.filter(
          (email) => email.state === "Confirmado"
        );
        const lastConfirmedMail = confirmedMails[confirmedMails.length - 1];

        return lastConfirmedMail
          ? lastConfirmedMail.email
          : "el correo electrónico";
      }

      return 'el correo electrónico';
    }
  );
  
  useEffect(() => {
    dispatch(tryGetProcedureById(selectedProcedure));
    dispatch(tryCloseModal());
    dispatch(getProcedureRequirements(selectedProcedure));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleShowModal = () => {
    if (procedureStatus === "Observado" && requirementsList.some(item => item?.correctFile === false)) {
      showWarning(
        "Verifique los requisitos",
        "Por favor, verifique que todos los requisitos estén cargados para poder continuar con el trámite. ¡Gracias!"
      );
      return;
    }
    if (allFilesLoaded && municipalAuthorizationLoaded) {
      dispatch(tryShowModal());
    } else {
      showWarning(
        "Verifique los requisitos",
        "Por favor, verifique que todos los requisitos estén cargados para poder continuar con el trámite. ¡Gracias!"
      );
    }
  };

  const handleFinish = (idProcedure) => {
    dispatch(tryChangeProcedureState(idProcedure));
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      variant="custom"
    >
      <Grid
        container
        sx={{ width: "60vw", justifyContent: "left", textAlign: "left" }}
      >
        <Grid
          item
          xs={12}
          sx={{
            ml: { xs: "0rem", md: "2rem", lg: "5rem" },
            mb: "2rem",
            mt: "2rem",
          }}
        >
          <Typography variant="title">Requisitos</Typography>
        </Grid>
      </Grid>
      {isFetchingRequirements && <AppLoading size={5} />}
      {requirementsList?.length > 0 && !isFetchingRequirements
        ? requirementsList?.map((item) => (
            <>
              <Accordion
                expanded={expanded === `panel${item?.id}`}
                onChange={handleChange(`panel${item?.id}`)}
                hidden={item?.requirementType?.id === 58}
                sx={{
                  width: { xs: "90%", md: "70%" },
                  mb: "1rem",
                  background:
                    procedureStatus === "Observado" && !item?.correctFile
                      ? ""
                      : item?.hasFile
                      ? "radial-gradient(circle, rgba(178,231,185,0.3) 0%, rgba(143,193,123,0.3) 100%)"
                      : item?.error &&
                        "radial-gradient(circle, rgba(255,231,185,0.3) 0%, rgba(255,193,123,0.3) 100%)",
                  borderLeft: item?.optional ? "#64afffd9" : "#ff6464d9",
                  borderLeftStyle: "groove",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography
                    variant="title3"
                    sx={{ width: "90%", flexShrink: 0, fontSize: "1.6rem" }}
                  >
                    <Grid key={item?.key}>
                      {item?.optional ? (
                        <Typography>OPCIONAL</Typography>
                      ) : (
                        <Typography>REQUERIDO</Typography>
                      )}
                      {item?.requirementType?.name}{" "}
                      {item?.municipalAuthorization?.localityName
                        ? `- ${item?.municipalAuthorization?.localityName}`
                        : item?.representative
                        ? `- ${item?.representative?.name}`
                        : item?.member
                        ? `- ${item?.member?.name}`
                        : ""}
                      {item?.error && !item?.hasFile && (
                        <div
                          style={{
                            textAlign: "end",
                            marginTop: "-2rem",
                            marginRight: "-4rem",
                          }}
                        >
                          {item?.error === true && (
                            <SvgIcon
                              component={ErrorIcon}
                              sx={{ fontSize: "1.5rem", color: "#FF0000" }}
                            />
                          )}
                        </div>
                      )}
                      {item?.hasFile && procedureStatus !== "Observado" && (
                        <div
                          style={{
                            textAlign: "end",
                            marginTop: "-2rem",
                            marginRight: "-4rem",
                          }}
                        >
                          <SvgIcon
                            component={CheckCircleIcon}
                            sx={{ fontSize: "1.5rem", color: "#00753A" }}
                          />
                        </div>
                      )}
                      {item?.correctFile && procedureStatus === "Observado" && (
                        <div
                          style={{
                            textAlign: "end",
                            marginTop: "-2rem",
                            marginRight: "-4rem",
                          }}
                        >
                          <SvgIcon
                            component={CheckCircleIcon}
                            sx={{ fontSize: "1.5rem", color: "#00753A" }}
                          />
                        </div>
                      )}
                      {procedureStatus === "Observado" &&
                        !item?.correctFile && (
                          <div
                            style={{
                              textAlign: "end",
                              marginTop: "-2rem",
                              marginRight: "-4rem",
                            }}
                          >
                            <SvgIcon
                              component={WarningIcon}
                              sx={{ fontSize: "1.5rem", color: "#FFAA00" }}
                            />
                          </div>
                        )}
                    </Grid>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <DragAndDrop
                    fileId={item?.id}
                    procedureId={item?.procedureId}
                    requirementId={item?.id}
                    fileExists={item?.hasFile}
                    observation={item?.observation}
                  />
                </AccordionDetails>
              </Accordion>
            </>
          ))
        : null}

      {/* TODO: [17-Jul-2024] Se oculta el email como requisito, validar si no se seguira utilizando a futuro y eliminar este código */}
      {transactState === "Borrador" ? (
        <Accordion
          
          expanded={expanded === `panelEmail`}
          onChange={handleChange(`panelEmail`)}
          sx={{
            display:"none", // TODO: Eliminar esta línea para visualizar email nuevamente
            width: { xs: "90%", md: "70%" },
            mb: "1rem",
            background:
              "radial-gradient(circle, rgba(178,231,185,0.3) 0%, rgba(143,193,123,0.3) 100%)",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              variant="title3"
              sx={{ width: "90%", flexShrink: 0, fontSize: "1.6rem" }}
            >
              <Grid>
                Constituir domicilio electrónico, donde serán válidas las
                comunicaciones cursadas (este domicilio se constituye una sola
                vez)
                <div
                  style={{
                    textAlign: "end",
                    marginTop: "-2rem",
                    marginRight: "-4rem",
                  }}
                >
                  <SvgIcon
                    component={CheckCircleIcon}
                    sx={{ fontSize: "1.5rem", color: "#00753A" }}
                  />
                </div>
              </Grid>
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ mb: "2rem" }}>
            <Typography
              variant="title3"
              sx={{ ml: "2rem", fontSize: "1.6rem" }}
            >
              <b>
                {" "}
                {emailSelected
                  ? emailSelected
                  : "No posee o no se encuentra ningún mail asociado a AFIP"}
              </b>
            </Typography>
          </AccordionDetails>
        </Accordion>
      ) : (
        ""
      )}

      <Card
        variant="infoCard"
        sx={{
          mt: "2rem",
          padding: "3rem",
          mb: "2rem",
          width: { xs: "90%", md: "70%" },
        }}
      >
        <Typography
          variant="textParagraph"
          sx={{ mb: "1rem", fontWeight: 600 }}
        >
          Tenga en cuenta para inscribirse:
        </Typography>
        <br />
        <br />
        <Typography variant="textParagraph" sx={{ mb: "1rem" }}>
          <b>1.</b> No encontrarse inhibidos (Completar Declaración Jurada en el
          Formulario Interactivo, no obstante el registro de proveedores en
          forma concomitante efectuará el control pertinente solicitando el F.5
          al Registro de la Propiedad de Inmueble "Informe de inhibiciones" a
          los efectos de constatar la veracidad de lo declarado).
        </Typography>
        <br />
        <br />
        <Typography variant="textParagraph" sx={{ mb: "1rem" }}>
          <b>2.</b> No pueden ser empleados del Estado según las
          incompatibilidades previstas en el Art. 70 de la Constitución
          Provincial y Art. 40° Inc. b) y c) de la Ley I N° 37 (antes Decreto
          Ley 1556/82) situación que se constatará mediante consulta al
          "Registro Único Laboral".
        </Typography>
      </Card>
      {(!allFilesLoaded || !municipalAuthorizationLoaded) && (
        <Alert
          severity="warning"
          sx={{
            width: { xs: "90%", md: "70%" },
            mb: "2rem",
          }}
        >
          {!allFilesLoaded && (
            <>
              <Typography variant="textParagraph" sx={{ mb: "1rem" }}>
                • Tiene requisitos sin completar.
              </Typography>
              <br />
            </>
          )}
          {!municipalAuthorizationLoaded && (
            <>
              <Typography variant="textParagraph" sx={{ mb: "1rem" }}>
                • Debe tener al menos una habilitación municipal cargada.
              </Typography>
              <br />
            </>
          )}
        </Alert>
      )}
      <Alert
        iconMapping={{
          success: (
            <ErrorIcon
              className="errorIcon"
              fontSize="inherit"
              sx={{ color: "#A61414", fontSize: "3rem" }}
            />
          ),
        }}
        sx={{
          width: { xs: "90%", md: "70%" },
          backgroundColor: "#F7C7C7",
        }}
      >
        <Typography
          variant="textParagraph"
          className="text-message"
          sx={{
            color: "#000",
            fontWeight: "400",
          }}
        >
          Declaro que los datos y documental adjunta son correctos y completos.
          Los mismos revisten el carácter de Declaración Jurada, sin omitir ni
          falsear dato alguno que deba contener, siendo fiel expresión de la
          verdad.
        </Typography>
      </Alert>
      <Grid
        container
        direction="row"
        sx={{ width: "100%", textAlign: "center", my: "3rem" }}
      >
        <Grid item xs={12} md={6} align="center">
          <Button
            onClick={() => {
              history.navigate(
                typeOfProcedureSelected === "Ampliación de Rubro"
                  ? MUNICIPAL_AUTHORIZATION_URI
                  : REPRESENTATIVES_URI
              );
            }}
            variant="bold"
            sx={{
              width: "20rem",
              mb: { xs: "1rem", md: 0 },
            }}
          >
            Volver
          </Button>
        </Grid>
        <Grid item xs={12} md={6} align="center">
          <Button
            onClick={handleShowModal}
            variant="success"
            sx={{ width: "20rem" }}
            // disabled={procedureStatus === "Observado" && requirementsList.some(item => item?.correctFile === false)}
          >
            Finalizar
          </Button>
        </Grid>
      </Grid>
      <RequirementModal
        open={true}
        mail={mail}
        handleFinish={() => handleFinish(requirementsList[0]?.procedureId)}
      ></RequirementModal>
    </Grid>
  );
};

export default RequirementsPage;
