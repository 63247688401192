import afipicono from './afipicono.png';
import facebook from './facebook.png';
import iconogoogle from './iconogoogle.png';
import mistramitesicon from './mistramitesicon.png';
import openIcon from './openIcon.png';
import warningIcon from './warningIcon.png';
import associateIcon from './associate_icon.svg';
import printConstancyIcon from './print_icon.svg';
import startTransactIcon from './startTransact_icon.svg';
import historyIcon from './history_icon.svg'; 
import misionesIcon from './misionesIcon.png';
import misionesIconAlt from './misionesIcon_alt.png';
import pdfIcon from './pdf_icon.svg';
import infoIcon from './info_icon.svg';
import mailIcon from './mail_icon.svg';

const Icons = {
    "afipicono": afipicono,
    "facebook": facebook,
    "iconogoogle": iconogoogle,
    "mistramitesicon": mistramitesicon,
    "openIcon": openIcon,
    "warningIcon": warningIcon,
    "associateIcon": associateIcon,
    "printConstancyIcon": printConstancyIcon,
    "startTransactIcon": startTransactIcon,
    "historyIcon": historyIcon,
    "misionesIcon": misionesIcon,
    "misionesIconAlt": misionesIconAlt,
    "pdfIcon": pdfIcon,
    "infoIcon": infoIcon,
    "mailIcon": mailIcon
};

export default Icons;