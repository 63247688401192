import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Tooltip,
  MenuItem,
  Grid,
} from "@mui/material";
import { useDispatch } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import { Image } from "react-bootstrap";
import banner from "../../assets/images/logo_contaduria.png";
import Icons from "../../assets/icons/Icons";
import { history } from "../../_helpers/history";
import { logout } from "../pages/loginPage/loginSlice";
import { useSelector } from "react-redux";
import Menus from "./menus";
import { tryGetUserManual } from "./commonSlice";

const pages = [
  { name: "Inicio", path: "/" },
  { name: "Iniciar trámite", path: "/new-transact" },
  { name: "Personas asociadas", path: "/associate-cuit" },
];
const settings = [
  {name:"Perfil", id:1},
  {name:"Manual de Usuario", id:2},
  {name:"Cerrar sesión", id:3}
];

const Header = () => {
  const dispatch = useDispatch();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const userAlias = useSelector((state) => state?.login?.authentication?.alias);
  const urlAvatar = useSelector((state) => state?.login?.urlAvatar);
  const userProfile = useSelector(
    (state) => state?.login?.authentication?.profile_name
  );

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (url) => {
    history.navigate(url);
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onClickSettings = (id) => {
    setAnchorElUser(null);
    switch (id) {
      case 1:
        history.navigate("/profile");
        break;
      case 2:
        dispatch(tryGetUserManual())
        break;
      case 3:
        dispatch(logout());
        history.navigate("/login");
        break;
      default:
        break;
    }
  };

  return (
    <AppBar
      position="static"
      variant="custom"
      sx={{ padding: "2rem", backgroundColor: "rgba(255,255,255, 0)" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Grid
            sx={{
              maxWidth: "10%",
              "&:hover": { cursor: "pointer" },
              display: { xs: "none", md: "flex", lg: "flex" },
            }}
          >
            <Image
              src={banner}
              alt="logo"
              width="100rem"
              onClick={() => history.navigate("/")}
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "none" },
                ml: "5vw",
              }}
            />
            <Image
              src={Icons.misionesIconAlt}
              alt="logo"
              width="50rem"
              height="60rem"
              onClick={() => history.navigate("/")}
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "none" },
                ml: "5vw",
              }}
            />
          </Grid>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon color="primary" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page?.name}
                  sx={{ mr: "0.5rem" }}
                  onClick={() => handleCloseNavMenu(page?.path)}
                >
                  <Typography variant="textParagraph" textAlign="center">
                    {page?.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Container
            sx={{
              maxWidth: "10%",
              "&:hover": { cursor: "pointer" },
              display: { xs: "flex", md: "none", lg: "none" },
              position: "absolute",
              left: "30%",
            }}
          >
            <Image
              src={banner}
              alt="logo"
              width="100rem"
              onClick={() => history.navigate("/")}
              sx={{ flexGrow: 1, justifyContent: "center" }}
            />
          </Container>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              ml: { xs: "7vw", lg: "5vw", xl: "3vw" },
            }}
          >
            <Menus />
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip
              variant="custom"
              title={
                <Typography fontSize="1.2rem">Abrir menú de usuario</Typography>
              }
            >
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt="Avatar usuario"
                  src={urlAvatar}
                  sx={{
                    border: "0.1rem solid rgb(255, 255, 255, 1)",
                    boxShadow: "0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.5)",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "4.5rem" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Typography
                variant="title"
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "1.4rem",
                  fontWeight: 800,
                  ml: "1rem",
                }}
                textAlign="center"
              >
                {userAlias}
              </Typography>
              <br />
              <Typography
                variant="title2"
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "1.2rem",
                  fontWeight: 700,
                  ml: "2rem",
                }}
                textAlign="center"
              >
                {userProfile}
              </Typography>
              <hr
                style={{
                  color: "grey",
                  backgroundColor: "lightgrey",
                  height: 1,
                  width: "25rem",
                }}
              />
              {settings.map((setting) => (
                <MenuItem
                  key={setting?.name}
                  onClick={() => onClickSettings(setting?.id)}
                  sx={{ height: "3rem", fontSize: "1.4rem", "&:hover": { animation: "fontSizeGrow 0.3s forwards" } }}
                >
                  <Typography
                    variant="title3"
                    sx={{ fontSize: "inherit", fontWeight: "inherit" }}
                    textAlign="center"
                  >
                    {setting?.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
