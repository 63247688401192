import { buildDefaultOptions } from "./authService";
import { httpPost, httpPut, httpGet, handleHttpError, httpDelete } from "./httpService";

import {env} from "../env";
const URL = `${env.API__BACKEND}/api`;

export const postMunicipalAuthorization = async (accessToken, data) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v2/MunicipalAuthorizations`;

  try {
    let response = await httpPost(uri, data, getOptions);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const getMunicipalAuthorizations = async (accessToken) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v1/MunicipalAuthorizations`;

  try {
    let response = await httpGet(uri, getOptions);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const getMunicipalAuthorizationsById = async (accessToken, id) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v1/MunicipalAuthorizations/${id}`;

  try {
    let response = await httpGet(uri, getOptions);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const putMunicipalAuthorization = async (accessToken, data) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v1/MunicipalAuthorizations/${data?.id}`;

  try {
    let response = await httpPut(uri, data, getOptions);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const getMunicipalAuthorizationsByProcedureId = async (accessToken, id) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v2/MunicipalAuthorizations/procedureId/${id}`;

  try {
    let response = await httpGet(uri, getOptions);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const deleteMunicipalAuthorization = async (accessToken, id) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v2/MunicipalAuthorizations/${id}`;

  try {
    let response = await httpDelete(uri, getOptions);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
}