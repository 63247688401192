import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserManual } from "../../services/storageService";
import { showError, showSuccess } from "../../utils/utils";
import { downloadStreamFile } from "../../utils/downloadStreamFile";
import { getApiVersions } from "../../services/dashboardService";

const initialState = {
    canFinish: false,
    isFetching: false,
    hasError: false,
    apiVersions: [],
}

export const tryGetUserManual = createAsyncThunk(
    "common/getUserManual",
    async (state, thunkAPI) => {
        try {
            const token = thunkAPI.getState().login?.authentication?.access_token;
            const response = await getUserManual(token);
            if (response.hasOwnProperty("error") && response?.error != null) {
                showError("Ups... ha ocurrido un error", response?.error?.message);
                return thunkAPI.rejectWithValue({
                    error: response?.error?.message,
                });
            }
            if (response?.status === 418 || response?.status === 500) {
                showError("Error del servidor.", response?.error?.message)
                return thunkAPI.rejectWithValue({
                    error: response?.error?.message,
                });
            }
            showSuccess("Se ha descargado el archivo con éxito.","");
            downloadStreamFile(response,  `Manual de Usuario`);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const tryGetApiVersions = createAsyncThunk(
  "common/getApiVersions",
  async (state, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await getApiVersions(token);
      if (response.hasOwnProperty("error") && response?.error !== null) {
        showError("No se puede recuperar información del Sistema")
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // TRY DOWNLOAD MANUAL
    builder.addCase(tryGetUserManual.fulfilled, (state, action) => {
      state.document = action?.payload;
      state.canFinish = true;
      state.hasError = false;
      state.isFetching = false;
    });
    builder.addCase(tryGetUserManual.pending, (state, action) => {
      state.canFinish = false;
      state.hasError = false;
      state.isFetching = true;
    });
    builder.addCase(tryGetUserManual.rejected, (state, action) => {
      state.canFinish = false;
      state.hasError = true;
      state.isFetching = false;
    });

    // TRY GET API VERSIONS IN USE
    builder.addCase(tryGetApiVersions.fulfilled, (state, action) => {
      state.apiVersions = action?.payload;
      state.hasError = false;
      state.isFetchingVersion = false;
    });
    builder.addCase(tryGetApiVersions.pending, (state, action) => {
      state.hasError = false;
      state.isFetchingVersion = true;
    });
    builder.addCase(tryGetApiVersions.rejected, (state, action) => {
      state.hasError = true;
      state.isFetchingVersion = false;
    });
  },
});

export default commonSlice.reducer;
