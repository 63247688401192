import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getFile,
  getStorageFiles,
  postFile,
} from "../../../services/storageService";
import {
  getDownloadRequirement,
  getDownloadRequirementToValidate,
  getRequirementsByProcedureId,
} from "../../../services/requirementsService";
import { showError, showSuccess } from "../../../utils/utils";
import {
  getProcedureById,
  changeProcedureState,
} from "../../../services/proceduresService";
import { history } from "../../../_helpers/history";
import { downloadStreamFile } from "../../../utils/downloadStreamFile";
import { loadState } from "../../../localStorage";

function cargarEstado() {
  // Carga el estado guardado previamente
  const savedState = loadState()?.requirements;

  // Selecciona solo las propiedades del estado que quieres precargar
  const preloadedState = {
    requirementsFiles: savedState?.requirementsFiles,
    procedureData: savedState?.procedureData,
    procedureStatus: savedState?.procedureStatus,
    // Agrega aquí las demás propiedades que quieres precargar
  };

  return preloadedState;
}

const initialState = cargarEstado()?.requirements ?? {
  requirementsFiles: [],
  hasAllFiles: false,
  isObserved: false,
  allFilesLoaded: false,
  isError: false,
  isFetching: false,
  isFinished: false,
  procedureData: {},
  procedureStatus: "",
  isFetchingRequirements: false,
};

export const fetchStorageFiles = createAsyncThunk(
  "requirements/files",
  async (state, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await getStorageFiles(token);
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          "No se pudo conectar con la StorageAPI...",
          response?.error?.message
        );
        return thunkAPI.rejectWithValue(response);
      }
      return response;
    } catch (error) {
      console.log("error");
    }
  }
);

export const tryDownloadMunicipalAuthorization = createAsyncThunk(
  "municipalAuthorization/downloadAuthorization",
  async (fileId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await getFile(token, fileId);
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          "No se pudo conectar con la StorageAPI...",
          response?.error?.message
        );
        return thunkAPI.rejectWithValue(response);
      }
      downloadStreamFile(response, `habilitacion_${fileId}`);
      return response;
    } catch (error) {
      console.log("error");
    }
  }
);

export const postRequirementFile = createAsyncThunk(
  "requirements/postFile",
  async (file, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await postFile(token, file);
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          "No se ha podido adjuntar el archivo.",
          "Ocurrió un error al adjuntar el archivo."
        );
        return thunkAPI.rejectWithValue({
          id: +file.get("id"),
          error: response?.error?.message,
        });
      }
      showSuccess("Se ha subido el archivo con éxito.", "");
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getProcedureRequirements = createAsyncThunk(
  "requirements/getProcedureRequirements",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await getRequirementsByProcedureId(token, id);
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          "Ocurrió un problema al recuperar los requisitos...",
          response?.error?.message
        );
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryChangeProcedureState = createAsyncThunk(
  "requirements/changeProcedureState",
  async (id, thunkAPI) => {
    const error = "No se pudo enviar el trámite, intente nuevamente en unos minutos.";
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await changeProcedureState(token, id);
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(error, "");
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryDownloadRequirement = createAsyncThunk(
  "requirements/downloadRequirement",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await getDownloadRequirement(token, id);
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          "No se pudo finalizar el trámite, intente nuevamente en unos minutos.",
          response?.error?.message
        );
        return thunkAPI.rejectWithValue(response?.error?.message);
      }

      downloadStreamFile(response, `requisito_${id}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryDownloadRequirementToValidate = createAsyncThunk(
  "requirements/downloadRequirementToValidate",
  async (requirement, thunkAPI) => {
    try {
      const response = await getDownloadRequirementToValidate(requirement);
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          "No se pudo finalizar el trámite, intente nuevamente en unos minutos.",
          response?.error?.message
        );
        return thunkAPI.rejectWithValue(response?.error?.message);
      }

      downloadStreamFile(response, `requisito_${requirement.id}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryGetProcedureById = createAsyncThunk(
  "requirements/getProceduresById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await getProcedureById(token, id);
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          "No se pudo obtener información sobre el trámite {id}.",
          response?.error?.message
        );
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const requirementsSlice = createSlice({
  name: "requirements",
  initialState,
  reducers: {
    restartRequirementState: (state, action) => {
      state.isFinished = false;
      state.procedureStatus = "";
    },
    setProcedureStatus: (state, action) => {
      state.procedureStatus = action.payload;
    },
    clearRequirementsState: (state, action) => {
      state.requirementsFiles = [];
      state.hasAllFiles = false;
      state.isObserved = false;
      state.allFilesLoaded = false;
      state.isError = false;
      state.isFetching = false;
      state.isFinished = false;
      state.procedureData = {};
      state.procedureStatus = "";
      state.isFetchingRequirements = false;
    },
  },
  extraReducers: (builder) => {
    // GET PROCEDURE REQUIREMENTS
    builder.addCase(getProcedureRequirements.fulfilled, (state, action) => {
      state.requirementsFiles = action.payload.map((item) => ({
        ...item,
        hasFile: item?.documentURL ? true : item?.requirementType?.id === 58 ? true : false,
        error: false,
        correctFile: false,
      }));
      state.hasAllFiles = state.requirementsFiles?.some((obj) => {
        return obj.hasFile === false && obj.optional === false;
      })
        ? false
        : true;
      state.isObserved =
        state.procedureStatus !== "Observado"
          ? true
          : state.requirementsList?.some((obj) => {
              return obj.correctFile === false;
            })
          ? false
          : true;
      state.allFilesLoaded = state.isObserved && state.hasAllFiles;
      state.isError = false;
      state.isFetchingRequirements = false;
    });
    builder.addCase(getProcedureRequirements.rejected, (state, action) => {
      state.isError = true;
      state.isFetchingRequirements = false;
    });
    builder.addCase(getProcedureRequirements.pending, (state, action) => {
      state.isError = false;
      state.isFetchingRequirements = true;
    });

    // POST REQUIREMENT FILE
    builder.addCase(postRequirementFile.fulfilled, (state, action) => {
      const index = state.requirementsFiles.findIndex(
        (item) => item.id === action.payload.id
      );
      state.requirementsFiles[index].hasFile = true;
      state.requirementsFiles[index].correctFile = true;
      state.hasAllFiles = state.requirementsFiles?.some((obj) => {
        return obj.hasFile === false && obj.optional === false;
      })
        ? false
        : true;
      state.isObserved =
        state.procedureStatus !== "Observado"
          ? true
          : state.requirementsList?.some((obj) => {
              return obj.correctFile === false;
            })
          ? false
          : true;
      state.allFilesLoaded = state.isObserved && state.hasAllFiles;
      state.isError = false;
      state.isFetching = false;
    });
    builder.addCase(postRequirementFile.rejected, (state, action) => {
      state.isError = true;
      state.isFetching = false;
      const index = state.requirementsFiles.findIndex(
        (item) => item.id === action.payload.id
      );
      state.requirementsFiles[index].error = true;
    });
    builder.addCase(postRequirementFile.pending, (state, action) => {
      state.isError = false;
      state.isFetching = true;
    });

    // CHANGE THE PROCEDURE STATE
    builder.addCase(tryChangeProcedureState.fulfilled, (state, action) => {
      state.isFinished = true;
      state.isError = false;
      state.isFetching = false;
      setTimeout(() => history.navigate("/"), 5000);
    });
    builder.addCase(tryChangeProcedureState.pending, (state, action) => {
      state.isFinished = true;
      state.isError = false;
      state.isFetching = true;
    });
    builder.addCase(tryChangeProcedureState.rejected, (state, action) => {
      state.isError = true;
      state.isFetching = false;
      setTimeout(() => history.navigate("/"), 5000);
    });

    // TRY DOWNLOAD REQUIREMENT
    builder.addCase(tryDownloadRequirement.fulfilled, (state, action) => {
      state.isError = false;
      state.isFetching = false;
    });
    builder.addCase(tryDownloadRequirement.pending, (state, action) => {
      state.isError = false;
      state.isFetching = true;
    });
    builder.addCase(tryDownloadRequirement.rejected, (state, action) => {
      state.isError = true;
      state.isFetching = false;
    });

    // TRY DOWNLOAD REQUIREMENT TO VALIDATE
    builder.addCase(tryDownloadRequirementToValidate.fulfilled, (state, action) => {
      state.isError = false;
      state.isFetching = false;
    });
    builder.addCase(tryDownloadRequirementToValidate.pending, (state, action) => {
      state.isError = false;
      state.isFetching = true;
    });
    builder.addCase(tryDownloadRequirementToValidate.rejected, (state, action) => {
      state.isError = true;
      state.isFetching = false;
    });

    // TRY GET PROCEDURE DATA
    builder.addCase(tryGetProcedureById.fulfilled, (state, action) => {
      state.procedureData = action.payload;
      state.isError = false;
      state.isFetching = false;
    });
    builder.addCase(tryGetProcedureById.pending, (state, action) => {
      state.isError = false;
      state.isFetching = true;
    });
    builder.addCase(tryGetProcedureById.rejected, (state, action) => {
      state.isError = false;
      state.isFetching = false;
    });
  },
});

export const {
  restartRequirementState,
  clearRequirementsState,
  setProcedureStatus,
} = requirementsSlice.actions;

export default requirementsSlice.reducer;
