import React from "react";
import { SvgIcon } from "@mui/material";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";

// import config from "../config"
import {env} from "../env";

const EnvironmentCard = props => {

    return (
      <>
        {env?.ENVIRONMENT__NAME !== "PRODUCCION" && (
          <div className="environment-card">
            ENV: {env.ENVIRONMENT__NAME}
            <SvgIcon className="environment-icon">
              <BuildCircleIcon />
            </SvgIcon>
          </div>
        )}
      </>
    );
}

export default EnvironmentCard;