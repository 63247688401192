import { Typography, Grid, Box, TextField, Button, InputAdornment, SvgIcon } from "@mui/material";
import LoginStructure from "../../common/loginStructure";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { putRecoveryAccountAsync } from "./passwordRecoverySlice";
import AppLoading from "../../common/appLoading";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";

const ForgotPasswordPageStepThree = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const pswValidationSchema = yup.object({
    newPsw: yup
      .string()
      .required("Debe ingresar una nueva contraseña")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "Debe contener 8 caracteres, al menos una mayúscula, una minúscula y un número"
      ),
    confirmPsw: yup
      .string()
      .required("Debe ingresar una contraseña")
      .oneOf([yup.ref("newPsw"), null], "Las contraseñas deben coincidir"),
  });

  const formik = useFormik({
    initialValues: {
      newPsw: "",
      confirmPsw: "",
    },
    onSubmit: (values) => {
      dispatch(
        putRecoveryAccountAsync({
          UserID: userID,
          NewPassword: values?.newPsw,
        })
      );
    },
    validationSchema: pswValidationSchema,
  });

  const dispatch = useDispatch();
  const isFetching = useSelector((x) => x?.passwordRecovery?.isFetching);
  const userID = useSelector((state) => state?.passwordRecovery?.UserID);

    const handleVisibility = () => {
      setShowPassword(!showPassword);
    };

    const handleVisibilityNewPass = () => {
      setShowNewPassword(!showNewPassword);
    };

  return (
    <LoginStructure>
      <Grid container spacing={4} sx={{ justifyContent: "center" }}>
        <Grid item textAlign="left">
          <Typography variant="title">Nueva contraseña</Typography>
        </Grid>
        <Grid item xs={8} md={8} sx={{ textAlign: "center" }}>
          <Typography variant="textParagraph" sx={{}}>
            La nueva contraseña debe tener al menos 8 caracteres e incluir
            mayúsculas, minúsculas y números.
          </Typography>
        </Grid>
        <Grid
          item
          xs={8}
          md={8}
          sx={{ textAlign: "center" }}
        >
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{ mt: "0rem", mb: "0rem", justifyContent: "center" }}
            container
            spacing={4}
          >
            <Grid item textAlign="left">
              <TextField
                id="newPsw"
                name="newPsw"
                margin="dense"
                label="Nueva Contraseña"
                size="normal"
                type={showPassword ? "text" : "password"}
                value={formik.values.newPsw}
                onChange={formik.handleChange}
                error={formik.touched.newPsw && Boolean(formik.errors.newPsw)}
                helperText={formik.touched.newPsw && formik.errors.newPsw}
                sx={{ textAlign: "left" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SvgIcon
                        component={showPassword ? Visibility : VisibilityOff}
                        onClick={handleVisibility}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item textAlign="left" sx={{ mb: "2rem" }}>
              <TextField
                id="confirmPsw"
                name="confirmPsw"
                margin="dense"
                label="Confirme Contraseña"
                size="normal"
                type={showNewPassword ? "text" : "password"}
                value={formik.values.confirmPsw}
                onChange={formik.handleChange}
                error={
                  formik.touched.confirmPsw && Boolean(formik.errors.confirmPsw)
                }
                helperText={
                  formik.touched.confirmPsw && formik.errors.confirmPsw
                }
                sx={{ textAlign: "left" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SvgIcon
                        component={showNewPassword ? Visibility : VisibilityOff}
                        onClick={handleVisibilityNewPass}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                variant="success"
                type="submit"
                sx={{ mt: 1, mb: 2, width: "60%" }}
              >
                {isFetching ? <AppLoading size={3} /> : "Guardar"}
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </LoginStructure>
  );
};

export default ForgotPasswordPageStepThree;
