import { buildDefaultOptions } from "./authService";
import {
  httpPost,
  httpGet,
  handleHttpError,
  httpDelete,
} from "./httpService";

import {env} from "../env";
const URL = `${env.API__BACKEND}/api`;

export const getAllRepresentatives = async (accessToken, id) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v1/Representatives/${id}`;

  try {
    let response = await httpPost(uri, getOptions);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const getRepresentativeByProcedureId = async (
  accessToken,
  procedureId
) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v1/Representatives/procedureId/${procedureId}`;

  try {
    let response = await httpGet(uri, getOptions);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const postRepresentative = async (accessToken, data) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v1/Representatives`;

  try {
    let response = await httpPost(uri, data, getOptions);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const deleteRepresentative = async (accessToken, id) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v1/Representatives/${id}`;

  try {
    let response = await httpDelete(uri, getOptions);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
};
