import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showSuccess, showWarning } from "../../../utils/utils";
import {
  deleteMember,
  getMembersByProcedureId,
  postMember,
} from "../../../services/membersService";
import { clearPerson } from "../../../features/associateCuit/associateCuitSlice";
import { showError } from "../../../utils/utils";


//TODO agregar persistencia al localstorage
const initialState = {
  membersList: [],
  kindOfPersonSelected: "",
};

export const tryPostMember = createAsyncThunk(
  "membersUpload/postMember",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const procedureId =
        thunkAPI.getState().municipalAuthorization?.procedureId;
      const response = await postMember(token, data);
      if (response.hasOwnProperty("error") && response?.error != null) {
        showWarning(
          "No se ha podido cargar el integrante",
          "Recuerde que debe tener al menos uno para poder finalizar el trámite."
        );
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      showSuccess("Integrante cargado correctamente", "");
      thunkAPI.dispatch(tryGetMembersByProcedureId(procedureId));
      thunkAPI.dispatch(clearPerson());
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//XXX: todavía sin usar
export const tryModifyMember = createAsyncThunk(
  "membersUpload/modifyMember",
  async (data, thunkAPI) => {
    try {
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryGetMembersByProcedureId = createAsyncThunk(
  "membersUpload/getMembers",
  async (state, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const procedureId =
        thunkAPI.getState().municipalAuthorization?.procedureId;
      const response = await getMembersByProcedureId(token, procedureId);
      if (response.hasOwnProperty("error") && response?.error != null) {
        showWarning(
          "No se pudieron recuperar los integrantes asociados al trámite",
          ""
        );
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryDeleteMember = createAsyncThunk(
  "membersUpload/deleteMember",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const procedureId =
        thunkAPI.getState().municipalAuthorization?.procedureId;
      const response = await deleteMember(token, id);
      if (response.hasOwnProperty("error") && response?.error != null) {
        showWarning(
          "No se pudieron recuperar los integrantes asociados al trámite",
          ""
        );
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      thunkAPI.dispatch(tryGetMembersByProcedureId(procedureId));
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const membersUploadSlice = createSlice({
  name: "membersUpload",
  initialState,
  reducers: {
    setKindOfPersonSelected: (state, action) => {
      state.kindOfPersonSelected = action.payload;
    },
    addMember: (state, action) => {
      if (action.payload?.memberType?.id !== 0 && action.payload?.memberType?.label !== '') {
        state.membersList = [...state.membersList, action.payload];
      } else {
        showWarning("Debe ingresar un cargo");
      }
    },
    removeMember: (state, action) => {
      state.membersList = state.membersList.filter(
        (member) => member.id !== action.payload
      );
    },
    clearMembers: (state, action) => {
      state.membersList = [];
    },
  },
  extraReducers: (builder) => {
    // TRY POST MEMBER
    builder.addCase(tryPostMember.fulfilled, (state, action) => {
      state.hasError = false;
      state.isFetching = false;
    });
    builder.addCase(tryPostMember.pending, (state, action) => {
      state.hasError = false;
      state.isFetching = true;
    });
    builder.addCase(tryPostMember.rejected, (state, action) => {
      state.hasError = true;
      state.isFetching = false;
    });

    // GET MEMBERS LIST BY PROCEDURE ID
    builder.addCase(tryGetMembersByProcedureId.fulfilled, (state, action) => {
      state.membersList = action.payload;
      state.hasError = false;
      state.isFetching = false;
    });
    builder.addCase(tryGetMembersByProcedureId.pending, (state, action) => {
      state.hasError = false;
      state.isFetching = true;
    });
    builder.addCase(tryGetMembersByProcedureId.rejected, (state, action) => {
      state.hasError = true;
      state.isFetching = false;
    });

    // MODIFY MEMBER
    builder.addCase(tryModifyMember.fulfilled, (state, action) => {
      state.hasError = false;
      state.isFetching = false;
    });
    builder.addCase(tryModifyMember.pending, (state, action) => {
      state.hasError = false;
      state.isFetching = true;
    });
    builder.addCase(tryModifyMember.rejected, (state, action) => {
      state.hasError = true;
      state.isFetching = false;
    });
  },
});

export const {
  setKindOfPersonSelected,
  addMember,
  removeMember,
  clearMembers,
} = membersUploadSlice.actions;

export default membersUploadSlice.reducer;
