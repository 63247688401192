import * as React from "react";
import {
  Typography,
  Box,
  Grid,
  Link,
  Modal,
  Input,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import AppLoading from "../common/appLoading";
import {
  SendTokenAssociateCuit,
  ValidationTokenAssociateCuit,
} from "../../features/associateCuit/associateCuitSlice";
import { handleMaxLenght, maskMail } from "../../utils/utils";
import { tryCloseModal } from "./modalSlice";

const modalBoxStyle = {
  position: "absolute",
  top: "50vh",
  left: "50vw",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 400,
  bgcolor: "background.paper",
  borderRadius: "2rem",
  boxShadow: "0rem 0.4rem 1rem rgb(0, 0, 0, 0.3)",
  p: 4,
  padding: "4rem",
};

const inputStyleProps = {
  inputProps: { maxLength: 9999 },
  style: {
    borderRadius: "10rem",
    borderColor: "rgba(0,0,0,0)",
    fontFamily: "Open sans",
    fontSize: "3rem",
    fontWeight: "bolder",
    textAlign: "center",
    color: "#1A3762",
    height: "4rem",
    letterSpacing: "0.5rem",
  },
};

const closeBtnStyle = {
  float: "right",
  color: "black",
  fontSize: "2.2rem",
  mt: "-2rem",
  mr: "-2rem",
  "&:hover": { cursor: "pointer" },
};

export default function ConfirmEmailModal(props) {
  const { cuit } = props;
  const stateModal = useSelector((state) => state?.modal?.showModal);
  const stateChildModal = useSelector((state) => state?.modalChild?.showModal);
  const isFetchingToken = useSelector(
    (state) => state?.associateCuit?.isFetchingToken
  );
  const afipData = useSelector((state) => state?.associateCuit?.person?.emails?.map((element) => {
    return (element?.state === "Confirmado" ? element?.email : "");
  }));
  const emailData = afipData !== undefined ? afipData.at(-1) : "";
  let email = maskMail(emailData)
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    token: yup
      .string()
      .required("Debe ingresar el código")
      .matches(/^(?=.{4,4})/, "El codigo debe ser de 4 dígitos"),
  });

  const formik = useFormik({
    initialValues: {
      token: "",
    },
    onSubmit: (values) => {
      const data = {
        token: values?.token,
        cuit: cuit,
      };
      dispatch(ValidationTokenAssociateCuit(data));
    },
    validationSchema: validationSchema,
  });

  const hendleCloseModal = () => {
    dispatch(tryCloseModal());
  };

  const handleMailValidate = () => {
    dispatch(SendTokenAssociateCuit(cuit));
  };


  return (
    <div>
      <Modal
        open={stateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        display={!stateChildModal}
        sx={{ display: stateChildModal ? "none" : true }}
      >
        <Box component="form" onSubmit={formik.handleSubmit} sx={modalBoxStyle}>
          <CloseIcon sx={closeBtnStyle} onClick={hendleCloseModal} />
          <Grid
            container
            spacing="1rem"
            sx={{ justifyContent: "center", textAlign: "center" }}
          >
            <Grid item xs={12} md={8}>
              <Typography id="modal-title" variant="title2">
                Confirmación
              </Typography>
            </Grid>
            <Grid item xs={12} md={10} sm={10} sx={{textAlign:"center"}}>
              <Typography id="modal-description" variant="textDescription">
                Ingrese el código de verificación que fue enviado al correo electrónico <b>{email}</b> constituido 
                como domicilio fiscal electrónico ante la AFIP para confirmar la administración de la persona.
              </Typography>
            </Grid>
            <Grid item sx={{ textAlign: "center", width: "50%" }}>
              <Input
                id="token"
                name="token"
                type="number"
                variant="codeInput"
                inputProps={inputStyleProps}
                value={formik.values.token}
                onChange={formik.handleChange}
                error={formik.touched.token && Boolean(formik.errors.token)}
                sx={{
                  width: "80%",
                  mt: "1rem",
                  boxShadow: "inset 0rem 0.2rem 0.6rem rgba(0, 0, 0, 0.2)",
                  "&:before": {
                    display: "none",
                  },
                }}
                onInput={(e) => {
                  handleMaxLenght(e, 4);
                }}
              />
              <Typography
                variant="h6"
                textAlign="center"
                sx={{ fontSize: "1rem", color: "red" }}
              >
                {formik.touched.token && formik.errors.token}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} sx={{ textAlign: "center" }}>
              <Button
                variant="success"
                type="submit"
                disabled={isFetchingToken}
                sx={{ mt: 1, mb: 2, width: "60%", color: "white" }}
              >
                <Typography
                  variant="title2"
                  textAlign="left"
                  sx={{ fontWeight: 400, color: "white", fontSize: "1.6rem" }}
                >
                  {isFetchingToken ? <AppLoading size={3} /> : "Validar"}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} md={8} sx={{ textAlign: "center" }}>
              <Link variant="linkBlue" onClick={handleMailValidate}>
                Reenviar código
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
