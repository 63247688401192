import * as React from "react";
import { Typography, Box, Grid, Modal, Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const style = {
  position: "absolute",
  top: "50vh",
  left: "50vw",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  bgcolor: "background.paper",
  borderRadius: "2rem",
  boxShadow: "0rem 0.4rem 1rem rgb(0, 0, 0, 0.3)",
  pt: "4rem",
  pb: "4rem",
  pr: "5rem",
  pl: "5rem",
  display: "flex",
  alignItems: "center",
  height: "fit-content",
  width: { xs: "100%", sm: "80%", md: "auto" },
};

export default function RequirementsReviewModal({
  title = "Título del modal",
  description = "Aquí va la descripción del modal",
  acceptText = "Aceptar",
  cancelText = "No, volver atrás",
  loading,
  onSubmit,
  handleCloseModal,
  showModal,
}) {
  return (
    <div>
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container gap={4}>
            <Grid
              item
              xs={12}
              sx={{
                p: { xs: "0px", lg: "4rem 2rem 2rem 2rem" },
              }}
            >
              <div>
                <Typography
                  id="modal-description"
                  variant="textDescription"
                  sx={{ mt: 2, fontSize: "1.4rem", fontWeight: "400", lineHeight:"2.4rem" }}
                >
                  Los aspirantes a proveedor no deben encontrarse inhibidos y no
                  pueden ser empleados del Estado según las incompatibilidades
                  previstas en el Art. 70 de la Constitución Provincial y Art.
                  40° Inc. b) y c) de la Ley I N° 37 (antes Decreto Ley
                  1556/82).
                </Typography>
                <div style={{ marginBottom: "2rem" }} />
                <Typography
                  id="modal-description"
                  variant="textDescription"
                  sx={{ mt: 2, fontSize: "1.6rem", fontWeight: 700, lineHeight:"2.4rem" }}
                >
                  Declaro haber corroborado los datos y la documentación
                  adjunta, siendo consciente de que los mismos revisten el
                  carácter de Declaración Jurada, además de no encontrarme
                  inhibido ni ser empleado del Estado.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center", p: "0px" }}>
              <Button
                onClick={handleCloseModal}
                variant="bold"
                sx={{
                  width: "20rem",
                  m: "0.5rem",
                }}
              >
                {cancelText}
              </Button>
              <Button
                onClick={onSubmit}
                variant="success"
                sx={{
                  width: "20rem",
                  m: "0.5rem",
                }}
              >
                {acceptText}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
