import { toast } from "react-toastify";

const notificationBody = (title, message) => {
  return (
    <>
      <b>{title}</b>
      <br />
      {message}
    </>
  );
};

export const showSuccess = (title, message = "", time = 10000) => {
  toast.success(notificationBody(title, message), { autoClose: time });
};

export const showError = (title, message = "", time = 10000) => {
  toast.error(notificationBody(title, message), { autoClose: time });
};

export const showInfo = (title, message = "", time = 10000) => {
  toast.info(notificationBody(title, message), { autoClose: time });
};

export const showWarning = (title, message = "", time = 10000) => {
  toast.warning(notificationBody(title, message), { autoClose: time });
};

export const capitalizeCase = (word) => {
  let text = word
    .toLowerCase()
    .split(" ")
    .map((e) => e.charAt(0).toUpperCase() + e.substring(1))
    .join(" ");
  return text;
};

export const lowerCase = (text) => {
  if (!text) {
    return "";
  }

  return (
    text.toLowerCase().charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  );
};

  const providerStatusCodes = {
    status: {
      borrador: {
        code: 1,
        description: "Borrador",
        fontColor: "#1a3170",
        backgroundColor: "#e3ebff",
      },
      enRevision: {
        code: 2,
        description: "En Revisión",
        fontColor: "#907200",
        backgroundColor: "#fcf8d8",
      },
      denegado: {
        code: 3,
        description: "Denegado",
        fontColor: "#A30D11",
        backgroundColor: "#FBE7E8",
      },
      aceptado: {
        code: 4,
        description: "Aceptado",
        fontColor: "#1F9254",
        backgroundColor: "#EBF9F1",
      },
      observado: {
        code: 5,
        description: "Observado",
        fontColor: "#A30D11",
        backgroundColor: "#FBE7E8",
      },
      desistido: {
        code: 6,
        description: "Desistido",
        fontColor: "#666666",
        backgroundColor: "#eeeeee",
      },
      aValidar: {
        code: 7,
        description: "A Validar",
        fontColor: "#8e3600",
        backgroundColor: "#ffd3aa",
      },
    },
  };

  export const providerDetailsStatusCodes = {
    status: {
      aceptado: {
        code: [4],
        description: "Aceptado",
        fontColor: "#1F9254",
        backgroundColor: "#EBF9F1",
      },
      enTramiteSafi: {
        code: [2, 3],
        description: "En trámite",
        fontColor: "#CD6200",
        backgroundColor: "#FEF2E5",
      },
      enRevisionSafi: {
        code: [3],
        description: "En trámite",
        fontColor: "#CD6200",
        backgroundColor: "#FEF2E5",
      },
      pendiente: {
        code: [1],
        description: "Pendiente",
        fontColor: "#1F9254",
        backgroundColor: "#EBF9F1",
      },
      baja: {
        code: [5, 6, 7],
        description: "Baja",
        fontColor: "#A30D11",
        backgroundColor: "#FBE7E8",
      },
    },
  };

export function capitalizeTitle(title) {
  // Funcion para capitalizar textos excepto preposiciones
  const articlesToExclude = [
    "el",
    "la",
    "los",
    "las",
    "un",
    "una",
    "unos",
    "unas",
    "y",
    "o",
    "con",
    "de",
    "en",
    "a",
    "por",
    "para",
    "a",
    "ante",
    "bajo",
    "cabe",
    "con",
    "contra",
    "de",
    "desde",
    "durante",
    "en",
    "entre",
    "hacia",
    "hasta",
    "mediante",
    "para",
    "por",
    "según",
    "sin",
    "so",
    "sobre",
    "tras",
    "versus",
    "vía",
  ];

  const words = title.toLowerCase().split(" ");

  const capitalizedWords = words.map((word, index) => {
    if (index === 0 || !articlesToExclude.includes(word)) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    } else {
      return word;
    }
  });

  return capitalizedWords.join(" ");
}

export const toogleCase = (word) => {
  let text = word
    .toUpperCase()
    .split(" ")
    .map((e) => e.charAt(0).toLowerCase() + e.substring(1))
    .join(" ");
  return text;
};

export const sentenceCase = (word) => {
  let text = word
    .split(". ")
    .map((e) => e.charAt(0).toUpperCase() + e.substring(1).toLowerCase())
    .join(". ");
  return text;
};

export const util_getTomorrowDate = () => {
  const theDate = new Date();
  theDate.setDate(theDate.getDate() + 1);
  return theDate;
};

export const getMinDateValue = (days) => {
  const theDate = new Date();
  theDate.setDate(theDate.getDate() + days);
  return theDate;
};

export const getMaxDateValue = (anios) => {
  const theDate = new Date();
  theDate.setFullYear(theDate.getFullYear() + anios);
  return theDate;
};

export const cuitIsValid = (cuit) => {
  let verificator = 0;
  let result = 0;
  let mult = [];

  cuit = cuit.replace(/-/g, "");

  if (cuit.length !== 11) {
    return false;
  }

  const CUITType = parseInt(cuit.substring(0, 2));
  if (![20, 23, 24, 25, 26, 27, 30, 33, 34].includes(CUITType)) {
    return false;
  }

  mult = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
  verificator = parseInt(cuit.charAt(10));

  for (let x = 0; x < 10; x++) {
    result += mult[x] * parseInt(cuit.charAt(x));
  }

  result = result % 11;
  result = 11 - result;

  if (result === 11) {
    result = 0;
  } else if (result === 10) {
    result = 9;
  }

  if (result === verificator) {
    return true;
  } else {
    return false;
  }
};

export function dateToYMD(date, limit = 1, yearLimit = 0, addDay = 0) {
  var d = date.getDate() + addDay;
  var m = date.getMonth() + limit > 12 ? 1 : date.getMonth() + limit;
  var y =
    date.getMonth() + limit > 12
      ? date.getFullYear() + (yearLimit + 1)
      : date.getFullYear() + yearLimit;
  return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
}

export function stringDateToDMY(stringDate, separator = "-") {
  var aux = stringDate?.split("-");
  return aux?.at(-1) + separator + aux?.at(-2) + separator + aux?.at(0);
}

export function stringDateToYMD(stringDate, separator = "-") {
  var aux = stringDate?.split("-");
  return aux?.at(-1) + separator + aux?.at(-2) + separator + aux?.at(0);
}

//Useful for manage the max lenght props in f** inputs
export const handleMaxLenght = (e, maxLength) => {
  e.target.value = Math.max(0, parseInt(e.target.value))
    .toString()
    .slice(0, maxLength);
};

export const memberTypes = [
  { id: 1, label: "PRESIDENTE" },
  { id: 2, label: "VICE PRESIDENTE" },
  { id: 3, label: "TESORERO" },
  { id: 4, label: "GERENTE" },
  { id: 6, label: "ADMINISTRADOR" },
  { id: 7, label: "DIRECTOR" },
];

export const maskedInputStyle = {
  style: {
    borderRadius: "10rem",
    borderColor: "rgba(0,0,0,0)",
    fontFamily: "Open sans",
    fontSize: "1.6rem",
    fontWeight: "bolder",
    textAlign: "center",
    color: "#1A3762",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    letterSpacing: "0.5rem",
  },
};

export const addressTypes = [
  { id: 1, label: "FISCAL" },
  { id: 2, label: "LEGAL/REAL" },
  { id: 3, label: "LOCALES Y ESTABLECIMIENTOS" },
  { id: 4, label: "LEGALMISIONES" },
];

export const procedureTypes = [
  { id: 1, label: "Inscription" },
  { id: 2, label: "Upgrade" },
  { id: 3, label: "Reinscription" },
  { id: 4, label: "ModificationEconomicActivitiesExtension" },
];

export const procedureStates = [
  { id: 1, label: "Draft" },
  { id: 2, label: "InRevision" },
  { id: 3, label: "Denied" },
  { id: 4, label: "Accepted" },
  { id: 5, label: "Observed" },
  { id: 6, label: "Desisted" },
];

export function simularRespuestaTiempoEspecifico(tiempo, respuesta) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(respuesta);
    }, tiempo);
  });
}

export function maskCUIT(cuit) {
  // Asumiendo que el CUIT es una cadena de 11 caracteres
  if (cuit?.length === 11) {
    const parte1 = cuit.substring(0, 2);
    const parte2 = cuit.substring(2, 10);
    const parte3 = cuit.substring(10);
    return `${parte1}-${parte2}-${parte3}`;
  } else {
    // Si el CUIT no tiene la longitud esperada, devuelve el valor original
    return cuit;
  }
};

export const getStatusDescriptionByCode = (
  statusCode,
  typeRes = "label",
  typeCode = 1,
) => {
  const { status } = (typeCode === 1) ? providerStatusCodes : providerDetailsStatusCodes;
  for (const key in status) {
    const currentStatus = status[key];
    if (Array.isArray(currentStatus.code)) {
      if (currentStatus.code.includes(statusCode)) {
        return typeRes === "label"
          ? currentStatus.description
          : typeRes === "fontColor"
          ? currentStatus.fontColor
          : currentStatus.backgroundColor;
      }
    } else {
      if (typeCode === 1 && (currentStatus.description.toUpperCase() === statusCode.toUpperCase())) {
        return typeRes === "label"
          ? currentStatus.description
          : typeRes === "fontColor"
          ? currentStatus.fontColor
          : currentStatus.backgroundColor;
      }
    }
  }
  
  return typeRes === "label" ? "-" : "rgba(255,255,255,0)";
};

export const maskMail = (mail) => {
  let email = mail;
  if (mail?.includes('@')) {
    const emailParts = mail.split("@");
    const chars =
      emailParts[0].length - Math.max(Math.ceil(emailParts[0].length * 0.7), 1);
    email = mail?.replace(
      /[a-z0-9\-_.]+@/gi,
      (c) =>
        c.substr(0, chars) +
        c
          .split("")
          .slice(chars, -1)
          .map((v) => "*")
          .join("") +
        "@"
    );
  }
  return email;
}