import axios from "axios";
import moment from 'moment';

export const httpGet = (uri, options) => axios.get(uri, {...options, signal: AbortSignal.timeout(60000)})

export const httpPost = (uri, params, options) => axios.post(uri, params, {...options, signal: AbortSignal.timeout(60000)})

export const httpPut = (uri, params, options) => axios.put(uri, params, {...options, signal: AbortSignal.timeout(60000)})

export const httpDelete = (uri, options) => axios.delete(uri, options)

export const httpPatch = (uri, params, options) => axios.patch(uri, params, {...options, signal: AbortSignal.timeout(60000)})

export const handleHttpError = (error) => {
    console.error("Detalles del error: ", error)
    return {
        error,
        response: error || error.response || error.response.data
    }
}

export const createCancelation = () => axios.CancelToken.source()

export const isCancel = (error) => axios.isCancel(error)

export const handleCancelation = (error) => {
    return {
        cancelled: true
    };
}

export const buildURLQuery = params => {
    var esc = encodeURIComponent;
    var query = Object.keys(params)
        .map(k => {
            let dato = '';
            if(params[k] || params[k] === 0) 
                dato = params[k];
            let url = esc(k) + '=' + esc(dato) 
            return url
        })
        .join('&');
    return query;
}

export const downloadReportDataDetailFile = (uri, options, title) => {
axios({
    url: uri,
    header: options,
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
     const url = window.URL.createObjectURL(new Blob([response.data]));
     const link = document.createElement('a');     
     let currentDate = moment(new Date()).format("DD/MM/YYYY");
     link.href = url;
     //El título esta hardcodeado
     let nameFile= `Informe_Tramites_Detalles_${currentDate}.xlsx`;
     link.setAttribute('download', nameFile); //or any other extension
     document.body.appendChild(link);
     link.click();
  })
}
//Basarse en esta función
export const downloadReportDataGeneralFile = (uri, params, options) => {
    axios({
        url: uri,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {           
            if (response?.data.size > 150) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');     
                let currentDate = moment(new Date()).format("DD/MM/YYYY");
                link.href = url;
                let nameFile= `Informe_Tramites_General_${currentDate}.xlsx`;
                link.setAttribute('download', nameFile); //or any other extension
                document.body.appendChild(link);
                link.click();
                //return response;  

            } else{

                let response = {};
                response.success= false;
                response.error = 'No se ha encontrado datos con esta consulta'; 
                return response;
            }
           
      })
      .catch(error => {
        console.log(error)
        //this.errored = true
      })
      .finally(() =>  console.log('finalizado'))
    }


export const downloadExcel = (uri, access_token, title) => {
    axios({
        headers: {
            'Authorization': `Bearer ${access_token}`
        },
        url:uri,
        method: 'GET',
        responseType: 'blob', // important
        }).then((response) => {           
            if (response?.data.size > 150) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a'); 
                link.href = url;
                let nameFile= `${title}.xlsx`;
                link.setAttribute('download', nameFile); //or any other extension
                document.body.appendChild(link);
                link.click();
                // return response;  

            } else{

                let response = {};
                response.success= false;
                response.error = 'No se ha encontrado datos con esta consulta'; 
                return response;
            }
            
        })
        .catch(error => {
        console.log(error)
        //this.errored = true
        })
        .finally(() =>  console.log('finalizado'))
}