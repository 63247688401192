import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  Tooltip,
  TableHead,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import TablePaginationActions from "../../common/tablePagination";
import {
  tryDeleteMunicipalAuthorization,
} from "./municipalAuthorizationSlice";
import { stringDateToDMY } from "../../../utils/utils";
function createData(
  province,
  locality,
  inscriptionNumber,
  expirationDate,
  actions
) {
  return { province, locality, inscriptionNumber, expirationDate, actions };
}

const MunicipalAuthorizationsTable = (props) => {
  const dispatch = useDispatch();
  const authorizationsList = useSelector(
    (state) => state?.municipalAuthorization?.authorizationsList
  );

  const rows =
    authorizationsList?.length > 0
      ? authorizationsList
      : [createData("-", "-", "-", "-", "-")];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (row) => {
    dispatch(tryDeleteMunicipalAuthorization(row));
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",
        backgroundColor: "rgba(255,255,255,0.3)",
        borderRadius: "1rem",
      }}
    >
      <Table sx={{ minWidth: "40rem" }}>
        <TableHead>
          <TableRow sx={{ textAlign: "center" }}>
            <TableCell>
              <b>PROVINCIA</b>
            </TableCell>
            <TableCell>
              <b>LOCALIDAD</b>
            </TableCell>
            <TableCell>
              <b>NÚMERO DE INSCRIPCIÓN</b>
            </TableCell>
            <TableCell>
              <b>FECHA DE VENCIMIENTO</b>
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <b>ACCIONES</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ textAlign: "center" }}>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row, index) => (
            <TableRow key={index} size="small">
              <TableCell
                size="small"
                // onClick={() => {
                //   handleSelectRow(row?.id);
                // }}
                sx={{ "&:hover": { cursor: "pointer" } }}
              >
                {row?.province?.name?.toUpperCase()}
              </TableCell>
              <TableCell
                size="small"
                // onClick={() => {
                //   handleSelectRow(row?.id);
                // }}
                sx={{ "&:hover": { cursor: "pointer" } }}
              >
                {row?.locality?.name}
              </TableCell>
              <TableCell
                size="small"
                // onClick={() => {
                //   handleSelectRow(row?.id);
                // }}
                sx={{ "&:hover": { cursor: "pointer" } }}
              >
                {row?.incriptionNumber}
              </TableCell>
              <TableCell
                size="small"
                // onClick={() => {
                //   handleSelectRow(row?.id);
                // }}
                sx={{ "&:hover": { cursor: "pointer" } }}
              >
                {/* TODO: Hacer conversion de fecha en BE */}
                {row?.expirationDate?.split("T")[0] === "-" || row?.expirationDate === undefined
                  ? "-"
                  : stringDateToDMY(row?.expirationDate?.split("T")[0]).replace(
                      /-/g,
                      "/"
                    )}
              </TableCell>
              <TableCell sx={{ textAlign: "center" }} size="small">
                {row?.id && (
                  <>
                    {/* <Tooltip
                      title={
                        <Typography fontSize="1.2rem">
                          Editar Habilitación
                        </Typography>
                      }
                    >
                      <IconButton
                        aria-label="info picture"
                        component="label"
                        onClick={() => {
                          handleSelectRow(row?.id);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip> */}
                    <Tooltip
                      title={
                        <Typography fontSize="1.2rem">
                          Eliminar Habilitación
                        </Typography>
                      }
                    >
                      <IconButton
                        aria-label="delete picture"
                        component="label"
                        onClick={() => handleDelete(row)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "Todo", value: -1 }]}
              colSpan={4}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "Elementos por página",
                },
                native: true,
              }}
              labelRowsPerPage={"Cantidad de filas"}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              sx={{
                ".MuiTablePagination-toolbar": {
                  fontSize: "1.4rem",
                },
                ".MuiTablePagination-displayedRows": {
                  fontSize: "1.4rem",
                },
                ".MuiTablePagination-selectLabel": {
                  fontSize: "1.4rem",
                },
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default MunicipalAuthorizationsTable;
