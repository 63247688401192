import React, { useState, useEffect } from "react";
import {
  Button,
  Snackbar,
  Alert,
  Typography,
  Grid,
  Slide,
} from "@mui/material";
import CookieTwoToneIcon from "@mui/icons-material/CookieTwoTone";
import { primaryColor } from "../../../src/components/pages/themes";

function TransitionUp(props) {
  return <Slide {...props} direction="up" timeout={2000} />;
}

const CookieConsent = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (consent !== "accepted") {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setOpen(false);
    enableCookies();
  };

  const handleReject = () => {
    localStorage.setItem("cookieConsent", "rejected");
    setOpen(false);
    disableCookies();
  };

  const enableCookies = () => {
    // TODO: Logica para aceptar cookies
    console.log("Cookies habilitadas");
  };

  const disableCookies = () => {
    // TODO: Logica para rechazar cookies
    console.log("Cookies deshabilitadas");
  };

  return (
    <Snackbar
      open={open}
      TransitionComponent={TransitionUp}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        icon={
          <CookieTwoToneIcon
            sx={{
              fontSize: { xs: "3rem", md: "4rem" },
              display: { xs: "none", sm: "block" },
              color: primaryColor,
            }}
          />
        }
        severity="info"
        action={
          <Grid
            container
            direction="column"
            spacing={2}
            sx={{ height: "100%", width: "fit-content" }}
          >
            <Button
              color="inherit"
              variant="blueBtn"
              sx={{
                fontSize: "1rem",
                width: "12rem",
                mt: "1rem",
                mx: "1rem",
                fontWeight: "bold",
                borderRadius: "2rem",
              }}
              onClick={handleAccept}
            >
              ACEPTAR
            </Button>
            <Button
              color="inherit"
              fontSize="inherit"
              sx={{
                fontSize: "0.8rem",
                width: "12rem",
                mx: "1rem",
                mb: "-2rem",
                borderRadius: "2rem",
              }}
              onClick={handleReject}
            >
              Rechazar
            </Button>
          </Grid>
        }
        sx={{
          width: {
            xs: "100vw",
            sm: "100vw",
            md: "90vw",
            lg: "80vw",
            xl: "70vw",
          },
          minHeight: "6rem",
          alignItems: "center",
          fontSize: { xs: "1rem", sm: "1.2rem" },
          backgroundColor: "#FFF",
          p: "1.5rem",
          boxShadow: "0rem 0rem 2rem rgba(0,0,0, 0.3)",
        }}
      >
        <Typography variant="textParagraph" fontSize="inherit" color="inherit">
          Esta página utiliza cookies para mejorar la experiencia del usuario.
          Al continuar navegando en este sitio, estás aceptando el uso de
          cookies.
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default CookieConsent;
