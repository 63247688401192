import * as React from "react";
import {
  Typography,
  Box,
  Grid,
  Modal,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import bannerWebContaduria from "../../assets/images/banner-web-contaduria.png";
import { useSelector } from "react-redux";
import AppLoading from "./appLoading";

const style = {
  position: "absolute",
  top: "50vh",
  left: "50vw",
  transform: "translate(-50%, -50%)",
  width: 600,
  minHeight: 600,
  borderRadius: "2rem",
  border: "0px solid rgba(0,0,0,0)",
  pt: "4rem",
  pb: "4rem",
  pr: "14rem",
  pl: "14rem",
  background:
    "linear-gradient(to top right, RGBA(150,200,255,0.8),rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))",
  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
  backdropFilter: "blur(7px)",
  ":-webkit-backdrop-filter": "blur(7px)",
  outline: "none",
};

export default function VersionModal(props) {
  const { showModal, handleClose, cfgVersion } = props;

  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") {
      handleClose();
    }
  };

  const isFetching = useSelector((state) => state.common?.isFetchingVersion);
  const apiVersions = [
    ...useSelector((state) => state.common?.apiVersions),
    {
      appName: cfgVersion?.name,
      assambly: cfgVersion?.version,
    },
  ];

  return (
    <div>
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={4}>
            <Grid item xs={12} sx={{ textAlign: "center", ml: "-1.5rem" }}>
              <img alt="Avatar usuario" src={bannerWebContaduria} width={300} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography id="modal-title" variant="title2">
                Acerca del Sistema
              </Typography>
            </Grid>
            {isFetching ? (
              <AppLoading size={5}></AppLoading>
            ) : (
              <Grid item xs={12}>
                <TableContainer sx={{ textAlign: "-webkit-center" }}>
                  <Table aria-label="simple table" sx={{ maxWidth: 400 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <b>MÓDULO</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>VERSIÓN</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {apiVersions?.map((item, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row" align="center">
                            {item?.appName}
                          </TableCell>
                          <TableCell align="center">{item?.assambly}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
