// custom history object to allow navigation outside react components
export const history = {
  navigate: (path, state) => {
    // Update the location state with the props you want to pass
    history.location = { pathname: path, state };
    // Perform the navigation
    window.history.pushState(state, "", path);
  },
  location: null,
};
