import * as React from "react";
import {
  Typography,
  Box,
  Grid,
  Modal,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { tryCloseModal } from "../../modals/modalSlice";
import Icons from "../../../assets/icons/Icons";
import AppLoading from "../../common/appLoading";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { primaryColor } from "../themes";

const style = {
  position: "absolute",
  top: "50vh",
  left: "50vw",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  bgcolor: "background.paper",
  borderRadius: "2rem",
  boxShadow: "0rem 0.4rem 1rem rgb(0, 0, 0, 0.3)",
  pt: "4rem",
  pb: "4rem",
  pr: "5rem",
  pl: "5rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "fit-content",
  width: { xs: "100%", sm: "80%", md: "auto" }
};

export default function RequirementsReviewModal(props) {
  const { handleFinish, optionSelected } = props;
  const stateModal = useSelector((state) => state?.modal?.showModal);
  const isFinished = useSelector((state) => state?.requirementsReview?.isFinished);
  const isFetching = useSelector((state) => state?.requirementsReview?.isFetching);
  const isError = useSelector((state) => state?.requirementsReview?.isError);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(tryCloseModal());
  };

  return (
    <div>
      <Modal
        open={stateModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {!isFinished ? (
            <Grid container gap={4}>
              <Grid item xs={12} sx={{ textAlign: "center", mt: "1rem" }}>
                <img
                  alt="Avatar usuario"
                  src={Icons.warningIcon}
                  width={50}
                  style={{
                    filter:
                      "hue-rotate(200deg) brightness(150%) grayscale(50%)",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  textAlign: "center",
                  p: { xs: "0px", lg: "0 10rem 0 10rem" },
                }}
              >
                <Typography
                  id="modal-description"
                  variant="textDescription"
                  sx={{ mt: 2 }}
                >
                  {optionSelected === 1 ? (
                    <>
                      Al validar los requisitos el trámite será enviado para su
                      revisión.
                      <br />
                      ¿Está seguro?
                    </>
                  ) : optionSelected === 0 ? (
                    <>
                      Si rechaza los requisitos este trámite será desistido y
                      deberá iniciar un nuevo trámite.
                      <br />
                      ¿Está seguro?
                    </>
                  ) : (
                    <>
                      Al solicitar cambios, el trámite volverá a su estado
                      anterior para que su gestor realice las modificaciones
                      necesarias.
                      <br />
                      ¿Está seguro?
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center", p: "0px" }}>
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                  variant="bold"
                  sx={{
                    width: "20rem",
                    m: "0.5rem",
                  }}
                >
                  No, volver atrás.
                </Button>
                <Button
                  onClick={() => {
                    handleFinish();
                  }}
                  variant="success"
                  sx={{
                    width: "20rem",
                    m: "0.5rem",
                  }}
                >
                  Sí, estoy seguro.
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Box textAlign="center">
              {isFetching ? (
                <Box height="100%" sx={{ mt: "25%" }}>
                  <AppLoading size={5} />
                  <Typography
                    variant="textParagraph"
                    justifyContent="center"
                    fontSize="1.6rem"
                    width="60%"
                    sx={{ color: "#1A3762" }}
                  >
                    Su trámite está siendo enviado
                  </Typography>
                  <br />
                  <Typography
                    variant="textParagraph"
                    justifyContent="center"
                    fontSize="1.6rem"
                    width="60%"
                    sx={{ color: "#1A3762" }}
                  >
                    Aguarde por favor
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ mt: "15%" }}>
                  <Typography id="modal-title" variant="title2">
                    {isError ? (
                      "No se pudo validar los requisitos"
                    ) : (
                      <>
                        {optionSelected === 1
                          ? "¡Requisitos validados con éxito!"
                          : optionSelected === 0
                          ? "¡Requisitos rechazados!"
                          : "¡Modificaciones solicitadas!"}
                        <br />
                        {optionSelected === 1
                          ? "Realizaremos la revisión de su trámite."
                          : optionSelected === 0
                          ? "Debe iniciar un nuevo trámite."
                          : "Se envió un correo electrónico a su gestor para que haga los cambios necesarios."}
                      </>
                    )}
                  </Typography>
                  <br />
                  <Box sx={{ mt: "5rem" }}>
                    {isError ? (
                      <ErrorIcon
                        id="modal-icon-error"
                        sx={{ fontSize: 75, color: primaryColor }}
                      ></ErrorIcon>
                    ) : (
                      <CheckCircleIcon
                        id="modal-icon-success"
                        sx={{ fontSize: 75, color: primaryColor }}
                      ></CheckCircleIcon>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
}
