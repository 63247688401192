import * as React from "react";
import {
  Typography,
  Box,
  Grid,
  Modal,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { tryCloseModal } from "../../modals/modalSlice";
import Icons from "../../../assets/icons/Icons";
import AppLoading from "../../common/appLoading";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const style = {
  position: "absolute",
  top: "50vh",
  left: "50vw",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 400,
  bgcolor: "background.paper",
  borderRadius: "2rem",
  boxShadow: "0rem 0.4rem 1rem rgb(0, 0, 0, 0.3)",
  pt: "4rem",
  pb: "4rem",
  pr: "14rem",
  pl: "14rem",
};

export default function RequirementModal(props) {
  const { handleFinish } = props;
  const stateModal = useSelector((state) => state?.modal?.showModal);
  const isFinished = useSelector((state) => state?.requirements?.isFinished);
  const isFetching = useSelector((state) => state?.requirements?.isFetching);
  const isError = useSelector((state) => state?.requirements?.isError);
  const dispatch = useDispatch();
  
  const handleClose = () => {
    dispatch(tryCloseModal());
  };

  return (
    <div>
      <Modal
        open={stateModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isFinished ? (
          <Box sx={style}>
            <Grid container spacing={4}>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <img alt="Avatar usuario" src={Icons.warningIcon} width={50} />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Typography id="modal-title" variant="title2">
                  Enviar Trámite
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  id="modal-description"
                  variant="textDescription"
                  sx={{ mt: 2, fontSize:"1.6rem" }}
                >
                  ¿Está seguro que desea enviar los requisitos cargados? Tenga
                  en cuenta que al hacer click en "Aceptar" ya no podrá
                  modificarlos. El trámite pasará a estar pendiente de
                  validación por el CUIT solicitante.
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center", mt: "4rem" }}>
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                  variant="bold"
                  sx={{
                    width: "20rem",
                    mr: "1rem",
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={() => {
                    handleFinish();
                  }}
                  variant="success"
                  sx={{
                    width: "20rem",
                    ml: "1rem",
                  }}
                >
                  Aceptar
                </Button>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box sx={style} textAlign="center">
            {isFetching ? (
              <Box height="100%" sx={{ mt: "25%" }}>
                <AppLoading size={5} />
                <Typography
                  variant="textParagraph"
                  justifyContent="center"
                  fontSize="1.6rem"
                  width="60%"
                  sx={{ color: "#1A3762" }}
                >
                  Su trámite está siendo enviado
                </Typography>
                <br />
                <Typography
                  variant="textParagraph"
                  justifyContent="center"
                  fontSize="1.6rem"
                  width="60%"
                  sx={{ color: "#1A3762" }}
                >
                  Aguarde por favor
                </Typography>
              </Box>
            ) : (
              <Box sx={{ mt: "15%" }}>
                <Typography
                  id="modal-title"
                  variant="title2"
                  color={isError ? "red" : "green"}
                >
                  {isError ? (
                    "No se pudo enviar el trámite"
                  ) : (
                    <>
                      ¡Requisitos adjuntados con éxito!
                      <br />
                      Se ha enviado un correo electrónico al proveedor para que pueda validarlos.
                    </>
                  )}
                </Typography>
                <br />
                <Box sx={{ mt: "5rem" }}>
                  {isError ? (
                    <ErrorIcon
                      id="modal-icon-error"
                      sx={{ fontSize: 75, color: "red" }}
                    ></ErrorIcon>
                  ) : (
                    <CheckCircleIcon
                      id="modal-icon-success"
                      sx={{ fontSize: 75, color: "green" }}
                    ></CheckCircleIcon>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Modal>
    </div>
  );
}
