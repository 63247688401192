import * as React from "react";
import { Typography, Box, Grid, Modal, Button } from "@mui/material";
import Icons from "../../assets/icons/Icons";

const style = {
  position: "absolute",
  top: "50vh",
  left: "50vw",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  bgcolor: "background.paper",
  borderRadius: "2rem",
  boxShadow: "0rem 0.4rem 1rem rgb(0, 0, 0, 0.3)",
  pt: "4rem",
  pb: "4rem",
  pr: "5rem",
  pl: "5rem",
  display: "flex",
  alignItems: "center",
  height: "fit-content",
  width: { xs: "100%", sm: "80%", md: "auto" },
};

export default function SimpleModal({
  title = "Título del modal",
  description = "Aquí va la descripción del modal",
  acceptText = "Aceptar",
  cancelText = "Cancelar",
  loading,
  onSubmit,
  handleCloseModal,
  showModal,
}) {
  return (
    <div>
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container gap={4}>
            <Grid item xs={12} sx={{ textAlign: "center", mt: "1rem" }}>
              <img
                alt="Icono de advertencia"
                src={Icons.warningIcon}
                width={50}
                style={{
                  filter: "hue-rotate(200deg) brightness(150%) grayscale(50%)",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "center",
                p: { xs: "0px", lg: "0 10rem 0 10rem" },
              }}
            >
              <div style={{marginBottom:"1rem"}}>
                <Typography
                  id="modal-description"
                  variant="textDescription"
                  sx={{ mb: 2, fontWeight: 700, fontSize: "1.6rem" }}
                >
                  {title}
                </Typography>
              </div>
              <div>
                <Typography
                  id="modal-description"
                  variant="textDescription"
                  sx={{ mt: 2 }}
                >
                  {description}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center", p: "0px" }}>
              <Button
                onClick={handleCloseModal}
                variant="bold"
                sx={{
                  width: "20rem",
                  m: "0.5rem",
                }}
              >
                {cancelText}
              </Button>
              <Button
                onClick={onSubmit}
                variant="success"
                sx={{
                  width: "20rem",
                  m: "0.5rem",
                }}
              >
                {acceptText}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
