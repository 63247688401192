import { Typography, Grid, Button, Container, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchManagedPersons,
  getAvailableTransactTypes,
} from "../../features/newTransact/newTransactSlice";
import { selectPerson } from "../../features/newTransact/newTransactSlice";
import { history } from "../../_helpers/history";
import AppLoading from "../common/appLoading";
import { Box } from "@mui/system";
import { Scrollbars } from "react-custom-scrollbars-2";
import { clearMembers } from "./membersUploadPage/membersUploadSlice";
import { clearPerson } from "../../features/associateCuit/associateCuitSlice";
import { maskCUIT } from "../../utils/utils";

export default function NewTransact(props) {
  const dispatch = useDispatch();
  const [userSelected, setUserSelected] = useState("");
  const users = useSelector((state) => state?.newTransact?.persons);
  const isFetching = useSelector((state) => state?.newTransact?.isFetching);
  const isError = useSelector((state) => state?.newTransact?.isError);

  useEffect(() => {
    dispatch(fetchManagedPersons());
    dispatch(clearMembers());
    dispatch(clearPerson());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserSelected = (item) => {
    setUserSelected(item);
  };

  const handleStartTransact = (person) => {
    dispatch(selectPerson(person));
    dispatch(getAvailableTransactTypes(person?.cuit));
    history.navigate("/new-transact-step-two");
  };

  const handleCancel = () => {
    history.navigate("/");
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      variant="custom"
    >
      <Grid
        container
        sx={{ width: "60vw", justifyContent: "left", textAlign: "left" }}
      >
        <Grid
          item
          xs={12}
          sx={{ ml: { xs: "0rem", md: "2rem", lg: "5rem" }, mt: "2rem" }}
        >
          <Typography variant="title">Inicio de trámite</Typography>
        </Grid>
        <Grid item xs={12} sx={{ ml: { xs: "0rem", md: "2rem", lg: "5rem" } }}>
          <Typography variant="textParagraph">
            Seleccione la persona (humana o jurídica) con la que desea
            iniciar el trámite.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        sx={{ justifyContent: "center", textAlign: "center", p: "2vh" }}
        container
      >
        {isError ? (
          <Grid item>
            <Alert severity="info" sx={{ alignItems: "center" }}>
              <Typography variant="title3" xs={12} md={12} sx={{}}>
                No se pudo cargar la lista de personas asociadas.
              </Typography>
            </Alert>
          </Grid>
        ) : (
          <Grid
            item
            sx={{
              width: { xs: "100%", sm: "80%", md: "60%", lg: "60%", xl: "50%" },
            }}
          >
            <Grid item xs={12} md={12} sx={{ pb: "3vh" }}>
              <Typography variant="title2">Personas Disponibles</Typography>
            </Grid>
            <Grid
              item
              sx={{
                justifyContent: "center",
                textAlign: "center",
                height: "50vh",
              }}
            >
              <Scrollbars style={{ width: "100%", height: "45vh" }}>
                {users.length > 0 && !isFetching ? (
                  users.map((item, index) => (
                    <Box m={1} key={index}>
                      <Button
                        id={
                          item?.cuit === userSelected?.cuit
                            ? "bola__pulsante"
                            : ""
                        }
                        onClick={() => handleUserSelected(item)}
                        key={index}
                        variant={
                          item?.cuit === userSelected?.cuit
                            ? "blueBtn"
                            : "lightBlueBtn"
                        }
                        sx={{
                          width: "90%",
                          minHeight: "4rem",
                          maxHeight: "8rem",
                        }}
                      >
                        {maskCUIT(item?.cuit)} - {item?.name}
                      </Button>
                    </Box>
                  ))
                ) : isFetching ? (
                  <AppLoading size={5} />
                ) : (
                  <Container align="center" sx={{ pb: "5rem", pt: "5rem" }}>
                    <Typography variant="title3">
                      No posee personas disponibles.
                    </Typography>
                  </Container>
                )}
              </Scrollbars>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        spacing={2}
        marginBottom={5}
        sx={{ textAlign: "center", justifyContent: "center" }}
      >
        <Grid item xs={8} md={6}>
          <Button
            onClick={handleCancel}
            variant="bold"
            sx={{
              width: "20rem",
              position: { md: userSelected ? "fixed" : "" },
              left: { md: userSelected ? "18vw" : "" },
              bottom: { md: userSelected ? "5vh" : "" },
            }}
          >
            Cancelar
          </Button>
        </Grid>
        {users && userSelected && (
          <Grid item xs={8} md={6} align="center">
            <Button
              onClick={() => handleStartTransact(userSelected)}
              variant="success"
              sx={{
                width: "20rem",
                position: { md: userSelected ? "fixed" : "" },
                right: { md: "18vw" },
                bottom: { md: "5vh" },
              }}
            >
              Iniciar trámite
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
