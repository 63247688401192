import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequirementsToValidateList } from "../../../services/requirementsService";
import { showError, showSuccess } from "../../../utils/utils";
import { patchDesistProcedure, patchFinishProcedure, patchRequestChanges, resendEmailToValidate } from "../../../services/proceduresService";

const initialState = {
  requirementsToValidate: [],
  procedureData: {},
  isError: false,
    isFetching: false,
    isFinished: false,
};

export const tryGetRequirementstoValidateList = createAsyncThunk(
  "requirementsReview/getRequirementstoValidateList",
  async (checkCode, thunkAPI) => {
    try {
      const response = await getRequirementsToValidateList(checkCode);
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          "Ocurrió un problema al recuperar los requisitos...",
          response?.error?.message
        );
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryFinishProcedure = createAsyncThunk(
  "requirementsReview/finishProcedure",
  async (checkCode, thunkAPI) => {
    const error =
      "No se pudo finalizar el trámite, intente nuevamente en unos minutos.";

    try {
      const response = await patchFinishProcedure(checkCode);
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(error, "");
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryRejectProcedure = createAsyncThunk(
  "requirementsReview/rejectProcedure",
  async (checkCode, thunkAPI) => {
    const error = "No se pudo rechazar el trámite, intente nuevamente en unos minutos.";

    try {
      const response = await patchDesistProcedure(checkCode);
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(error, "");
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryRequestChanges = createAsyncThunk(
  "requirementsReview/requestChanges",
  async (checkCode, thunkAPI) => {
    const error = "No se pudo realizar la solicitud, intente nuevamente en unos minutos.";

    try {
      const response = await patchRequestChanges(checkCode);
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(error, "");
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryResendMailToValidate = createAsyncThunk(
  "requirementsReview/resendMailToValidate",
  async (procedureId, thunkAPI) => {
    const error = "No se pudo realizar la solicitud, intente nuevamente en unos minutos.";

    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await resendEmailToValidate(token, procedureId);
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(error, "");
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      showSuccess("¡Correo enviado con éxito!", "")
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
);

const requirementsReviewSlice = createSlice({
  name: "requirementsReview",
  initialState,
  reducers: {
    clearRequirementsState: (state, action) => {
      state.requirementsToValidate = [];
      state.isError = false;
      state.isFetching = false;
          state.isFinished = false;
    },
  },
  extraReducers: (builder) => {
    // FINALIZE UPLOADING OF PROCEDURE REQUIREMENTS
      builder.addCase(tryFinishProcedure.fulfilled, (state, action) => {
      state.isFinished = true;
      state.isError = false;
      state.isFetching = false;
      setTimeout(() => window.location.reload(), 5000);
    });
      builder.addCase(tryFinishProcedure.pending, (state, action) => {
      state.isFinished = true;
      state.isError = false;
      state.isFetching = true;
    });
      builder.addCase(tryFinishProcedure.rejected, (state, action) => {
      state.isError = true;
      state.isFetching = false;
      setTimeout(() => window.location.reload(), 5000);
    });

    // TRY GET REQUIREMENTS TO REVIEW LIST
    builder.addCase(
      tryGetRequirementstoValidateList.fulfilled,
      (state, action) => {
        state.requirementsToValidate = action.payload?.requirements;
        state.procedureData = action.payload?.procedure;
        state.isError = false;
        state.isFetching = false;
      }
    );
    builder.addCase(
      tryGetRequirementstoValidateList.pending,
      (state, action) => {
        state.isError = false;
        state.isFetching = true;
      }
    );
    builder.addCase(
      tryGetRequirementstoValidateList.rejected,
      (state, action) => {
        state.isError = false;
        state.isFetching = false;
      }
    );

    // TRY REJECT PROCEDURE
    builder.addCase(tryRejectProcedure.fulfilled, (state, action) => {
      state.isFinished = true;
      state.isError = false;
      state.isFetching = false;
      setTimeout(() => window.location.reload(), 5000);
    });
    builder.addCase(tryRejectProcedure.pending, (state, action) => {
      state.isFinished = true;
      state.isError = false;
      state.isFetching = true;
    });
    builder.addCase(tryRejectProcedure.rejected, (state, action) => {
      state.isError = true;
      state.isFetching = false;
      setTimeout(() => window.location.reload(), 5000);
    });
    
    // TRY REQUEST CHANGES
    builder.addCase(tryRequestChanges.fulfilled, (state, action) => {
      state.isFinished = true;
      state.isError = false;
      state.isFetching = false;
      setTimeout(() => window.location.reload(), 5000);
    });
    builder.addCase(tryRequestChanges.pending, (state, action) => {
      state.isFinished = true;
      state.isError = false;
      state.isFetching = true;
    });
    builder.addCase(tryRequestChanges.rejected, (state, action) => {
      state.isError = true;
      state.isFetching = false;
      setTimeout(() => window.location.reload(), 5000);
    });
    
    // TRY RESEND MAIL TO VALIDATE
    builder.addCase(tryResendMailToValidate.fulfilled, (state, action) => {
      state.isError = false;
      state.isFetching = false;
    });
    builder.addCase(tryResendMailToValidate.pending, (state, action) => {
      state.isError = false;
      state.isFetching = true;
    });
    builder.addCase(tryResendMailToValidate.rejected, (state, action) => {
      state.isError = true;
      state.isFetching = false;
    });
  },
});

export const { clearRequirementsState } = requirementsReviewSlice.actions;

export default requirementsReviewSlice.reducer;
