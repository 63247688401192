import {
    Grid,
  } from "@mui/material";
  import React from "react";
  import { Image } from "react-bootstrap";
  import banner from "../../assets/images/banner-web-contaduria.png";

  
  const LoginStructure = (props) => {

    return (
            <Grid container width="100%" sx={{my:"2rem"}}>
                <Grid item width="50%" textAlign="center" sx={{width:{ xs: '0%', sm:'0%', md:'50%', lg:'50%', xl: '50%' }}} alignSelf="center">
                  <Image fluid src={banner} width="80%"/>
                </Grid>
                <Grid item sx={{width:{ xs: '100%', sm:'100%', md:'50%', lg:'50%', xl: '50%' }}} align="center">
                    {props.children}
                </Grid>
            </Grid>
    );
  };
  
  export default LoginStructure;
  