import { handleHttpError, httpGet } from "./httpService";
import { buildDefaultOptions } from "./authService";
import { showError } from "../utils/utils";

import { env } from "../env";
const URL = `${env.API__BACKEND}/api`;

//get people managed by the logged user
export const getManagedPersons = async (
  accessToken,
  sortOptions = { pageSize: 50, page: 1, sortCriteria: "Id", sortOrder: "ASC" }
) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v1/Persons/logged?pageSize=${sortOptions?.pageSize}&page=${sortOptions?.page}&sortCriteria=${sortOptions?.sortCriteria}&sortOrder=${sortOptions?.sortOrder}`;

  try {
    let response = await httpGet(uri, getOptions);
    return response.data?.data;
  } catch (error) {
    showError("No se pudo obtener las personas asociadas.");
    return handleHttpError(error);
  }
};
