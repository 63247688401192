import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  showError,
  showSuccess,
  showWarning,
} from "../../utils/utils";
import { history } from "../../_helpers/history";
import { getAfipPerson, getAfipPersonName } from "../../services/afipService";
import {
  postSendTokenToAssociatePerson,
  postValidateTokenToAssociatePerson,
} from "../../services/userService";
import { getLocalitiesList } from "../newTransact/newTransactSlice";

const initialState = {
  person: {},
  personName: {},
  cuit: "",
  hasError: false,
  isFetching: false,
  isFetchingToken: false,
};

export const fetchAfipCuit = createAsyncThunk(
  "associateCuit/personData",
  async (data, thunkAPI) => {
    try {
      const response = await getAfipPerson(data?.cuit);
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          "El CUIT ingresado no es válido",
          response?.response?.response?.data?.Error?.Message.replace(
            /Id/g,
            "CUIT"
          )
        );
        return thunkAPI.rejectWithValue(
          response?.data?.error.replace ||
            response?.response?.response?.data?.Error?.Message
        );
      } else if (
        response?.data?.valid === false &&
        data?.isAssociate === true
      ) {
        showWarning(
          "El CUIT que intentó asociar no es admisible"
          // response?.data?.error
        );
      }
      return response;
    } catch (error) {
      console.log("Error: ", error);
    }
  }
);

export const SendTokenAssociateCuit = createAsyncThunk(
  "associateCuit/SendTokenAssociateCuit",
  async (data, thunkAPI) => {
    const token = thunkAPI.getState().login?.authentication?.access_token;
    try {
      const response = await postSendTokenToAssociatePerson(token, data);

      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          "No se ha podido enviar el código de validación...",
          response?.error?.response?.data?.error?.message
        );

        return thunkAPI.rejectWithValue(response?.error?.message);
      } else {
        showSuccess(
          "Éxito al enviar el código de validación",
          "Se ha enviado el código de validación..."
        );
      }

      return response;
    } catch (error) {
      console.log("error");
    }
  }
);

export const ValidationTokenAssociateCuit = createAsyncThunk(
  "associateCuit/ValidationTokenAssociateCuit",
  async (data, thunkAPI) => {
    const token = thunkAPI.getState().login?.authentication?.access_token;
    try {
      const response = await postValidateTokenToAssociatePerson(token, data);

      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          "No se ha podido verificar el código de validación...",
          response?.error?.response?.data?.error?.message
        );

        return thunkAPI.rejectWithValue(response?.error?.message);
      } else {
        showSuccess(
          "Éxito al verificar el código de validación",
          "Persona asociada a la cuenta con éxito para su administración de trámites..."
        );
      }

      return response;
    } catch (error) {
      console.log("error");
    }
  }
);

export const getPersonName = createAsyncThunk(
  "associateCuit/getPersonName",
  async (cuit, thunkAPI) => {
    try {
      const response = await getAfipPersonName(cuit);

      if (
        response.hasOwnProperty("error") &&
        response?.response?.response?.data != null
      ) {
        showError(
          "El CUIT ingresado no es válido",
          response?.response?.response?.data?.Error?.Message.replace(
            /Id/g,
            "CUIT"
          )
        );
        return thunkAPI.rejectWithValue(
          response?.data?.error.replace ||
            response?.response?.response?.data?.Error?.Message
        );
      }
      thunkAPI.dispatch(getLocalitiesList(response?.domiciles?.provinceId));
      return response;
    } catch (error) {
      console.log("Error: ", error);
    }
  }
);

const associateCuitSlice = createSlice({
  name: "associateCuit",
  initialState,
  reducers: {
    clearPerson: (state, action) => {
      state.person = { name: "" };
      state.cuit = "";
    },
    clearPersonName: (state, action) => {
      state.personName = {
        name: "",
        cuit: "",
        domicilies: { localityId: 0, provinceId: 0 },
        kindOfPerson: "",
      };
    },
  },
  extraReducers: (builder) => {
    // FETCH AFIP CUIT
    builder.addCase(fetchAfipCuit.fulfilled, (state, action) => {
      state.person = action.payload?.data;
      state.hasError = false;
      state.isFetching = false;
    });
    builder.addCase(fetchAfipCuit.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(fetchAfipCuit.rejected, (state, action) => {
      state.hasError = true;
      state.isFetching = false;
    });

    // POST ASSOCIATE CUIT PROCESS
    // SEND EMAIL WITH TOKEN
    builder.addCase(SendTokenAssociateCuit.fulfilled, (state, action) => {
      state.hasError = false;
      state.isFetchingToken = false;
    });
    builder.addCase(SendTokenAssociateCuit.pending, (state, action) => {
      state.isFetchingToken = true;
    });
    builder.addCase(SendTokenAssociateCuit.rejected, (state, action) => {
      state.hasError = true;
      state.isFetchingToken = false;
    });

    //  VALIDATE SENT OKEN
    builder.addCase(ValidationTokenAssociateCuit.fulfilled, (state, action) => {
      state.hasError = false;
      state.isFetchingToken = false;
      history.navigate("/");
    });
    builder.addCase(ValidationTokenAssociateCuit.pending, (state, action) => {
      state.isFetchingToken = true;
    });
    builder.addCase(ValidationTokenAssociateCuit.rejected, (state, action) => {
      state.hasError = true;
      state.isFetchingToken = false;
    });

    // GET PERSON NAME FROM AFIP
    builder.addCase(getPersonName.fulfilled, (state, action) => {
      state.personName = action.payload;
      state.hasError = false;
      state.isFetching = false;
    });
    builder.addCase(getPersonName.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(getPersonName.rejected, (state, action) => {
      state.hasError = true;
      state.isFetching = false;
    });
  },
});

export const { clearPerson, clearPersonName } = associateCuitSlice.actions;
export default associateCuitSlice.reducer;
