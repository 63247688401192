import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { history } from "../../../_helpers/history";
import {
  REPRESENTATIVES_URI,
  REQUIREMENTS_URI,
} from "../../../routing/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getLocalitiesList,
  getProvincesList,
} from "../../../features/newTransact/newTransactSlice";
import {
  tryPostMunicipalAuthorization,
  setModificationBoolean,
  tryModifyMunicipalAuthorization,
} from "./municipalAuthorizationSlice";
import { dateToYMD } from "../../../utils/utils";
import MunicipalAuthorizationsTable from "./municipalAuthorizationsTable";
import { isNonEmptyArray } from "../../../services/validationService";

function MunicipalAuthorizationPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProvincesList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isModification = useSelector(
    (state) => state?.municipalAuthorization?.isModification
  );
  const provinciasState = useSelector((state) => state?.newTransact?.provinces);
  const provincias = provinciasState?.data?.map((item) => ({
    label: item?.name,
    id: item?.id,
  }));
  const localidadesState = useSelector(
    (state) => state?.newTransact?.localities?.data?.data
  );
  let localidades = localidadesState?.map((item) => ({
    label: item?.name,
    id: item?.id,
  }));
  const [showLocalities, setShowLocalities] = useState(true);

  const date = new Date();
  const todayDate = new Date();
  todayDate.setDate(todayDate.getDate() + 30);

  const today = dateToYMD(todayDate);
  const limitDate = dateToYMD(date, 2, 10);
  const stateDate = useSelector(
    (state) =>
      state?.municipalAuthorization?.selectedAuthorization?.expirationDate
  );

  const procedureId = useSelector(
    (state) => state?.municipalAuthorization?.procedureId
  );
  const typeOfProcedureSelected = useSelector(
    (state) => state?.municipalAuthorization?.type
  );
  const defaultInscriptionNumber = useSelector(
    (state) =>
      state?.municipalAuthorization?.selectedAuthorization?.incriptionNumber
  );
  const defaultProvince = useSelector(
    (state) => state?.municipalAuthorization?.selectedAuthorization?.province
  );
  const defaultLocality = useSelector(
    (state) => state?.municipalAuthorization?.selectedAuthorization?.locality
  );
  const authorizationsList = useSelector(
    (state) => state?.municipalAuthorization?.authorizationsList
  );
  const isNonEmptyAuthorizationList = isNonEmptyArray(authorizationsList);

  const todayPlus30 = () => {
    let date = new Date();
    date.setDate(date.getDate() + 30);
    return dateToYMD(date);
  };

  //TODO: revisar validacion de formik al precargar datos
  const validationSchema = yup.object({
    province: yup
      .object()
      .required("Debe ingresar una localidad")
      .test("not-empty-object", "Debe seleccionar una provincia", (value) => {
        // Verificar que el objeto no sea igual a { label: "", id: 0 }
        return value.label !== "" && value.id !== 0;
      }),
    locality: yup
      .object()
      .required("Debe ingresar una localidad")
      .test("not-empty-object", "Debe seleccionar una localidad", (value) => {
        // Verificar que el objeto no sea igual a { label: "", id: 0 }
        return value.label !== "" && value.id !== 0;
      }),
    inscriptionNumber: yup
      .string()
      .required("Debe ingresar el número de inscripción"),
    expirationDate: yup
      .date()
      .required("Debe ingresar una fecha")
      .min(new Date(todayPlus30()), "La fecha no puede ser menor a 30 días"),
  });

  const dateArray = stateDate?.split("T");
  let stateDateFormatted = dateArray ? dateArray[0] : "";

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      province:
        defaultProvince !== ""
          ? { label: defaultProvince?.name, id: defaultProvince?.id }
          : "",
      locality:
        defaultLocality !== ""
          ? { label: defaultLocality?.name, id: defaultLocality?.id }
          : "",
      inscriptionNumber: defaultInscriptionNumber,
      expirationDate: stateDateFormatted,
    },
    onSubmit: (values, { resetForm }) => {
      dispatch(setModificationBoolean(false));
      let data = {
        provinceId: values?.province?.id,
        localityId: values?.locality?.id,
        inscriptionNumber: values?.inscriptionNumber,
        expirationDate: values?.expirationDate,
        procedureId: procedureId,
      };
      if (isModification) {
        dispatch(tryModifyMunicipalAuthorization(data));
      } else {
        dispatch(tryPostMunicipalAuthorization(data));
      }
      resetForm({ values: "" });
    },
    validationSchema: validationSchema,
  });

  const handleChange = (value) => {
    dispatch(getLocalitiesList(value.id));
    setShowLocalities(false);
  };

  const handleContinue = () => {
    if (typeOfProcedureSelected === "Ampliación de Rubro") {
      history.navigate(REQUIREMENTS_URI);
    } else {
      history.navigate(REPRESENTATIVES_URI);
    }
    dispatch(setModificationBoolean(false));
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={formik.handleSubmit}
      enableReinitialize
    >
      <Grid
        container
        direction="column"
        sx={{ width: "60vw", position: "center", mr: "10%" }}
      >
        <Grid
          item
          sx={{ ml: { xs: "0rem", md: "2rem", lg: "5rem" }, mt: "2rem" }}
        >
          <Typography variant="title">Habilitaciones Municipales</Typography>
        </Grid>
        <Grid item xs={12} sx={{ ml: { xs: "0rem", md: "2rem", lg: "5rem" } }}>
          <Typography variant="textParagraph">
            Recuerde que si su habilitación no posee fecha de vencimiento debe
            ingresar como tal dos años contados al día de la fecha.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        sx={{ placeContent: "center", mt: "1rem", width: "100%" }}
      >
        <Grid item sx={{ mt: 3, mr: { md: "1rem" } }}>
          <Autocomplete
            required
            disablePortal
            key={(option) => option.id}
            options={
              provincias
                ? provincias.sort((a, b) =>
                    a.label.localeCompare(b.label, undefined, {
                      sensitivity: "base",
                    })
                  )
                : [{ label: "Cargando...", id: 0 }]
            }
            id="province"
            name="province"
            placeholder="Provincia"
            label="Provincia"
            onChange={(e, value) => {
              handleChange(value);
              formik.setFieldValue(
                "province",
                value !== null ? value : { label: "", id: 0 }
              );
            }}
            renderOption={(props, option) => {
              return (
                <Box {...props} key={props?.id}>
                  {props?.key}
                </Box>
              );
            }}
            value={formik.values.province}
            sx={{ mb: "1rem", width: { xs: "60vw", md: "20rem" } }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Provincia"
                error={
                  formik.touched.province && Boolean(formik.errors.province)
                }
                helperText={formik.touched.province && formik.errors.province}
              />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            noOptionsText="No se encontraron resultados"
          />
        </Grid>
        <Grid item sx={{ mt: 3, ml: { md: "1rem" } }}>
          <Autocomplete
            required
            disablePortal
            key={(option) => option.id}
            disabled={showLocalities}
            options={
              localidades
                ? localidades.sort((a, b) =>
                    a.label.localeCompare(b.label, undefined, {
                      sensitivity: "base",
                    })
                  )
                : [{ label: "Cargando...", id: 0 }]
            }
            id="locality"
            name="locality"
            placeholder="Localidad"
            label="Localidad"
            onChange={(e, value) => {
              formik.setFieldValue(
                "locality",
                value !== null ? value : { label: "", id: 0 }
              );
            }}
            renderOption={(props, option) => {
              return (
                <Box {...props} key={props?.id}>
                  {props?.key}
                </Box>
              );
            }}
            value={formik.values.locality}
            sx={{ mb: "1rem", width: { xs: "60vw", md: "20rem" } }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Localidad"
                error={
                  formik.touched.locality && Boolean(formik.errors.locality)
                }
                helperText={formik.touched.locality && formik.errors.locality}
              />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            noOptionsText="No se encontraron resultados"
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        sx={{ placeContent: "center", width: "100%" }}
      >
        <Grid item sx={{ mt: 3, mr: { md: "1rem" } }}>
          <TextField
            FormHelperTextProps={{
              style: {
                fontSize: "1.2rem",
              },
            }}
            id="inscriptionNumber"
            name="inscriptionNumber"
            type="text"
            placeholder="Número de inscripción"
            label="Número de inscripción"
            value={
              formik?.values?.inscriptionNumber
                ? formik.values.inscriptionNumber
                : defaultInscriptionNumber
            }
            onChange={formik.handleChange}
            error={
              formik.touched.inscriptionNumber &&
              Boolean(formik.errors.inscriptionNumber)
            }
            helperText={
              formik.touched.inscriptionNumber &&
              formik.errors.inscriptionNumber
            }
            sx={{ mb: "1rem", width: { xs: "60vw", md: "20rem" } }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item sx={{ mt: 3, ml: { md: "1rem" } }}>
          <TextField
            FormHelperTextProps={{
              style: {
                fontSize: "1.2rem",
              },
            }}
            id="expirationDate"
            name="expirationDate"
            type="date"
            placeholder="Fecha"
            label="Fecha de vencimiento"
            value={
              formik?.values?.expirationDate
                ? formik.values.expirationDate
                : stateDateFormatted
            }
            onChange={formik.handleChange}
            error={
              formik.touched.expirationDate &&
              Boolean(formik.errors.expirationDate)
            }
            helperText={
              formik.touched.expirationDate && formik.errors.expirationDate
            }
            sx={{ mb: "1rem", width: { xs: "60vw", md: "20rem" } }}
            InputProps={{ inputProps: { min: today, max: limitDate } }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        sx={{
          width: "60%",
          textAlign: "center",
          mt: "3rem",
          mb: "3rem",
          ml: "20%",
          mr: "20%",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          align="center"
          sx={{ mb: { xs: "1rem", md: 0 } }}
        >
          <Button
            variant="lightBlueBtn"
            sx={{
              width: "12rem",
              boxShadow: "0rem 0.4rem 0.4rem rgba(0, 0, 0, 0.25)",
            }}
            onClick={formik.handleReset}
          >
            Limpiar
          </Button>
        </Grid>
        <Grid item xs={12} md={6} align="center">
          <Button
            variant="blueBtn"
            type="submit"
            sx={{
              width: "12rem",
              backgroundColor: "#0869AB",
              boxShadow: "0rem 0.4rem 0.4rem rgba(0, 0, 0, 0.25)",
            }}
          >
            {isModification ? "Modificar" : "Añadir"}
          </Button>
        </Grid>
      </Grid>

      <Grid
        item
        sx={{
          width: "90%",
          textAlign: "center",
          mb: "1rem",
          mr: "5%",
          ml: "5%",
        }}
      >
        <MunicipalAuthorizationsTable />
      </Grid>
      <Grid
        container
        direction="row"
        sx={{ width: "100%", textAlign: "center", my: "2rem" }}
      >
        <Grid item xs={12} md={6} align="center" alignSelf="center">
          <Button
            onClick={() => {
              history.navigate("/");
            }}
            variant="bold"
            sx={{
              width: "20rem",
              mb: { xs: "1rem", md: 0, mb: "2rem", mt: "2rem" },
            }}
          >
            Volver
          </Button>
        </Grid>
        <Grid item xs={12} md={6} align="center" alignSelf="center">
          <Button
            variant="success"
            onClick={handleContinue}
            sx={{ width: "20rem", mb: "2rem", mt: "2rem", md: 0 }}
            disabled={!isNonEmptyAuthorizationList}
          >
            Continuar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MunicipalAuthorizationPage;
