import { handleHttpError, httpGet } from "./httpService";
import { buildDefaultOptions } from "./authService";
import { showError } from "../utils/utils";
import { env } from "../env";


//get the selected person emails
export const getEmails = async (
  accessToken,
  personId,
  sortOptions = { pageSize: 50, page: 1, sortCriteria: "Id", sortOrder: "ASC" }
) => {

  let getOptions = buildDefaultOptions(accessToken);
  const URL = `${env.API__BACKEND}/api`;
  let uri = `${URL}/v1/Emails/personId/${personId}?pageSize=${sortOptions?.pageSize}&page=${sortOptions?.page}&sortCriteria=${sortOptions?.sortCriteria}&sortOrder=${sortOptions?.sortOrder}`;

  try {
    let response = await httpGet(uri, getOptions);
    return response.data?.data;
  } catch (error) {
    showError("No se pudieron obtener los emails del usuario.");
    return handleHttpError(error);
  }
};
