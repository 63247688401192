import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showWarning, showError } from "../../../utils/utils";
import { getActivitiesById, getProviderDetails } from "../../../services/userService";
import { clearNewTransactState, fetchManagedPersons, getAvailableTransactTypes, selectPerson } from "../../../features/newTransact/newTransactSlice";

const initialState = {
    associateDetails: null,
    economicActivities: null,
    isFetching: false,
};

export const tryGetAssociateDetails = createAsyncThunk(
  "associateDetails/getDetails",
  async (cuit, thunkAPI) => {
    try {
      thunkAPI.dispatch(clearNewTransactState());
      thunkAPI.dispatch(fetchManagedPersons()).then(() => {
        const managedPersons = thunkAPI.getState().newTransact?.persons;
        const person = managedPersons.find((p) => p.cuit === cuit);
        thunkAPI.dispatch(selectPerson(person));
        thunkAPI.dispatch(getAvailableTransactTypes(person?.cuit));
      });
      const response = await getProviderDetails(cuit);
      if (response.hasOwnProperty("error") && response?.error != null) {
        showWarning(
          "No se pudo recuperar la información de la persona",
          "Intente nuevamente más tarde."
        );
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      if (response?.status === 200 && response?.data === false) {
        return thunkAPI.rejectWithValue();
      }
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryGetActivitiesById = createAsyncThunk(
  "associateDetails/getEconomicActivities",
  async (id, thunkAPI) => {
    try {
      const response = await getActivitiesById(id);
      if (response.hasOwnProperty("error") && response?.error != null) {
        showWarning(
          "No se pudo recuperar la información de la persona",
          "Intente nuevamente más tarde."
        );
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


const associateDetailsSlice = createSlice({
  name: "membersUpload",
  initialState,
  reducers: {
    clearDetails: (state, action) => {
      state.associateDetails = null;
      state.economicActivities = null;
    },
  },
    extraReducers: (builder) => {
        // TRY GET ASSOCIATE DETAILS
        builder.addCase(tryGetAssociateDetails.fulfilled, (state, action) => {
            state.associateDetails = action.payload;
            state.hasError = false;
            state.isFetching = false;
        });
        builder.addCase(tryGetAssociateDetails.pending, (state, action) => {
            state.hasError = false;
            state.isFetching = true;
        });
        builder.addCase(tryGetAssociateDetails.rejected, (state, action) => {
            state.hasError = true;
            state.isFetching = false;
        });
      
        // TRY GET ECONOMIC ACTIVITIES V1
        builder.addCase(tryGetActivitiesById.fulfilled, (state, action) => {
            state.economicActivities = action.payload;
            state.hasError = false;
            state.isFetching = false;
        });
        builder.addCase(tryGetActivitiesById.pending, (state, action) => {
            state.hasError = false;
            state.isFetching = true;
        });
        builder.addCase(tryGetActivitiesById.rejected, (state, action) => {
            state.hasError = true;
            state.isFetching = false;
        });
    }
});

export const {
  clearDetails
} = associateDetailsSlice.actions;

export default associateDetailsSlice.reducer;
