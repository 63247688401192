import {
  Typography,
  Grid,
  Button,
  TextField,
  Box,
  Autocomplete,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../_helpers/history";
import {
  getProvincesList,
  getLocalitiesList,
  getMisionesLocalitiesList,
  selectPerson,
} from "../../features/newTransact/newTransactSlice";
import {
  MEMBERS_UPLOAD_URI,
  NEW_TRANSACT_STEP_FOUR,
  NEW_TRANSACT_STEP_TWO,
} from "../../routing/constants";
import { useFormik } from "formik";
import * as yup from "yup";

export default function NewTransactStepThree(props) {
  const dispatch = useDispatch();

  const addresses = useSelector(
    (state) => state?.newTransact?.selectedPerson?.addresses
  );
  const savedLegalMisionesAddress = useSelector(
    (state) => state?.newTransact?.selectedPerson?.legalMisionesAddress
  );
  const savedRealLegalAddress = useSelector(
    (state) => state?.newTransact?.selectedPerson?.realLegalAddress
  );
  const provincias = useSelector((state) => state?.newTransact?.provinces);
  const localidades = useSelector(
    (state) => state?.newTransact?.localities?.data?.data || null
  );

  let onlyProvincias = provincias?.data?.map((item) => ({
    label: item?.name,
    id: item?.id,
  }));
  const localidadesMisiones = useSelector(
    (state) => state?.newTransact?.misionesLocalities
  );
  let onlyLocalidadesMisiones = localidadesMisiones?.data?.data?.map(
    (item) => ({ label: item?.name, id: item?.id })
  );
  let onlyLocalidades = localidades?.map((item) => ({
    label: item?.name,
    id: item?.id,
  }));

  const realAddress = savedRealLegalAddress
  ? {
    description: savedRealLegalAddress?.description,
    localityId: savedRealLegalAddress?.locality?.id,
    provinceId: savedRealLegalAddress?.province?.id,
  }
  : addresses?.realAddress?.provinceId !== undefined
  ? addresses.realAddress
  : addresses?.fiscalAddress;
  useEffect(() => {
    dispatch(getProvincesList(realAddress?.provinceId));
    dispatch(getMisionesLocalitiesList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fiscalAddress = savedLegalMisionesAddress
    ? {
        description: savedLegalMisionesAddress?.description,
        localityId: savedLegalMisionesAddress?.locality?.id,
      }
    : addresses?.fiscalAddress;
  const selectedPerson = useSelector(
    (state) => state?.newTransact?.selectedPerson
  );
  const selectedProcedureId = useSelector(
    (state) => state?.newTransact?.selectedProcedureId
  );

  const defaultProvince = onlyProvincias?.find(
    (element) => element.id === realAddress?.provinceId
  );

  const defaultFiscalProvince = onlyProvincias?.find(
    (element) => element.id === fiscalAddress?.provinceId
  );

  const defaultLegalLocality = onlyLocalidadesMisiones?.find(
    (element) => element.id === fiscalAddress?.localityId
  );
  
  const defaultRealLocality = onlyLocalidades?.find(
    (element) => element.id === realAddress?.localityId
  );

  const validationSchema = yup.object({
    //Real/Legal
    real_province: yup.object().required("Debe ingresar una provincia"),
    real_locality: yup
      .object()
      .required("Debe ingresar una localidad")
      .test("not-empty-object", "Debe seleccionar una localidad", (value) => {
        // Verificar que el objeto no sea igual a { label: "", id: 0 }
        return value.label !== "" && value.id !== 0;
      }),
    real_address: yup.string().required("Debe ingresar un domicilio"),
    //Legal
    legal_locality: yup
      .object()
      .required("Debe ingresar una localidad")
      .test("not-empty-object", "Debe seleccionar una localidad", (value) => {
        // Verificar que el objeto no sea igual a { label: "", id: 0 }
        return value.label !== "" && value.id !== 0;
      }),
    legal_address: yup.string().required("Debe ingresar un domicilio"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      //Real/Legal
      real_province: defaultProvince
        ? defaultProvince
        : defaultFiscalProvince
        ? defaultFiscalProvince
        : { label: "", id: 0 },
      real_locality: defaultRealLocality
        ? defaultRealLocality
        : defaultLegalLocality
        ? defaultLegalLocality
        : { label: "", id: 0 },
      real_address: realAddress
        ? realAddress?.description
        : fiscalAddress?.description,
      //Legal
      legal_locality: defaultLegalLocality
        ? defaultLegalLocality
        : { label: "", id: 0 },
      legal_address:
        (defaultLegalLocality !== undefined)
          ? fiscalAddress?.description
          : "",
    },
    onSubmit: (values) => {
      handleContinue(values);
    },
    validationSchema: validationSchema,
  });

  const handleChange = (value) => {
    dispatch(getLocalitiesList(value.id));
  };

  const handleContinue = (values) => {
    const params = {
      id: selectedPerson?.id,
      emails: selectedPerson?.emails?.at(0),
      addresses: addresses,
      cuit: selectedPerson?.cuit,
      economicActivities: selectedPerson?.economicActivities,
      kindOfPerson: selectedPerson?.kindOfPerson,
      name: selectedPerson?.name,
      selectedProcedureId: selectedProcedureId,
      legalMisionesAddress: {
        locality: values?.legal_locality,
        description: values?.legal_address,
      },
      realLegalAddress: {
        locality: values?.real_locality,
        province: values?.real_province,
        description: values?.real_address,
      },
    };
    dispatch(selectPerson(params));
    history.navigate(
      selectedProcedureId === 4
        ? NEW_TRANSACT_STEP_FOUR
        : selectedPerson?.kindOfPerson === "JURIDICA"
        ? MEMBERS_UPLOAD_URI
        : NEW_TRANSACT_STEP_FOUR
    );
  };

  const handleCancel = () => {
    history.navigate(NEW_TRANSACT_STEP_TWO);
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      variant="custom"
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{ padding: "2rem" }}
    >
      <Grid
        sx={{
          width: { xs: "100vw", sm: "80vw", lg: "60vw" },
          justifyContent: "start",
          textAlign: "start",
          mb: "3rem",
        }}
        container
        spacing={0}
      >
        <Grid
          sx={{
            justifyContent: "start",
            textAlign: "start",
            pl: "10%",
            mb: "1rem",
          }}
          container
          spacing={0}
        >
          <Grid item xs={8} md={10} sx={{ py: "1rem" }}>
            <Typography
              variant="title"
              sx={{ fontSize: "2.4rem", fontWeight: 400 }}
            >
              Domicilio Real/Legal
            </Typography>
          </Grid>
          <Grid
            item
            xs={10}
            md={10}
            sx={{ textAlign: "-webkit-start", alignContent: { xs: "start" } }}
          >
            <Typography variant="textParagraph" sx={{ maxWidth: "50vw" }}>
              Lugar de radicación donde desarrolla sus actividades
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="column" alignItems="center">
          <Grid
            container
            direction="row"
            align="center"
            sx={{ placeContent: "center", my: "2rem" }}
          >
            <Grid item sx={{ mr: "1rem", width: { xs: "38%", md: "29%" } }}>
              <Autocomplete
                id="real_province"
                name="real_province"
                disablePortal
                // disabled={isModification}
                key={(option) => option.id}
                defaultValue={defaultProvince}
                value={formik.values.real_province}
                options={
                  onlyProvincias
                    ? onlyProvincias
                    : [{ label: "Cargando...", id: 0 }]
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={
                      formik.touched.real_province &&
                      Boolean(formik.errors.real_province)
                    }
                    helperText={
                      formik.touched.real_province &&
                      formik.errors.real_province
                    }
                    label="Provincia"
                  />
                )}
                onChange={(event, value) => {
                  handleChange(value);
                  formik.setFieldValue(
                    "real_province",
                    value !== null ? value : { label: "", id: 0 }
                  );

                  //Cleaning the locality field
                  formik.setFieldValue("real_locality", { label: "", id: 0 });
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={{ width: "100%" }}
                noOptionsText="No se encontraron resultados"
              />
            </Grid>
            <Grid item sx={{ ml: "1rem", width: "29%" }}>
              <Autocomplete
                id="real_locality"
                name="real_locality"
                disablePortal
                // disabled={isModification}
                value={formik.values?.real_locality}
                key={(option) => option.id}
                defaultValue={defaultRealLocality}
                options={
                  onlyLocalidades
                    ? onlyLocalidades.sort((a, b) =>
                        a.label.localeCompare(b.label, undefined, {
                          sensitivity: "base",
                        })
                      )
                    : [{ label: "Cargando...", id: 0 }]
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "real_locality",
                    newValue !== null ? newValue : { label: "", id: 0 }
                  );
                }}
                renderOption={(props, option) => {
                  return (
                    <Box {...props} key={props?.id}>
                      {props?.key}
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={
                      formik.touched.real_locality &&
                      Boolean(formik.errors.real_locality)
                    }
                    helperText={
                      formik.touched.real_locality &&
                      formik.errors.real_locality
                    }
                    label="Localidad"
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={{ width: "100%" }}
                noOptionsText="No se encontraron resultados"
              />
            </Grid>
          </Grid>
          <Grid item sx={{ width: { xs: "80%", md: "60%" } }}>
            <TextField
              id="real_address"
              name="real_address"
              label="Domicilio"
              variant="outlined"
              // disabled={isModification}
              value={formik.values.real_address}
              error={
                formik.touched.real_address &&
                Boolean(formik.errors.real_address)
              }
              helperText={
                formik.touched.real_address && formik.errors.real_address
              }
              defaultValue={realAddress ? realAddress?.description : ""}
              onChange={(event, newValue) => {
                formik.setFieldValue(
                  "real_address",
                  newValue !== null ? event?.target?.value : ""
                );
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        sx={{
          justifyContent: "start",
          textAlign: "start",
          padding: "1rem",
          width: { xs: "100vw", sm: "80vw", lg: "60vw" },
        }}
        container
        spacing={0}
      >
        <Grid
          sx={{
            justifyContent: "start",
            textAlign: "start",
            pl: "10%",
            pr: "10%",
            mb: "1rem",
          }}
          container
          spacing={0}
        >
          <Grid item xs={8} md={10} sx={{ py: "1rem" }}>
            <Typography
              variant="title"
              sx={{ fontSize: "2.4rem", fontWeight: 400 }}
            >
              Domicilio Legal en la provincia de Misiones
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ textAlign: "start", alignContent: "start" }}
          >
            <Typography variant="textParagraph">
              Corresponde a las firmas no radicadas en Misiones, siendo
              requisito indispensable fijar domicilio dentro del territorio de
              la provincia, sometiéndose expresamente a la justicia ordinaria de
              la misma. Art. 6° del pliego de condiciones generales.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          sx={{ placeContent: "center", width: "100%" }}
        >
          <Grid item sx={{ my: "2rem", width: { xs: "80%", md: "60%" } }}>
            <Autocomplete
              id="legal_locality"
              name="legal_locality"
              disablePortal
              // disabled={isModification}
              value={formik.values.legal_locality}
              key={(option) => option?.id}
              defaultValue={defaultLegalLocality}
              options={
                onlyLocalidadesMisiones
                  ? onlyLocalidadesMisiones.sort((a, b) =>
                      a.label.localeCompare(b.label, undefined, {
                        sensitivity: "base",
                      })
                    )
                  : [{ label: "Cargando...", id: 0 }]
              }
              onChange={(event, newValue) => {
                formik.setFieldValue(
                  "legal_locality",
                  newValue !== null ? newValue : { label: "", id: 0 }
                );
              }}
              renderOption={(props, option) => {
                return (
                  <Box {...props} key={props?.id}>
                    {props?.key}
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={
                    formik.touched.legal_locality &&
                    Boolean(formik.errors.legal_locality)
                  }
                  helperText={
                    formik.touched.legal_locality &&
                    formik.errors.legal_locality
                  }
                  label="Localidad"
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ width: "100%" }}
              noOptionsText="No se encontraron resultados"
            />
          </Grid>
          <Grid>
            <TextField
              id="legal_address"
              name="legal_address"
              // disabled={isModification}
              defaultValue={fiscalAddress ? fiscalAddress?.description : ""}
              value={formik.values.legal_address}
              error={
                formik.touched.legal_address &&
                Boolean(formik.errors.legal_address)
              }
              helperText={
                formik.touched.legal_address && formik.errors.legal_address
              }
              label="Domicilio"
              variant="outlined"
              onChange={(event, newValue) => {
                formik.setFieldValue(
                  "legal_address",
                  newValue !== null ? event?.target?.value : ""
                );
              }}
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{
          textAlign: "center",
          justifyContent: "center",
          mt: "5rem",
          padding: "1rem",
        }}
      >
        <Grid item xs={8} md={10}>
          <Button
            onClick={handleCancel}
            variant="bold"
            sx={{
              width: "20rem",
              position: { md: "fixed" },
              left: { md: "18vw" },
              bottom: { md: "5vh" },
            }}
          >
            Volver
          </Button>
        </Grid>
        <Grid item xs={8} md={10}>
          <Button
            type="submit"
            variant="success"
            sx={{
              width: "20rem",
              position: { md: "fixed" },
              right: { md: "18vw" },
              bottom: { md: "5vh" },
            }}
          >
            Continuar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
