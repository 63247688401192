//Configuración específica local

const appSettings = {
  appSettings: {
    name: "Portal v2",
    version: "1.4.0",
    state: {
      SAVING_PERIOD: 1000,
    },
    DATE_FORMAT: "DD/MM/YYYY",
    clientId: "825591671439-p2leffsl39mv0sef40gn8250b427njoh.apps.googleusercontent.com",
  },
};

export default appSettings;