import { httpPost, handleHttpError, httpGet, httpPut } from "./httpService";
import { buildDefaultOptions } from "./authService";

import {env} from "../env";

const URL = `${env.API__BACKEND}/api`;

export const getStorageFiles = async () => {  
    let uri = `${URL}/v1/Files`;
  
    try{
      let response = await httpGet(uri);
      return response.data;
    } catch(error){
      return handleHttpError(error);
    }
  };

export const getFile = async (accessToken, fileId) => {
    const uri = `${URL}/Download/${fileId}`;
    let getOptions = buildDefaultOptions(accessToken);
    getOptions.responseType = "blob";

    try {
        let response = await httpGet(uri, getOptions);
        return response;
    } catch (error) {
        return handleHttpError(error);
    }
}

export const postFile = async (accessToken, file) => {
    let getOptions = buildDefaultOptions(accessToken);
    const uri = `${URL}/v1/Requirements/UploadRequirement`;

    try {
        let response = await httpPut(uri, file, getOptions);
        return response.data;
    } catch (error) {
        return handleHttpError(error);
    }
}

export const postFileAlt = async (accessToken, file) => {
    let getOptions = buildDefaultOptions(accessToken);
    const uri = `${URL}/v2/Files`;

    try {
        let response = await httpPost(uri, file, getOptions);
        return response;
    } catch (error) {
        return handleHttpError(error);
    }
}

export const getUserManual = async (accessToken) => {
    let getOptions = buildDefaultOptions(accessToken);
    const uri = `${URL}/v2/Files/userManual`;
    getOptions.responseType = "blob";
    
    try {
        let response = await httpGet(uri, getOptions);
        return response;
    } catch (error) {
        return handleHttpError(error);
    }
}