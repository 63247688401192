import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showError, showSuccess, showWarning } from "../../../utils/utils";
import { loadState } from "../../../localStorage";
import { REGISTER_VALIDATION_URI, LOGIN_URI } from "../../../routing/constants";
import {
  createAccount,
  resendValidationToken,
  sendValidationToken,
} from "../../../services/authService";
import { history } from "../../../_helpers/history";

const initialState = loadState()?.register ?? {
  passwordHash: "",
  userId: "",
  isFetching: false,
  hasError: false,
};

export const tryCreateAccount = createAsyncThunk(
  "register/tryCreateAccount",
  async (userData, thunkAPI) => {
    try {
      const user = {
        password: userData?.pass,
        firstLogin: true,
        id: 0,
        name: `${userData?.name} | ${userData?.lastName}`,
        alias: userData?.name,
        description: "",
        email: userData?.email,
        valid: true,
      };
      const response = await createAccount(user);
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message)
        return thunkAPI.rejectWithValue({
            error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError("Ocurrió un problema al crear el usuario...",
            response?.response?.response?.data?.error?.message);
        return thunkAPI.rejectWithValue(
          response?.response?.data?.error?.message
        );
      } else {
        showSuccess("Éxito al crear usuario",
          "El usuario ha sido creado.");
      }
      return response;
    } catch (error) {
      console.error("error: ", error);
    }
  }
);

export const validateAccount = createAsyncThunk(
  "register/validateAccount",
  async (data, thunkAPI) => {
    try {
      const response = await sendValidationToken(data?.token, data?.hash);
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message)
        return thunkAPI.rejectWithValue({
            error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError("No se ha podido verificar su usuario...",
            response?.error?.message);
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      switch (response?.data?.result) {
        case 0:
          showError("El usuario ingresado no existe","");
          return thunkAPI.rejectWithValue(response?.data?.result);
        case 1:
          showWarning("El usuario ingresado ya está validado","");
          return thunkAPI.rejectWithValue(response?.data?.result);
        case 2:
          showWarning("El nombre de usuario ya existe","");
          return thunkAPI.rejectWithValue(response?.data?.result);
        case 3:
          showSuccess("Su cuenta se ha creado correctamente.",
              "Puede iniciar sesión.");
          return response;
        case 4:
          showError("Ocurrio un problema en la validación","");
          return thunkAPI.rejectWithValue(response?.data?.result);
        case 5:
          showError("El código ha expirado","");
          return thunkAPI.rejectWithValue(response?.data?.result);
        case 6:
          showError("El código ingresado no es válido","Por favor, inténtelo nuevamente.");
          return thunkAPI.rejectWithValue(response?.data?.result);
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }
);

export const tryResendValidationToken = createAsyncThunk(
  "register/tryResendValidationToken",
  async (data, thunkAPI) => {
    try {
      const response = await resendValidationToken(data);
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message)
        return thunkAPI.rejectWithValue({
            error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError("No se ha podido verificar su usuario...", response?.error?.message);
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      showSuccess("Se ha enviado otro código de activación a su correo","");
      return response;
    } catch (error) {
      console.error(error);
    }
  }
);

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    clearHash: (state, action) => {
      state.passwordHash = "";
    },
  },
  extraReducers: (builder) => {
    // REGISTER ACCOUNT
    builder.addCase(tryCreateAccount.fulfilled, (state, action) => {
      state.passwordHash = action.payload.passwordHash;
      state.userId = action.payload.id;
      state.isFetching = false;
      state.hasError = false;
      history.navigate(REGISTER_VALIDATION_URI);
    });
    builder.addCase(tryCreateAccount.pending, (state, action) => {
      state.isFetching = true;
      state.hasError = false;
    });
    builder.addCase(tryCreateAccount.rejected, (state, action) => {
      state.isFetching = false;
      state.hasError = true;
    });

    // VALIDATE ACCOUNT
    builder.addCase(validateAccount.fulfilled, (state, action) => {
      state.isFetching = false;
      state.hasError = false;
      history.navigate(LOGIN_URI);
    });
    builder.addCase(validateAccount.pending, (state, action) => {
      state.isFetching = true;
      state.hasError = false;
    });
    builder.addCase(validateAccount.rejected, (state, action) => {
      state.isFetching = false;
      state.hasError = true;
    });

    // TRY RESEND ACTIVATION TOKEN
    builder.addCase(tryResendValidationToken.fulfilled, (state, action) => {
      state.isFetching = false;
      state.hasError = false;
      state.passwordHash = action?.payload?.passwordHash;
    });
    builder.addCase(tryResendValidationToken.pending, (state, action) => {
      state.isFetching = true;
      state.hasError = false;
    });
    builder.addCase(tryResendValidationToken.rejected, (state, action) => {
      state.isFetching = false;
      state.hasError = true;
    });
  },
});

export const { clearHash } = registerSlice.actions;

export default registerSlice.reducer;
