import React, { useState, useRef, Suspense } from "react";
import { Card, Chip, SvgIcon, Tooltip, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { capitalizeTitle, getStatusDescriptionByCode, maskCUIT } from "../utils/utils";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SimpleModal from "./modals/simpleModal";

const ProvidersTable = ({ data, rows, clickFunction, actionFunction }) => {
  
  const [searchTerm, setSearchTerm] = useState("");
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [procedureId, setProcedureId] = useState("");
  const [providerName, setProviderName] = useState("su Proveedor");

  const rowsPerPage = rows;

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleShowModal = (provider) => {
    setProviderName(provider?.name)
    setProcedureId(provider?.id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setProcedureId("");
    setShowModal(false);
  };

  const handleSubmit = () => {
    actionFunction(procedureId);
    handleCloseModal();
  }

  const filteredData =
    Array.isArray(data) && data.length > 0
      ? data.filter(
          (item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.cuit.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : [];

  const tableRef = useRef(null);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  return (
    <Suspense fallback={<p>Cargando...</p>}>
      <Card
        ref={tableRef}
        className={`card-table ${true ? "fade-in" : "fade-out"}`}
        sx={{
          margin: "50px 0 50px 0",
          position: "relative",
          transition: "max-height 0.5s ease-in-out, opacity 0.5s ease-in-out",
          overflow: "hidden",
          display: data?.length === 0 ? "none" : "auto",
        }}
      >
        <div className="table-searchBar">
          <div>
            Se muestran <Chip label={filteredData.length} /> entradas
          </div>
          <div className="table-searchInputContainer">
            <p className="table-searchIcon">
              <SearchIcon />
            </p>
            <input
              className="table-searchInput"
              type="text"
              placeholder="Buscar..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="table-header">
          {/* <div className="table-colVigencia">Fecha</div> */}
          <div className="table-colCuit">CUIT</div>
          <div className="table-colRazonSocial">
            Apellido Nombre / Razón Social
          </div>
          <div className="table-colTipo">Tipo de trámite</div>
          <div className="table-colEstado">Estado</div>
          <div className="table-colActions">Acciones</div>
        </div>
        {filteredData && filteredData.length > 0 ? (
          paginatedData.map((item, index) => (
            <div
              key={item.id}
              className={`table-row ${item.isHidden ? "table-hidden" : ""}`}
              onMouseEnter={() => setHoveredRowIndex(index)}
              onMouseLeave={() => setHoveredRowIndex(null)}
              style={{
                cursor: hoveredRowIndex === index ? "pointer" : "default",
              }}
              onClick={() => clickFunction(item)}
            >
              {/* <div className="table-colVigencia">
                {formatDate(item.endDate) || "-"}
              </div> */}
              <div className="table-colCuit">{maskCUIT(item.cuit)}</div>
              <div className="table-colRazonSocial">{item.name}</div>
              <div className="table-colTipo">{capitalizeTitle(item.type)}</div>
              <div className="table-colEstado">
                <Chip
                  label={item.status}
                  sx={{
                    color: getStatusDescriptionByCode(item.status, "fontColor"),
                    backgroundColor: getStatusDescriptionByCode(
                      item.status,
                      "backgroundColor"
                    ),
                    fontWeight: "500",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                />
              </div>
              <div className="table-colActions">
                {item.status === "A Validar" ||
                item.status === "Borrador" ||
                item.status === "Observado" ? (
                  <Tooltip
                    variant="custom"
                    placement="top"
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    arrow
                    title={
                      <Typography fontSize="1.2rem">
                        {item.status === "A Validar" ? "Reenviar correo de validación" : "Cargar requisitos"}
                      </Typography>
                    }
                  >
                    <SvgIcon
                      component={item.status === "A Validar" ? MailOutlineIcon : FileUploadOutlinedIcon}
                      sx={{
                        fontSize: "2.4rem",
                        "&:active": {
                          color: "#e12150",
                        },
                      }}
                      onClick={() => handleShowModal(item)}
                    />
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))
        ) : (
          <div className={`table-row table-hidden`}>
            {/* <div className="table-colVigencia">-</div> */}
            <div className="table-colCuit">-</div>
            <div className="table-colRazonSocial">-</div>
            <div className="table-colTipo">-</div>
            <div className="table-colEstado">-</div>
            <div className="table-colActions">-</div>
          </div>
        )}
        <div className="table-pagination">
          <Chip
            label="Anterior"
            variant="paginationNav"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          />
          {Array.from(
            { length: Math.ceil(filteredData.length / rowsPerPage) },
            (_, i) => (
              <Chip
                variant="paginationPage"
                key={i}
                onClick={() => setCurrentPage(i + 1)}
                label={i + 1}
                sx={{
                  backgroundColor: currentPage === i + 1 && "#106699",
                  color: currentPage === i + 1 && "#FFFFFF",
                }}
              />
            )
          )}
          <Chip
            label="Siguiente"
            variant="paginationNav"
            onClick={handleNextPage}
            disabled={currentPage * rowsPerPage >= filteredData.length}
          />
        </div>
      </Card>{" "}
      <SimpleModal
        title="Confirmar reenvío de correo"
        description={`¿Está seguro de que desea reenviar el correo electrónico de validación de requisitos a ${providerName}? Esta acción no se puede deshacer.`}
        acceptText="Sí, reenviar"
        cancelText="No, cancelar"
        onSubmit={handleSubmit}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        loading={false}
      />
    </Suspense>
  );
};

export default ProvidersTable;
