import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showError, showInfo, showSuccess, showWarning } from "../../../utils/utils";
import { loadState } from "../../../localStorage";
import { REGISTER_VALIDATION_URI } from "../../../routing/constants";
import { authenticate, googleLogin } from "../../../services/authService";
import { history } from "../../../_helpers/history";

const initialState = loadState()?.login ?? {
  username: "",
  password: "",
  urlAvatar: "",
  isError: false,
  isLogged: false,
  isFetching: false,
  authentication: null,
  hash: "",
  isValidated: "",
};

//   function createInitialState() {
//     return {
//         // initialize state from local storage to enable user to stay logged in
//         user: JSON.parse(localStorage.getItem('user')),
//         error: null
//     }
// }

export const fetchLogin = createAsyncThunk(
  "user/loginStatus",
  async (state, thunkAPI) => {
    try {
      const response = await authenticate(state.username, state.password);
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          "No se ha podido iniciar sesión...",
          response?.error?.response?.data?.error?.message
        );
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("valid") && !response?.valid) {
        history.navigate(REGISTER_VALIDATION_URI);
        showInfo(
          "Su cuenta todavía no ha sido validada.",
          "Por favor ingrese el código que ha sido enviado a su email"
        );
        return response;
      }
      return response;
    } catch (err) {}
  }
);

export const tryLoginWithGoogle = createAsyncThunk(
  "user/googleLogin",
  async (credential, thunkAPI) => {
    try {
      const response = await googleLogin(credential);
      if (response?.hasOwnProperty("error") && response?.error != null) {
        showWarning(
          "No se ha podido iniciar sesión...",
          response?.error?.response.data?.error?.message,
          60000
        );
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    tryLogin: (state, action) => {
      state.username = action.payload.username;
      state.password = action.payload.password;
    },
    logout: (state, action) => {
      state.username = "";
      state.password = "";
      state.urlAvatar = "";
      state.isError = false;
      state.isLogged = false;
      state.isFetching = false;
      state.authentication = null;
      state.hash = "";
      state.isValidated = "";
      localStorage.removeItem("LocalStorage");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLogin.fulfilled, (state, action) => {
      const imgId = Math.floor(Math.random() * 5);
      state.urlAvatar = `https://picsum.photos/id/${imgId}/90/90`;
      
      //used for navigate to homepage avoiding to go back to the loginpage
      if (action?.payload?.valid != null && !action?.payload?.valid) {
        state.hash = action?.payload?.hash;
        state.isValidated = action?.payload?.valid;
      } else {
        
        state.authentication = action.payload;
        state.isLogged = true;
        state.hash = "";
        state.isValidated = "";
        history.navigate("/", { replace: true });
      }
      showSuccess("Bienvenido", state?.authentication?.alias);
      state.isFetching = false;
      state.isError = false;
    });
    builder.addCase(fetchLogin.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(fetchLogin.rejected, (state, action) => {
      state.isError = true;
      state.isFetching = false;
    });

    // GOOGLE AUTHENTICATION
    builder.addCase(tryLoginWithGoogle.fulfilled, (state, action) => {
      const imgId = Math.floor(Math.random() * 5);
      state.urlAvatar = `https://picsum.photos/id/${imgId}/90/90`;
      //used for navigate to homepage avoiding to go back to the loginpage
      if (action?.payload?.valid != null && !action?.payload?.valid) {
        state.hash = action?.payload?.hash;
        state.isValidated = action?.payload?.valid;
      } else {
        state.authentication = action.payload;
        state.isLogged = true;
        state.hash = "";
        state.isValidated = "";
        history.navigate("/", { replace: true });
      }
      showSuccess("Bienvenido", state?.authentication?.alias);
      state.isFetching = false;
      state.isError = false;
    });
    builder.addCase(tryLoginWithGoogle.pending, (state, action) => {
      state.isFetching = true;
      state.isError = false;
    });
    builder.addCase(tryLoginWithGoogle.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = false;
    });
  },
});

export const { tryLogin, logout } = loginSlice.actions;

export default loginSlice.reducer;
