import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  Tooltip,
  TableHead,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import TablePaginationActions from "../../common/tablePagination";
import { tryDeleteRepresentative } from "./representativesSlice";
import { maskCUIT } from "../../../utils/utils";
function createData(
  province,
  locality,
  inscriptionNumber,
  expirationDate,
  actions
) {
  return { province, locality, inscriptionNumber, expirationDate, actions };
}

const RepresentativesTable = (props) => {
  const dispatch = useDispatch();
  const representativesList = useSelector(
    (state) => state?.representatives?.representativesList
  );

  const rows =
    representativesList?.length > 0
      ? representativesList
      : [createData("-", "-", "-")];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (row) => {
    dispatch(tryDeleteRepresentative(row));
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",
        backgroundColor: "rgba(255,255,255,0.3)",
        borderRadius: "1rem",
      }}
    >
      <Table sx={{ minWidth: "40rem" }}>
        <TableHead>
          <TableRow sx={{ textAlign: "center" }}>
            <TableCell>
              <b>CUIT</b>
            </TableCell>
            <TableCell>
              <b>APELLIDO Y NOMBRES</b>
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <b>ACCIONES</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ textAlign: "center" }}>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row, index) => (
            <TableRow key={index} size="small">
              <TableCell
                size="small"
                sx={{ "&:hover": { cursor: "pointer" } }}
              >
                {maskCUIT(row?.cuit)}
              </TableCell>
              <TableCell
                size="small"
                sx={{ "&:hover": { cursor: "pointer" } }}
              >
                {row?.nombre}
              </TableCell>
              <TableCell sx={{ textAlign: "center" }} size="small">
                {row?.id && (
                  <>
                    <Tooltip
                      title={
                        <Typography fontSize="1.2rem">
                          Eliminar Apoderado
                        </Typography>
                      }
                    >
                      <IconButton
                        aria-label="delete picture"
                        component="label"
                        onClick={() => handleDelete(row)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "Todo", value: -1 }]}
              colSpan={4}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "Elementos por página",
                },
                native: true,
              }}
              labelRowsPerPage={"Cantidad de filas"}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              sx={{
                ".MuiTablePagination-toolbar": {
                  fontSize: "1.4rem",
                },
                ".MuiTablePagination-displayedRows": {
                  fontSize: "1.4rem",
                },
                ".MuiTablePagination-selectLabel": {
                  fontSize: "1.4rem",
                },
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default RepresentativesTable;
