import "./styles.css";
import Layout from "./routing/layout";
import { useNavigate, useLocation } from "react-router-dom";
import { history } from "./_helpers/history";
import routes from "./routing/routes.js";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "./styles/custom.scss";
import MaintenancePage from "./components/pages/MaintenancePage";
import { useHealthCheck } from "@webscopeio/react-health-check";
import { env } from "./env";
import { useEffect } from "react";

function App() {
  const { available, refresh } = useHealthCheck({
    service: {
      name: 'auth',
      url: env.API__BACKEND + '/hc',
    },
    refreshInterval: 30000,
  });

  history.navigate = useNavigate();
  history.location = useLocation();
  let isMaintenance = env.MAINTENANCE;

  refresh();

  useEffect(() => {
    // Función para borrar las cookies
    const clearCookies = () => {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
      }
    };

    const clearCache = () => {
      if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }
    };
    clearCookies();
    clearCache();
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      {available && !isMaintenance ? (
        <Layout>{routes}</Layout>
      ) : (
        <Layout>
          <MaintenancePage />
        </Layout>
      )}
    </div>
  );
}

export default App;
