import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  showError,
  showSuccess,
  showWarning,
  showInfo,
} from "../../../utils/utils";
import {
  postMunicipalAuthorization,
  getMunicipalAuthorizationsByProcedureId,
  putMunicipalAuthorization,
  deleteMunicipalAuthorization,
} from "../../../services/municipalAuthorizationsService";
import { postFileAlt } from "../../../services/storageService";
import { loadState } from "../../../localStorage";

const initialState = loadState()?.municipalAuthorization ?? {
  authorizationsList: [],
  selectedAuthorization: {},
  province: "",
  locality: "",
  inscriptionNumber: "",
  expirationDate: "",
  procedureId: "",
  type: "",
  canFinish: "",
  isFetching: "",
  hasError: "",
  isModification: false,
};

export const tryPostMunicipalAuthorization = createAsyncThunk(
  "municipalAuthorization/postAuthorization",
  async (data, thunkAPI) => {
    try{
      const token = thunkAPI.getState().login?.authentication?.access_token;
      let response = {};
      const procedureId =
        thunkAPI.getState().municipalAuthorization?.procedureId;
      const authorizationsList =
      thunkAPI.getState().municipalAuthorization?.authorizationsList;
      
      const exists = authorizationsList?.find(
        (obj) =>
          obj?.incriptionNumber === data?.inscriptionNumber &&
          obj?.locality?.id === data?.localityId &&
          obj?.province?.id === data?.provinceId &&
          obj?.expirationDate.split("T")[0] === data?.expirationDate
      );
      if (!exists) {
        response = await postMunicipalAuthorization(token, data);
        if (response.hasOwnProperty("error") && response?.error != null) {
          showWarning(
            "No se ha cargado la habilitación",
            "Recuerde completarla para finalizar el trámite.",
            ""
          );
          return thunkAPI.rejectWithValue(response?.error?.message);
        }
        if (response?.status === 418 || response?.status === 500) {
          showError("Error del servidor.", response?.error?.message);
          return thunkAPI.rejectWithValue({
            error: response?.error?.message,
          });
        }
      } else {
        showWarning(
          "Habilitación Municipal existente.",
          "Los campos no pueden ser exactamente iguales."
        );
        return thunkAPI.rejectWithValue({
          error: "El número de inscripción ya existe",
        });
      }
      showSuccess("Se ha cargado correctamente la habilitación municipal", "");
      thunkAPI.dispatch(
        tryGetMunicipalAuthorizationsByProcedureId(procedureId)
      );
      thunkAPI.dispatch(clearMunicipalAuthorizations());
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryModifyMunicipalAuthorization = createAsyncThunk(
  "municipalAuthorization/modifyAuthorization",
  async (data, thunkAPI) => {
    try {
      data = {
        ...data,
        id: thunkAPI.getState().municipalAuthorization?.selectedAuthorization
          ?.id,
      };
      const procedureId =
        thunkAPI.getState().municipalAuthorization?.procedureId;
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await putMunicipalAuthorization(token, data);
      if (response.hasOwnProperty("error") && response?.error != null) {
        showWarning(
          "No se ha cargado la habilitación",
          "Recuerde completarla para finalizar el trámite.",
          ""
        );
        return thunkAPI.rejectWithValues(response?.error?.message);
      }
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      showInfo("Se han actualizado los datos de habilitación municipal", "");
      thunkAPI.dispatch(
        tryGetMunicipalAuthorizationsByProcedureId(procedureId)
      );
      thunkAPI.dispatch(clearMunicipalAuthorizations());
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryGetMunicipalAuthorizationsByProcedureId = createAsyncThunk(
  "municipalAuthorization/getAuthorizations",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await getMunicipalAuthorizationsByProcedureId(token, id);
      if (response.hasOwnProperty("error") && response?.error != null) {
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      if (response?.data?.data[0]) {
        thunkAPI.dispatch(setCanFinish(true));
      } else {
        thunkAPI.dispatch(setCanFinish(false));
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const postMunicipalAuthorizationFile = createAsyncThunk(
  "municipalAuthorization/postFile",
  async (file, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await postFileAlt(token, file);
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError("Error al subir el archivo...", response?.error?.message);
        return thunkAPI.rejectWithValue({
          id: +file.get("id"),
          error: response?.error?.message,
        });
      }
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      showSuccess("Se ha subido el archivo con éxito.", "");
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryDeleteMunicipalAuthorization = createAsyncThunk(
  "municipalAuthorization/deleteAuthorization",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await deleteMunicipalAuthorization(token, data?.id);
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          "No se pudo eliminar la Habilitación Municipal.",
          response?.error?.message
        );
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      showSuccess("Habilitación Municipal eliminada", "");
      thunkAPI.dispatch(
        tryGetMunicipalAuthorizationsByProcedureId(data?.procedureId)
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const municipalAuthorizationSlice = createSlice({
  name: "municipalAuthorization",
  initialState,
  reducers: {
    setSelectedAuthorization: (state, action) => {
      state.selectedAuthorization = action.payload;
    },
    persistProcedureId: (state, action) => {
      state.procedureId = action.payload;
    },
    setTypeOfProcedureSelected: (state, action) => {
      state.type = action.payload;
    },
    clearMunicipalAuthorizations: (state, action) => {
      state.fileId = "";
      state.selectedAuthorization = {
        incriptionNumber: "",
        province: { id: 0, name: "" },
        locality: { id: 0, name: "" },
        expirationDate: "",
      };
    },
    setModificationBoolean: (state, action) => {
      state.isModification = action.payload;
    },
    setCanFinish: (state, action) => {
      state.canFinish = action.payload;
    },
  },
  extraReducers: (builder) => {
    // TRY POST MUNICIPAL AUTHORIZATION
    builder.addCase(
      tryPostMunicipalAuthorization.fulfilled,
      (state, action) => {
        state.canFinish = true;
        state.hasError = false;
        state.isFetching = false;
      }
    );
    builder.addCase(tryPostMunicipalAuthorization.pending, (state, action) => {
      state.canFinish = false;
      state.hasError = false;
      state.isFetching = true;
    });
    builder.addCase(tryPostMunicipalAuthorization.rejected, (state, action) => {
      state.canFinish = false;
      state.hasError = true;
      state.isFetching = false;
    });

    // POST FILE ALT V2
    builder.addCase(
      postMunicipalAuthorizationFile.fulfilled,
      (state, action) => {
        state.hasError = false;
        state.isFetching = false;
      }
    );
    builder.addCase(postMunicipalAuthorizationFile.pending, (state, action) => {
      state.hasError = false;
      state.isFetching = true;
    });
    builder.addCase(
      postMunicipalAuthorizationFile.rejected,
      (state, action) => {
        state.hasError = true;
        state.isFetching = false;
      }
    );

    // GET MUNICIPAL AUTHORIZATION BY PROCEDURE ID
    builder.addCase(
      tryGetMunicipalAuthorizationsByProcedureId.fulfilled,
      (state, action) => {
        state.authorizationsList = action.payload?.data;
        state.hasError = false;
        state.isFetching = false;
      }
    );
    builder.addCase(
      tryGetMunicipalAuthorizationsByProcedureId.pending,
      (state, action) => {
        state.hasError = false;
        state.isFetching = true;
      }
    );
    builder.addCase(
      tryGetMunicipalAuthorizationsByProcedureId.rejected,
      (state, action) => {
        state.hasError = true;
        state.isFetching = false;
      }
    );

    // MODIFY MUNICIPAL AUTHORIZATION
    builder.addCase(
      tryModifyMunicipalAuthorization.fulfilled,
      (state, action) => {
        state.isModification = false;
        state.hasError = false;
        state.isFetching = false;
      }
    );
    builder.addCase(
      tryModifyMunicipalAuthorization.pending,
      (state, action) => {
        state.hasError = false;
        state.isFetching = true;
      }
    );
    builder.addCase(
      tryModifyMunicipalAuthorization.rejected,
      (state, action) => {
        state.hasError = true;
        state.isFetching = false;
      }
    );
  },
});

export const {
  persistProcedureId,
  setTypeOfProcedureSelected,
  clearMunicipalAuthorizations,
  setSelectedAuthorization,
  setModificationBoolean,
  setCanFinish,
} = municipalAuthorizationSlice.actions;

export default municipalAuthorizationSlice.reducer;
