import numeral from "numeral";
import moment from "moment";
import { isNumeric } from "./validationService";

import appSettings from "../appSettings";

export const currencyFormatter = (value) => numeral(value).format("$0,0[.]00a");

export const mapToHttpBody = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export const parseDate = (argDate) =>
  moment(argDate, appSettings.appSettings.DATE_FORMAT);

export const parseDateField = (date) =>
  moment(date).format(appSettings.appSettings.DATE_FORMAT);

export const addWeekdays = (date, days) => {
  date = moment(date);
  while (days > 1) {
    date = date.add(1, "days");
    if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
      days -= 1;
    }
  }
  return date.format(appSettings.appSettings.DATE_FORMAT);
};

export const formatDate = (argDate) => {
  const date = new Date(argDate);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses en JavaScript son 0-indexados
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export const toStringDate = (date) =>
  date.format(appSettings.appSettings.DATE_FORMAT);

export const inputformatDate = (date) => {
  let dateString = date.split("T");
  return dateString[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1");
};

export const getformatDate = (date) => {
  return date.replace(/^(\d{2})\/(\d{2})\/(\d{4})$/g, "$3-$2-$1");
};

const formatParse = (value, format) => {
  let numberValid = isNumeric(value) ? true : false;

  if (!numberValid) return value;

  if (format) {
    if (format.includes("%"))
      return percentFormatter(parseFloat(value), format);
    if (format.includes("p1"))
      return percentFormatter(parseFloat(value), "0.0%");
  }

  return numeral(value).format();
};

const percentFormatter = (value, format) => numeral(value).format(format);

export { percentFormatter, formatParse };
