import {
  Typography,
  Grid,
  Button
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../_helpers/history";
import AppLoading from "../common/appLoading";
import moment from "moment";
import DownloadIcon from '@mui/icons-material/Download';
import { sendPdfData } from "../../features/newTransact/newTransactSlice";
import WarningIcon from "./../../assets/icons/warningIcon.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  getProcedureRequirements,
  restartRequirementState,
} from "./requirementsPage/requirementsSlice";
import { MUNICIPAL_AUTHORIZATION_URI } from "../../routing/constants";
import { tryGetMunicipalAuthorizationsByProcedureId, persistProcedureId, clearMunicipalAuthorizations, setTypeOfProcedureSelected } from "./municipalAuthorizationPage/municipalAuthorizationSlice";
import { clearRepresentativesState } from "./representativesPage/representativesSlice";
import { clearMembers } from "./membersUploadPage/membersUploadSlice";
import { maskCUIT } from "../../utils/utils";

export default function NewTransactStepFive(props) {
  const dispatch = useDispatch();
  const [fetchingConstancy, setFetchingConstancy] = useState(false);
  const finalizeTransactState = useSelector((state) => state?.newTransact?.isError);

  const constancyURL = useSelector((state) => state?.newTransact?.constancyURL);

  const selectedTransact = useSelector((state) => state?.newTransact?.selectedProcedureId);

  const selectedUser = useSelector(
    (state) => state?.newTransact?.selectedPerson
  );
  const isFetching = useSelector((state) => state?.newTransact?.isFetching);
  
  const idProcedure = useSelector((state) => state?.newTransact?.procedureId);
  
  let currentDate = moment(new Date()).format("DD/MM/YYYY, HH:mm:ss");
    
  useEffect(() => {
    dispatch(clearRepresentativesState());
    dispatch(clearMembers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  const handleCancel = () => {
    history.navigate("/");
  };

  const handleUploadRequirements = () => {
    handleOpenRequirements(idProcedure);
  };

  const handleOpenRequirements = (idProcedure) => {
    dispatch(clearMunicipalAuthorizations());
    dispatch(persistProcedureId(idProcedure));
    dispatch(setTypeOfProcedureSelected(selectedTransactLabel(selectedTransact)));
    dispatch(tryGetMunicipalAuthorizationsByProcedureId(idProcedure));
    dispatch(getProcedureRequirements(idProcedure));
    dispatch(restartRequirementState());
    history.navigate(MUNICIPAL_AUTHORIZATION_URI);
  };

  const handleDownload = () => {
    setFetchingConstancy(true)
    dispatch(sendPdfData(constancyURL)).then(() => {
      setFetchingConstancy(false)
    })
  };

  const selectedTransactLabel = (id) => {
    switch (id) {
      case 1:
        return "Inscripción";
      case 2:
        return "Actualización";
      case 3:
        return "Reinscripción";
      case 4:
        return "Ampliación de Rubro";
      default:
        break;
    }
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      variant="custom"
    >
      {!isFetching ? (
        <>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            variant="custom"
          >
              <Grid item xs={12} sx={{ width: "60vw", justifyContent: "center",}}>
                  {
                    finalizeTransactState
                    ?
                    (
                      <Grid spacing={2}>
                        <Grid item xs={12} md={12} sx={{textAlign:"center", mt:"5rem"}}>
                          <img 
                            src={WarningIcon}
                            alt="icon"
                            width="5%"
                            height="5%"
                          />
                        </Grid>
                          <Grid item xs={12} md={12} sx={{textAlign:"center", mt:"2rem"}}>
                            <Typography variant="titleGreen" sx={{color:"#CF1C1C"}}>
                                  Su trámite no ha sido enviado
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={12} sx={{textAlign:"center"}}>
                            <Typography variant="title2" sx={{justifyContent:"center"}}>
                                Intente nuevamente más tarde.
                            </Typography>
                          </Grid>
                        <br></br>
                        <br></br> 
                         <Grid item xs={10} md={10}>
                          <Button variant="blueBtn" 
                          startIcon={<ArrowBackIcon/>}
                          onClick={handleCancel}
                          sx={{ width: "20rem", position: {md: "fixed"}, left: {md: "18vw"}, bottom: {md: "5vh"}, backgroundColor:"#1A3762"}}
                          >
                              Ir a Inicio
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={2}
                      direction="column"
                      variant="custom" >
                        <Grid item xs={12} md={12} sx={{textAlign:"left", margin:"1rem", display: "flex", flexDirection: "column"}}>
                          <Typography variant="title">
                            ¡Trámite iniciado con éxito!
                          </Typography>
                          <Typography variant="title2">
                            Puede proceder a completar los requisitos.
                          </Typography>
                        </Grid>
                        
                        <Grid item sx={{textAlign:"left"}}>
                        <Grid item xs={8} md={6}
                          container                      
                          variant="lightBlueGrid" 
                          spacing={1.3}
                          sx={{
                              padding:"2rem",
                              fontSize:"1.3rem", mt:"2rem", ml:"18vh",
                              margin:"1rem"
                          }}                          
                          >
                              <Grid item xs={10} md={10} container sx={{padding:"1rem", }}>
                                  <Typography variant="title2">
                                    {selectedTransactLabel(selectedTransact)}
                                  </Typography>
                              </Grid>
                              <Grid item xs={10} md={10} container sx={{padding:"1rem"}}>
                                  CUIT: {maskCUIT(selectedUser.cuit)}
                              </Grid>
                              <Grid item xs={10} md={10} container sx={{padding:"1rem"}}>
                                  Razón Social: {selectedUser.name}
                              </Grid>
                              <Grid item xs={10} md={10} container sx={{padding:"1rem"}}>
                                  Fecha de inicio: {currentDate}
                              </Grid>
                              <Grid item xs={10} md={10} container>
                                  <Button variant="blueBtn" 
                                    startIcon={<DownloadIcon/>}
                                    onClick={handleDownload}
                                    sx={{
                                        width: "fit-content",
                                        backgroundColor:"#0869AB"
                                      }}
                                    >
                                        Descargar constancia
                                  </Button>
                              </Grid>
                          </Grid>
                        </Grid>
                            <Grid item xs={8} md={6} sx={{minHeight:"12vh"}}>
                              <Button
                                  onClick={() => handleCancel()}
                                  variant="bold"
                                  sx={{
                                    width: "20rem",
                                    position: { md: "fixed" },
                                    left: { md: "18vw" },
                                    bottom: { md: "5vh" },
                                  }}
                                >
                                Volver al inicio
                              </Button>
                              <Button
                                onClick={() => handleUploadRequirements()}
                                variant="success"
                                sx={{
                                  width: "25rem",
                                  position: { md: "fixed" },
                                  right: { md: "15vw" },
                                  bottom: { md: "5vh" },
                                }}
                              >
                                Cargar requisitos
                              </Button>
                            </Grid>
                      </Grid>
                    )
                  }
              </Grid>
          </Grid>
        </>
      ) : (
        <>
          <div mb="5rem"></div>
          <AppLoading size={5}/>
          <Typography variant="textParagraph" justifyContent="center" fontSize="1.6rem" width="60%" sx={{ color:"#1A3762" }}>
            {fetchingConstancy ? "Su constancia está siendo descargada" : "Su trámite está siendo enviado"}
          </Typography><br/>
          <Typography variant="textParagraph" justifyContent="center" fontSize="1.6rem" width="60%" sx={{ color:"#1A3762" }}>
            Aguarde por favor
          </Typography>
          <div mb="5rem"></div>
        </>
      )}
    </Grid>
  );
}
