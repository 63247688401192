import * as React from "react";
import {
  Grid,
  CardActions,
  CardContent,
  Button,
  Typography,
  Card,
  Avatar,
} from "@mui/material";

export default function MediaCard(props) {
  const Icon = props.icon;
  const { handleButton, disabled = false } = props;

  return (
    <div>
      <Grid
        sx={{
          paddingTop: "1rem",
          maxWidth: "38rem",
          width: "100%",
          minWidth: "10dvw",
          height: "0px",
          textAlign: "-webkit-center",
        }}
      >
        <Avatar
          src={Icon}
          sx={{
            width: "8rem",
            height: "8rem",
            p: "1.6rem",
            border: "0.2rem solid #1A3762",
            backgroundColor: "#1A3762",
            top: "0rem",
            transform: "translateY(-50%)",
            "&:hover": { cursor: "pointer" },
          }}
          onClick={handleButton}
        />
      </Grid>
      <Card
        variant="actionCard"
        sx={{
          minHeight: "30dvh",
          maxWidth: "38rem",
          width:"100%",
          marginBottom: "3rem",
          maxHeight: "40rem",
          padding: "4rem 0 0 0",
          backgroundColor: "rgba(255,255,255,0.7)",
        }}
      >
        <CardContent
          sx={{ height: "19rem", padding: "2rem", textAlign: "center" }}
        >
          <Typography
            gutterBottom
            variant="actionCardTitle"
            component="div"
            justifyContent="center"
          >
            {props.title}
          </Typography>
          <br></br>
          <Typography
            variant="actionCardDescription"
            justifyContent="center"
            height="35rem"
          >
            {props.description}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            justifyContent: "center",
            alignContent: "center",
            marginBottom: "2rem",
            position: "relative",
          }}
        >
          <Button
            onClick={handleButton}
            size="medium"
            variant="blueBtn"
            disabled={disabled}
            sx={{ width: "13rem" }}
          >
            {props.buttonLabel}
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
