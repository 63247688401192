import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import * as yup from "yup";
import { cuitIsValid, maskedInputStyle } from "../../../utils/utils";
import { history } from "../../../_helpers/history";
import { useDispatch, useSelector } from "react-redux";
import AppLoading from "../../common/appLoading";
import SearchIcon from "@mui/icons-material/Search";
import RepresentativesTable from "./representativesTable";
import {
  clearPersonName,
  getPersonName,
} from "../../../features/associateCuit/associateCuitSlice";
import {
  MUNICIPAL_AUTHORIZATION_URI,
  REQUIREMENTS_URI,
} from "../../../routing/constants";
import { tryPostRepresentative } from "./representativesSlice";
import {
  disabledPrimaryColor,
  lightPrimaryColor,
  primaryColor,
} from "../themes";

const RepresentativesPage = () => {
  const isFetchingCuit = useSelector(
    (state) => state?.associateCuit?.isFetching
  );
  const isFetching = isFetchingCuit;

  const dispatch = useDispatch();
  const [cuit, setCuit] = useState("");
  const [cuitValid, setCuitValid] = useState(false);
  const procedureId = useSelector(
    (state) => state?.municipalAuthorization?.procedureId
  );
  const name = useSelector((state) => state?.associateCuit?.personName);

  const validationSchema = yup.object({
    representativeName: yup
      .string()
      .required("Debe ingresar nombre y apellido"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      representativeName: name?.name,
      representativeCUIT: cuit,
    },
    onSubmit: (values) => {
      let data = {
        procedureId: procedureId,
        cuit: name?.cuit,
        nombre: values?.representativeName,
        apellido: "-",
      };
      dispatch(tryPostRepresentative(data));
      dispatch(clearPersonName());
      cleanFields();
    },
    validationSchema: validationSchema,
  });

  const handleChangeCuit = (event) => {
    let unmaskedCuit = event.target.value.replace("-", "");
    let validateCuit = cuitIsValid(event.target.value);
    setCuit(unmaskedCuit.replace("-", ""));
    setCuitValid(validateCuit);
  };

  const handleValidateCuit = (cuit) => {
    dispatch(getPersonName(cuit));
  };

  const handleContinue = () => {
    history.navigate(REQUIREMENTS_URI);
  };

  const cleanFields = () => {
    dispatch(clearPersonName());
    setCuit("");
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={formik.handleSubmit}
      enableReinitialize
    >
      <Grid
        container
        direction="column"
        sx={{ width: { xs: "90vw", md: "60vw" } }}
      >
        <Grid
          item
          xs={12}
          sx={{ ml: { xs: "0rem", md: "2rem", lg: "5rem" }, mt: "2rem" }}
        >
          <Typography variant="title">Apoderados</Typography>
        </Grid>
        <Grid
          container
          direction="row"
          sx={{ placeContent: "center", mt: "2rem" }}
          spacing={2}
        >
          <Grid item sx={{ mt: 0, width: "45rem", mb: "" }}>
            <Grid item sx={{ mb: "1rem" }}>
              <Typography
                variant="textDescription"
                sx={{ color: "rgba(0,0,0,1)" }}
              >
                Ingrese el CUIT de su apoderado y presione
                buscar:
              </Typography>
            </Grid>
            <Grid item sx={{ display: "flex" }}>
              <InputMask
                mask="99-99999999-9"
                maskChar=""
                onChange={handleChangeCuit}
                type="number"
                value={cuit}
              >
                {() => <TextField label="CUIT" inputProps={maskedInputStyle} />}
              </InputMask>
              <button
                type="button"
                name="search-representatives-button"
                disabled={!cuitValid}
                aria-label="search"
                sx={{
                  backgroundColor: lightPrimaryColor,
                  width: "4rem",
                  height: "4rem",
                  mt: "1rem",
                  "&:hover": {
                    background: primaryColor,
                    color: "white",
                  },
                  "&:disabled": {
                    background: disabledPrimaryColor,
                  },
                }}
                onClick={() => handleValidateCuit(cuit)}
                class="searchButton"
              >
                {isFetchingCuit ? (
                  <AppLoading size={2.5} />
                ) : (
                  <SearchIcon
                    fontSize="inherit"
                    sx={{
                      fontSize: "3rem",
                      color: cuitValid ? "white" : "grey",
                    }}
                  />
                )}
              </button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          spacing={2}
          sx={{ placeContent: "center", mt: "0rem" }}
        >
          <Grid item sx={{ mt: 0, width: "45rem", pr: "1rem" }}>
            <TextField
              required
              disabled={true}
              id="representativeName"
              name="representativeName"
              type="text"
              placeholder="Apellido y Nombres"
              label="Apellido y Nombres"
              value={name?.name}
              error={
                formik.touched.representativeName &&
                Boolean(formik.errors.representativeName)
              }
              helperText={
                formik.touched.representativeName &&
                formik.errors.representativeName
              }
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
        </Grid>
        {Object.keys(name).length > 0 &&
          name?.kindOfPerson === "JURIDICA" && (
            <Grid
              item
              xs={12}
              md={12}
              sx={{ marginTop: "3rem", textAlign: "-webkit-center" }}
            >
              <Alert
                severity="warning"
                sx={{
                  width: { xs: "90%", md: "70%" },
                  alignItems: "center",
                  textAlign: "start",
                }}
              >
                <Typography variant="title3" className="text-message">
                  Las personas jurídicas no son admisibles como apoderados.
                </Typography>
              </Alert>
            </Grid>
          )}
        {isFetching ? (
          <AppLoading size={5} />
        ) : (
          <Grid
            container
            direction="row"
            sx={{
              width: "60%",
              textAlign: "center",
              my: "3rem",
              ml: "20%",
              mr: "20%",
            }}
          >
            <Grid item xs={12} md={6} align="center">
              <Button
                variant="lightBlueBtn"
                disabled={formik.values?.representativeName ? false : true}
                hidden={false}
                sx={{
                  width: "12rem",
                  boxShadow: "0rem 0.4rem 0.4rem rgba(0, 0, 0, 0.25)",
                  mb: { xs: "1rem", md: 0 },
                }}
                onClick={cleanFields}
              >
                Limpiar
              </Button>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <Button
                variant="blueBtn"
                disabled={
                  formik.values?.representativeName && name?.kindOfPerson === "FISICA"
                    ? false
                    : true
                }
                sx={{
                  width: "12rem",
                  backgroundColor: "#0869AB",
                  boxShadow: "0rem 0.4rem 0.4rem rgba(0, 0, 0, 0.25)",
                }}
                type="submit"
              >
                Añadir
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          sx={{
            width: "90%",
            textAlign: "center",
            mb: "1rem",
            mr: "5%",
            ml: "5%",
          }}
        >
          <RepresentativesTable />
        </Grid>
        <Grid
          container
          direction="row"
          sx={{ width: "100%", textAlign: "center", mt: "2rem", mb: "2rem" }}
        >
          <Grid item xs={12} md={6} align="center">
            <Button
              variant="bold"
              onClick={() => {
                history.navigate(MUNICIPAL_AUTHORIZATION_URI);
              }}
              sx={{
                align: "center",
                width: "20rem",
                mb: "2rem",
                mt: "2rem",
              }}
            >
              Volver
            </Button>
          </Grid>
          <Grid item xs={12} md={6} align="center">
            <Button
              disabled={false}
              variant="success"
              sx={{ width: "20rem", mb: "2rem", mt: "2rem" }}
              onClick={handleContinue}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RepresentativesPage;
