import {
  Typography,
  Grid,
  Button,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Card,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { finalizeNewTransact } from "../../features/newTransact/newTransactSlice";
import {
  MEMBERS_UPLOAD_URI,
  NEW_TRANSACT_STEP_THREE,
} from "../../routing/constants";
import { history } from "../../_helpers/history";
import AppLoading from "../common/appLoading";
import SimpleModal from "../modals/simpleModal";

function createData(code, description) {
  const activity = `${code} - ${description}`;
  return activity;
}

export default function NewTransactStepFour(props) {
  const [showModal, setShowModal] = useState(false);
  
  const dispatch = useDispatch();
  const economicActivities = useSelector(
    (state) => state?.newTransact?.selectedPerson?.economicActivities
  );
  const userEmail = useSelector((state) => state?.login?.authentication?.email);

  const rows = economicActivities.map((item) =>
    createData(item?.code, item?.description)
  );
  const selectedUser = useSelector(
    (state) => state?.newTransact?.selectedPerson
  );
  const isFetching = useSelector((state) => state?.newTransact?.isFetching);
  const membersState = useSelector(
    (state) => state?.membersUpload?.membersList
  );
  const membersList = membersState?.map((item) => ({
    cuit: item?.cuit,
    name: item?.name,
    memberTypeId: item?.memberType?.id,
    Address: {
      localityId: item?.domiciles?.localityId?.id,
      provinceId: item?.domiciles?.provinceId?.id,
      description: item?.domiciles?.address,
    }
  }));

  const selectedPerson = useSelector(
    (state) => state?.newTransact?.selectedPerson
  );

  const selectedProcedureId = useSelector(
    (state) => state?.newTransact?.selectedProcedureId
  );

  const userData = {
    personCUIT: selectedUser?.cuit,
    observation: "Iniciado en el sistema web.",
    procedureType: {
      procedureTypeId: useSelector(
        (state) => state?.newTransact?.selectedProcedureId
      ),
    },
    provider: {
      email: selectedUser?.emails?.direction || userEmail,
      phone: "",
      fantasyName: selectedUser?.name,
      person: {
        cuit: selectedUser?.cuit,
      },
      LegalMisionesAddress: {
        description: selectedUser?.legalMisionesAddress?.description,
        localityId: selectedUser?.legalMisionesAddress?.locality?.id,
      },
      RealLegalAddress: {
        description: selectedUser?.realLegalAddress?.description,
        localityId: selectedUser?.realLegalAddress?.locality?.id,
      },
    },
  };

  useEffect(() => {
    if (selectedUser?.kindOfPerson === "JURIDICA") {
      userData.members = membersList;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const handleCancel = () => {
    history.navigate(
      selectedProcedureId === 4
        ? NEW_TRANSACT_STEP_THREE
        : selectedPerson?.kindOfPerson === "JURIDICA"
        ? MEMBERS_UPLOAD_URI
        : NEW_TRANSACT_STEP_THREE
    );
  };

  const handleContinue = () => {
    dispatch(finalizeNewTransact(userData));
    history.navigate("/new-transact-step-five");
  };

  const handleShowModal = (provider) => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = () => {
    handleCloseModal();
    handleContinue();
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      variant="custom"
    >
      <SimpleModal
        title="Confirmar envío de trámite"
        description="Va a enviar un trámite ¿está seguro?"
        acceptText="Sí, finalizar"
        cancelText="No, cancelar"
        onSubmit={handleSubmit}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        loading={false}
      />
      {!isFetching ? (
        <>
          <Grid
            sx={{
              justifyContent: "center",
              textAlign: "center",
              padding: "1rem",
            }}
            container
            spacing={0}
          >
            <TableContainer>
              <Table align="center" sx={{ width: "60%" }}>
                <TableHead>
                  <div style={{ padding: "2rem" }}>
                    <Typography
                      variant="title"
                      sx={{ fontSize: "2.4rem", fontWeight: 400 }}
                    >
                      Actividades Económicas
                    </Typography>
                  </div>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          fontSize: "1.2rem",
                          fontWeight: 400,
                          color: "#1A3762",
                          backgroundColor: "#F3EDED",
                          borderRadius: "1rem",
                        }}
                      >
                        {row}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {economicActivities?.length === 0 && (
              <Card
                variant="infoCard"
                sx={{
                  mt: "2rem",
                  padding: "3rem",
                  mb: "2rem",
                  borderRadius: "1rem",
                  fontSize: "1.4rem",
                }}
              >
                Esta persona no posee actividades económicas.
              </Card>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ textAlign: "center", justifyContent: "center" }}
          >
            <br></br>
            <br></br>
          </Grid>
          <Grid
            container
            spacing={2}
            marginTop={5}
            marginBottom={5}
            sx={{
              textAlign: "center",
              align: "center",
              justifyContent: "-webkit-center",
              placeContent: { xs: "center" },
            }}
          >
            <Grid item xs={8} md={10}>
              <Button
                onClick={handleCancel}
                variant="bold"
                sx={{
                  width: "20rem",
                  position: { md: "fixed" },
                  left: { md: "18vw" },
                  bottom: { md: "5vh" },
                }}
              >
                Volver
              </Button>
            </Grid>
            <Grid item xs={8} md={10}>
              <Button
                onClick={handleShowModal}
                variant="success"
                disabled={economicActivities?.length === 0 ? true : false}
                sx={{
                  width: "20rem",
                  position: { md: "fixed" },
                  right: { md: "18vw" },
                  bottom: { md: "5vh" },
                }}
              >
                Finalizar
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Typography variant="titleGreen">Iniciando Trámite</Typography>
          <AppLoading size={5} />
          <Typography
            variant="textParagraph"
            justifyContent="center"
            fontSize="1.6rem"
            width="60%"
            sx={{ color: "#1A3762" }}
          >
            Su trámite está siendo enviado
          </Typography>
          <br />
          <Typography
            variant="textParagraph"
            justifyContent="center"
            fontSize="1.6rem"
            width="60%"
            sx={{ color: "#1A3762" }}
          >
            Aguarde por favor
          </Typography>
          <div mb="5rem"></div>
        </>
      )}
    </Grid>
  );
}
