import {
  Typography,
  Grid,
  Button,
  TextField,
  Container,
  Box,
  Alert,
} from "@mui/material";
import InputMask from "react-input-mask";
import React, { useEffect, useState } from "react";
import ConfirmEmailModal from "../modals/confirmEmailModal";
import { useDispatch, useSelector } from "react-redux";
import { tryShowModal } from "../modals/modalSlice";
import {
  fetchAfipCuit,
  SendTokenAssociateCuit,
} from "../../features/associateCuit/associateCuitSlice";
import AppLoading from "../common/appLoading";
import { fetchManagedPersons } from "../../features/newTransact/newTransactSlice";
import { capitalizeTitle, cuitIsValid, maskCUIT } from "../../utils/utils";
import { showInfo } from "../../utils/utils";
import { env } from "../../env";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import DetailsCard from "../common/detailsCard";
import ErrorIcon from "@mui/icons-material/Error";
import { history } from "../../_helpers/history";
import { ASSOCIATE_DETAILS_URI } from "../../routing/constants";

function createData(name, data) {
  return { name, data };
}

export default function AssociatePage(props) {
  const stateModal = useSelector((state) => state.modal.showModal);
  const dispatch = useDispatch();
  const [validate, setValidate] = useState(false);
  const [cuit, setCuit] = useState("");
  const [cuitValid, setCuitValid] = useState(false);
  const isFetchingManagedPersons = useSelector(
    (state) => state?.newTransact?.isFetching
  );
  const isFetchingAfipInformation = useSelector(
    (state) => state?.associateCuit?.isFetching
  );
  const personData = useSelector((state) => state?.associateCuit?.person);
  const isValid = personData?.valid;
  const users = useSelector((state) => state?.newTransact?.persons);
  const isError = useSelector((state) => state?.newTransact?.isError);
  const hasError = useSelector((state) => state?.associateCuit?.hasError);
  const errorMessage = personData?.error?.replace(/\n/g, " ");

  const maskedInputStyle = {
    style: {
      borderColor: "rgba(0,0,0,0)",
      fontFamily: "Open sans",
      fontSize: "1.6rem",
      fontWeight: "bolder",
      textAlign: "center",
      color: "#1A3762",
      marginLeft: "0.5rem",
      marginRight: "0.5rem",
      letterSpacing: "0.5rem",
    },
  };

  useEffect(() => {
    dispatch(fetchManagedPersons());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rows = [
    createData("CUIT", maskCUIT(personData?.cuit)),
    createData(
      "Apellido y Nombre / Razón Social",
      personData?.name && capitalizeTitle(personData?.name)
    ),
    createData(
      "Tipo de Persona",
      personData?.kindOfPerson && capitalizeTitle(personData?.kindOfPerson)
    ),
  ];

  const handleValidate = () => {
    setValidate(true);
    dispatch(fetchAfipCuit({ cuit: cuit, isAssociate: true }));
  };

  const handleAdminPerson = () => {
    const associatedCuit = isAssociatedCuit();
    if (associatedCuit === false) {
      dispatch(SendTokenAssociateCuit(cuit));
      dispatch(tryShowModal());
    }
  };

  const isAssociatedCuit = () => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].cuit === cuit) {
        showInfo("Este CUIT ya se encuentra asociado a su cuenta.", "");
        return true;
      }
    }
    return false;
  };

  const handleChangeCuit = (event) => {
    let unmaskedCuit = event.target.value.replace("-", "");
    let validateCuit = cuitIsValid(event.target.value);
    setCuit(unmaskedCuit.replace("-", ""));
    setCuitValid(validateCuit);
  };

  const handleViewAssociateDetails = (cuit) => { 
    history.navigate(`${ASSOCIATE_DETAILS_URI}/${cuit}`);
  }

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justify="center"
      variant="custom"
      sx={{mb:"5rem"}}
    >
      <ConfirmEmailModal open={stateModal} cuit={cuit} />
      <Grid
        sx={{
          width: { xs: "80vw", md: "70vw", lg: "60vw" },
          justifyContent: "left",
          textAlign: "left",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              ml: { xs: "0rem", md: "2rem", lg: "5rem" },
              mt: "2rem",
              textAlign: "-webkit-center",
            }}
          >
            {env.AFIP__STATUS.hasError === true && (
              <Alert
                severity={env.AFIP__STATUS.type}
                sx={{
                  width: { xs: "100%", md: "70%" },
                  alignItems: "center",
                  mb: "3rem",
                }}
              >
                <Typography
                  variant="title3"
                  sx={{ mb: "1rem", fontWeight: "600", fontSize: "2rem" }}
                >
                  {env.AFIP__STATUS.title}
                </Typography>
                <br />
                <Typography variant="textParagraph" sx={{ mb: "1rem" }}>
                  {env.AFIP__STATUS.description}
                </Typography>
              </Alert>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="title">Asociar Persona</Typography>
            <br />
            <Typography variant="textParagraph">
              Ingrese el CUIT de la persona (humana o jurídica) para poder
              administrar los trámites.
            </Typography>
          </Grid>
        </Grid>
        <br></br>
        <br></br>
        <Grid container sx={{ placeContent: "start" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            sx={{
              textAlign: { xs: "center", md: "left", lg: "left" },
            }}
          >
            <InputMask
              mask="99-99999999-9"
              maskChar=""
              onChange={handleChangeCuit}
              type="number"
            >
              {() => <TextField label="CUIT" inputProps={maskedInputStyle} />}
            </InputMask>
            <Grid
              item
              sx={{
                textAlign: "start",
                width: { md: "max-content" },
                mt: "1rem",
              }}
            >
              <Typography variant="textParagraph">
                * Recuerde que se emplea el servicio WEB de AFIP para validar el
                CUIT.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            sx={{
              textAlign: {
                xs: "-webkit-center",
                md: "-webkit-center",
                lg: "center",
              },
            }}
          >
            <Button
              disabled={!cuitValid}
              variant="success"
              onClick={handleValidate}
              sx={{
                display: "flex",
                marginLeft: { md: "5rem" },
                marginTop: { md: "0.4rem" },
              }}
            >
              Validar
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={0} sx={{ placeContent: "start", mt:"10rem" }}>
          {!hasError && validate && !isFetchingAfipInformation ? (
            <DetailsCard
              title="Detalle de la persona"
              buttonText="Asociar Persona"
              clickFunction={() => handleAdminPerson()}
              disabled={!isValid}
              close={() => {setValidate(false)}}
            >
              <Grid
                container
                sx={{
                  justifyContent: "left",
                  textAlign: "left",
                }}
              >
                {rows.map((row) => (
                  <div style={{ width: "100%" }}>
                    <Typography variant="textParagraph" sx={{ color: "#000" }}>
                      <b>{row.name}:</b> {row.data}
                    </Typography>
                  </div>
                ))}
                <Grid item xs={12} md={12} sx={{ marginTop: "3rem" }}>
                  {errorMessage && (
                    <Alert
                      iconMapping={{
                        success: (
                          <ErrorIcon
                            fontSize="inherit"
                            sx={{ color: "#A61414" }}
                          />
                        ),
                      }}
                      sx={{
                        width: "fit-content",
                        alignItems: "center",
                        backgroundColor: "#F7C7C7",
                      }}
                    >
                      <Typography
                        variant="textParagraph"
                        className="text-message"
                        sx={{
                          color: "#000",
                          fontSize: "1.5rem",
                          fontWeight: "400",
                        }}
                      >
                        {errorMessage}
                      </Typography>
                    </Alert>
                  )}
                </Grid>
              </Grid>
            </DetailsCard>
          ) : (
            isFetchingAfipInformation && (
              <Container align="center" sx={{ mt: "5vh" }}>
                <AppLoading size={5} />
              </Container>
            )
          )}
        </Grid>
      </Grid>
      {isError ? (
        <Grid item>
          <Alert severity="info" sx={{ alignItems: "center" }}>
            <Typography variant="title3" xs={12} md={12} sx={{}}>
              No se pudo cargar la lista de personas asociadas.
            </Typography>
          </Alert>
        </Grid>
      ) : !validate && ((
          <Grid
            item
            sx={{
              width: { xs: "90vw", md: "70vw", lg: "60vw" },
            }}
          >
            <Grid item xs={11}>
              <Typography
                variant="title"
                sx={{ fontSize: "2.4rem", fontWeight: 400 }}
              >
                Personas Asociadas
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                justifyContent: "start",
                textAlign: "center",
                margin: "5rem 0 10rem 0",
                width: { xs: "90vw", sm: "80vw", md: "60vw", lg: "55vw" },
              }}
            >
              {users.length > 0 && !isFetchingManagedPersons ? (
                users.map((item, index) => (
                  <Box key={index}>
                    <Button
                      key={index}
                      variant={ "lightBlueBtn" }
                      sx={{
                        width: "100%",
                        minHeight: "4rem",
                        borderRadius: "0px",
                        backgroundColor: "rgba(255,255,255,0.8)",
                        "&:hover": {
                          backgroundColor: "#F7FBFE",
                          color: "#000",
                        },
                        padding: "1.6rem 0 1.6rem 0",
                        color: "#000",
                        fontWeight: "regular",
                      }}
                      onClick={()=> handleViewAssociateDetails(item?.cuit)}
                    >
                      <div style={{ width: "25%" }}>{maskCUIT(item?.cuit)}</div>
                      <div
                        style={{
                          width: "50%",
                          textAlign: "start",
                          padding: "0 0 0 50px",
                        }}
                      >
                        {item?.name}
                      </div>
                      <div
                        style={{
                          width: "25%",
                          textAlign: "center",
                          padding: "0 0 0 50px",
                        }}
                        onClick={()=> handleViewAssociateDetails(item?.cuit)}
                      >
                        <ZoomInIcon sx={{ fontSize: "20px" }} />
                      </div>
                    </Button>
                  </Box>
                ))
              ) : isFetchingManagedPersons ? (
                <AppLoading size={5} />
              ) : (
                <Container align="center" sx={{ pt: "5rem" }}>
                  <Typography
                    variant="title"
                    sx={{ fontSize: "1.6rem", fontWeight: 400 }}
                  >
                    No posee personas asociadas.
                  </Typography>
                </Container>
              )}
            </Grid>
          </Grid>
        )
      )}
    </Grid>
  );
}
