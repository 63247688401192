import { createSlice } from "@reduxjs/toolkit";

const initialState = 
  {
    showModal: false,
  };


const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    tryShowModal: (state, action) => {
        state.showModal = true;
    },
    tryCloseModal: (state, action) => {
        state.showModal = false;
    },
  }
});

export const { tryCloseModal, tryShowModal } = modalSlice.actions;

export default modalSlice.reducer;
