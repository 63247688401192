
import { createSlice } from "@reduxjs/toolkit";

const initialState = 
  {
    showModal: false,
  };


const modalChildSlice = createSlice({
  name: "modalChild",
  initialState,
  reducers: {
    tryShowChildModal: (state, action) => {
        state.showModal = true;
    },
    tryCloseChildModal: (state, action) => {
        state.showModal = false;
    },
  }
});

export const { tryCloseChildModal, tryShowChildModal } = modalChildSlice.actions;

export default modalChildSlice.reducer;
