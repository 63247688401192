import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../../../utils/utils";
import { history } from "../../../_helpers/history";
import { requestAccountDeletion } from "../../../services/userService";

const initialState = {
  isFetching: false,
  isError: false,
  successRequest: false,
  email: "",
};

export const tryRequestAccountDeletion = createAsyncThunk(
  "deleteAccount/tryRequestAccountDeletion",
  async (state, thunkAPI) => {
    try {
      const response = await requestAccountDeletion(state);
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.response?.data?.detail,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(response?.error?.response?.data?.detail);

        return thunkAPI.rejectWithValue(response?.data);
      }
      showSuccess(response?.data);
      return response;
    } catch (error) {
      console.error("error: ", error);
    }
  }
);

const deleteAccountSlice = createSlice({
  name: "deleteAccount",
  initialState,
  extraReducers: (builder) => {
    //POST send mail to request account deletion
    builder.addCase(tryRequestAccountDeletion.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isError = false;
      state.successRequest = true;
      setTimeout(() => history.navigate("/"), 30000);
    });
    builder.addCase(tryRequestAccountDeletion.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(tryRequestAccountDeletion.rejected, (state, action) => {
      state.successRequest = false;
      state.isError = true;
      state.isFetching = false;
    });
  },
});

export const { userData } = deleteAccountSlice.actions;

export default deleteAccountSlice.reducer;
