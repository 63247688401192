import React from "react";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function DetailsCard(props) {
  const {
    title,
    clickFunction,
    buttonText,
    buttonIcon,
    disabled,
    close,
    style,
    bodyStyle = {},
  } = props;
  return (
    <div className="details-card-container" style={style}>
      <div className="details-card-title">
        <span>{title}</span>
        <CloseIcon
          style={{ cursor: "pointer", position: "absolute", right: "3rem" }}
          onClick={close}
        />
      </div>
      <div className="details-card-body" style={bodyStyle}>{props.children}</div>
      <div className="details-card-footer">
        <Button
          variant="blueBtn"
          onClick={clickFunction}
          disabled={disabled}
          startIcon={buttonIcon}
          sx={{ width: "fit-content", fontSize: "1.6rem", padding: "0 3rem", height: "4rem"}}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
}
