import React, { useEffect, useState } from "react";
import { Card, Grid, Typography, Chip, Button, CircularProgress, Alert, FormControlLabel, Checkbox, FormGroup, Popover } from "@mui/material";
import banner from "../../../assets/images/logo_contaduria.png";
import Icons from "../../../assets/icons/Icons";
import { Image } from "react-bootstrap";
import RequirementsReviewModal from "./requirementsReviewModal";
import RequirementsTermsModal from "./requirementsTermsModal";
import { useDispatch, useSelector } from "react-redux";
import { tryCloseModal, tryShowModal } from "../../modals/modalSlice";
import {
  clearRequirementsState,
  tryFinishProcedure,
  tryGetRequirementstoValidateList,
  tryRejectProcedure,
  tryRequestChanges,
} from "./requirementsReviewSlice";
import {
  getStatusDescriptionByCode,
  maskCUIT,
  stringDateToDMY,
  stringDateToYMD,
} from "../../../utils/utils";
import { tryDownloadRequirementToValidate } from "../requirementsPage/requirementsSlice";
import AppLoading from "../../common/appLoading";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import { Scrollbars } from "react-custom-scrollbars-2";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import { primaryColor } from "../themes";

export default function RequirementsReviewPage(props) {
  const { checkCode } = props;
  const [option, setOption] = useState(1);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isDownloading, setIsDownloading] = useState({});
  const [isScrolled, setIsScrolled] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const procedure = useSelector(
    (state) => state.requirementsReview?.procedureData
  );
  const isToValidate = procedure?.procedureState?.name === "A Validar";
  const isFetching = useSelector(
    (state) => state.requirementsReview?.isFetching
  );
  const isAmpliacionDeRubro = procedure?.procedureType?.name === "Ampliación de Rubro" ? 1 : 0;
  const isCorrected = useSelector((state) =>
    state.requirementsReview?.requirementsToValidate[0]?.requirementState?.id === 3 ? true : false);
  const requirements = useSelector((state) =>
    state.requirementsReview?.requirementsToValidate.slice(
      (isAmpliacionDeRubro || isCorrected) ? 0 : 1
    )
  );
  const constancy = useSelector((state) => state.requirementsReview?.requirementsToValidate[0])
  const startDate = stringDateToDMY(
    procedure?.procedureState?.date?.split("T")[0], "/"
  );
  const regex = /^\d{2}\/\d{2}\/\d{4}$/;
  const dateYMD = regex.test(startDate)
    ? startDate.replace(/\//g, "-")
    : stringDateToYMD(startDate, "_");

  const dispatch = useDispatch();

  const handleShowModal = (opc) => {
    setOption(opc);
    dispatch(tryShowModal());
  };
  
  useEffect(() => {
    dispatch(clearRequirementsState());
    dispatch(tryCloseModal());
    dispatch(tryGetRequirementstoValidateList(checkCode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parseDate = (dateString, separator) => {
    const [day, month, year] = dateString.split(separator);
    return new Date(`${year}-${month}-${day}`);
  };

  const startDateObj = parseDate(startDate, "/");

  const isExpired = (() => {
    const currentDate = new Date();
    const date30DaysLater = new Date(startDateObj);
    date30DaysLater.setDate(date30DaysLater.getDate() + 30);
    return currentDate >= date30DaysLater;
  })();


  const handleFinish = (opc) => {
    if (opc === 1) {
      dispatch(tryFinishProcedure(checkCode));
      setButtonDisabled(true);
    } else if (opc === 0) {
      dispatch(tryRejectProcedure(checkCode));
      setButtonDisabled(true);
    } else if (opc === 2) {
      dispatch(tryRequestChanges(checkCode));
      setButtonDisabled(true);
    }
  };

  const handleDownload = (id) => {
    const requirementData = { id, checkCode }
    setIsDownloading({ ...isDownloading, [id]: true });
    dispatch(tryDownloadRequirementToValidate(requirementData)).then(() => {
      setIsDownloading({ ...isDownloading, [id]: false });
    });
  };

  const handleScroll = (values) => {
    const { top, scrollTop, scrollHeight, clientHeight } = values;
    setIsScrolled(top > 0.1);
    setIsAtBottom(scrollTop + clientHeight >= scrollHeight - 1);
  };

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      ...style,
      paddingRight: "2rem",
      flexGrow: 1,
    };
    return <div style={viewStyle} {...props} />;
  };

  const handleAcceptTerms = () => {
    setTermsAccepted(true);
  }

  const handleShowTermsModal = () => {
    setShowTermsModal(true);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Grid
      container
      direction="column"
      sx={{
        height: "100dvh",
        display: "flex",
        p: { xs: "1rem", md: "1rem 5rem", lg: "1rem 10rem" },
      }}
    >
      <Grid
        item
        xs="auto"
        style={{
          height: isScrolled || isAtBottom ? "0px" : "50px",

          transition: "height 0.5s ease",
        }}
      >
        <Grid
          sx={{
            width: "100%",
            "&:hover": { cursor: "pointer" },
            marginBottom: "0rem",
            height: isScrolled || isAtBottom ? "0px" : "50px",
            overflow: "hidden",
            transition: "height 0.5s ease",
            display: "flex",
            justifyContent:"center"
          }}
        >
          <Image
            src={banner}
            alt="logo"
            width="70"
            sx={{
              flexGrow: 1,
            }}
          />
          <Image
            src={Icons.misionesIconAlt}
            alt="logo"
            width="35"
            sx={{
              flexGrow: 1,
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs
        style={{ flexGrow: "revert", display: isFetching ? "none" : "block" }}
      >
        <Grid
          container
          direction="column"
          sx={{
            transition: "all 0.3s ease",
            padding: "0.5rem",
          }}
        >
          <Typography
            variant="title"
            sx={{
              fontFamily: "Open Sans",
              fontSize: {
                xs: "1.4rem",
                md: isScrolled || isAtBottom ? "1.6rem" : "2rem",
                transition: "all 0.3s ease",
              },
              fontWeight:"600"
            }}
          >
            Trámite de {procedure?.procedureType?.name} iniciado en {startDate}
          </Typography>
          <Typography
            variant="title2"
            sx={{
              fontFamily: "Open Sans",
              fontSize: {
                xs: "1.4rem",
                md: isScrolled || isAtBottom ? "1.4rem" : "1.6rem",
                transition: "all 0.3s ease",
              },
            }}
          >
            {maskCUIT(procedure?.provider?.person?.cuit)} /{" "}
            {procedure?.provider?.person?.name}
          </Typography>
          <Grid
            item
            direction="row"
            display="flex"
            sx={{
              mt:
                isScrolled || isAtBottom
                  ? "0rem"
                  : { xs: "1rem", md: "1.5rem", lg: "2rem" },
              transition: "all 0.3s ease",
            }}
          >
            <Typography
              variant="textParagraph"
              sx={{
                fontSize: {
                  xs: "1.2rem",
                  md: isScrolled || isAtBottom ? "1.4rem" : "2rem",
                  transition: "all 0.3s ease",
                },
              }}
            >
              Estado del trámite:
            </Typography>
            {procedure?.procedureState?.name && (
              <Chip
                label={procedure?.procedureState?.name}
                sx={{
                  color: getStatusDescriptionByCode(
                    procedure?.procedureState?.name,
                    "fontColor"
                  ),
                  backgroundColor: getStatusDescriptionByCode(
                    procedure?.procedureState?.name,
                    "backgroundColor"
                  ),
                  fontWeight: "500",
                  fontSize:
                    isScrolled || isAtBottom
                      ? "1.2rem"
                      : { xs: "1.2rem", md: "1.6rem" },
                  ml: "1.5rem",
                  height:
                    isScrolled || isAtBottom
                      ? "2.4rem"
                      : { xs: "2.4rem", md: "3.2rem" },
                  transition: "all 0.3s ease",
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid item display={"none"}>
          <Alert
            iconMapping={{
              success: (
                <WarningIcon
                  className="errorIcon"
                  fontSize="inherit"
                  sx={{
                    color: primaryColor,
                    fontSize:
                      isScrolled || isAtBottom
                        ? "2rem"
                        : { xs: "2rem", md: "3rem" },
                  }}
                />
              ),
            }}
            sx={{
              width: { xs: "100%" },
              backgroundColor: "transparent",
              mb: !isCorrected || !isToValidate ? "2rem" : "1rem",
              border: `1px solid ${primaryColor}`,
            }}
          >
            <Typography
              variant="textParagraph"
              className="text-message"
              sx={{
                color: "#000",
                fontWeight: "400",
                fontSize:
                  isScrolled || isAtBottom
                    ? "1.2rem"
                    : { xs: "1.2rem", md: "1.4rem" },
              }}
            >
              Trámite vencido: No se puede validar este trámite porque ha
              superado los 30 días desde su creación.
            </Typography>
          </Alert>
        </Grid>
        <Grid item display={(!isCorrected || !isToValidate) && "none"}>
          <Alert
            iconMapping={{
              success: (
                <ErrorIcon
                  className="errorIcon"
                  fontSize="inherit"
                  sx={{
                    color: "#A61414",
                    fontSize: isScrolled || isAtBottom ? "2rem" : "3rem",
                  }}
                />
              ),
            }}
            sx={{
              width: { xs: "100%" },
              backgroundColor: "#F7C7C7",
              mb: "2rem",
            }}
          >
            <Typography
              variant="textParagraph"
              className="text-message"
              sx={{
                color: "#000",
                fontWeight: "400",
                fontSize: isScrolled || isAtBottom ? "1.2rem" : "1.4rem",
              }}
            >
              Tenga en cuenta que únicamente se mostrarán los requisitos que han
              sido subsanados y están pendientes de validación.
            </Typography>
          </Alert>
        </Grid>
      </Grid>
      {isFetching ? (
        <AppLoading size={5} />
      ) : (
        <Grid
          item
          xs
          style={{
            flexGrow: 1,
            overflowY: "hidden",
          }}
        >
          <Grid sx={{ height: "100%", overflowY: "hidden" }}>
            <Scrollbars
              className="scroll-bars"
              style={{
                width: "100%",
                borderRadius: "1rem",
                transition: "height 0.3s ease",
              }}
              onScrollFrame={handleScroll}
              renderView={renderView}
            >
              <Card
                direction="column"
                variant="requirementCard"
                sx={{
                  display: isAmpliacionDeRubro || isCorrected ? "none" : "flex",
                  alignItems: "start",
                  mb: "2rem",
                }}
              >
                <Typography
                  variant="textParagraph"
                  sx={{
                    fontSize: "1.4rem",
                    fontWeight: "600",
                    textAlign: "start",
                  }}
                >
                  Formulario de Inscripción
                </Typography>
                <Card
                  sx={{
                    p: "1rem 4rem 1rem 0",
                    mt: "2rem",
                    backgroundColor: "transparent",
                    border: "1px solid #DDDDDD",
                    boxShadow: 0,
                    "&:hover": {
                      cursor: "pointer",
                      boxShadow: "2px 5px 5px #EEEEEE",
                    },
                  }}
                  onClick={() => handleDownload(constancy?.id)}
                >
                  <Grid container direction="row" alignItems={"center"}>
                    <Grid
                      item
                      sx={{
                        width: "8rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Image
                        src={Icons.pdfIcon}
                        alt="logo"
                        width="25"
                        height="25"
                        sx={{
                          flexGrow: 1,
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        textAlignLast: "start",
                      }}
                    >
                      <Typography
                        variant="textParagraph"
                        sx={{
                          fontSize: { xs: "1rem", md: "1.2rem" },
                          fontWeight: "500",
                          color: "black",
                        }}
                      >
                        {dateYMD}-requisito_{constancy?.id}
                        .pdf
                      </Typography>
                      <Typography
                        variant="textParagraph"
                        sx={{
                          fontSize: { xs: "1rem", md: "1.2rem" },
                          fontWeight: "500",
                          color: "#AAAAAA",
                        }}
                      >
                        Descargar
                      </Typography>
                    </Grid>
                    <Grid sx={{ ml: "3rem", width: "2rem" }}>
                      {isDownloading[constancy?.id] && (
                        <CircularProgress size={25} />
                      )}
                    </Grid>
                  </Grid>
                </Card>
              </Card>

              {requirements.map((item) => (
                <Card
                  direction="column"
                  variant="requirementCard"
                  sx={{ display: "flex", alignItems: "start", my: "0.5rem" }}
                >
                  <Typography
                    variant="textParagraph"
                    sx={{
                      fontSize: "1.2rem",
                      fontWeight: "600",
                      textAlign: "start",
                    }}
                  >
                    {item?.requirementType?.name}{" "}
                    {item?.municipalAuthorization?.localityName
                      ? `- ${item?.municipalAuthorization?.localityName}`
                      : item?.representative
                      ? `- ${item?.representative?.name}`
                      : item?.member
                      ? `- ${item?.member?.name}`
                      : ""}
                  </Typography>
                  <Card
                    sx={{
                      p: "1rem 4rem 1rem 0",
                      mt: "1rem",
                      backgroundColor: "transparent",
                      border: "1px solid #DDDDDD",
                      boxShadow: 0,
                      "&:hover": {
                        cursor:
                          item?.optional === true && item?.documentURL === ""
                            ? "default"
                            : "pointer",
                        boxShadow: "2px 5px 5px #DDDDDD",
                      },
                    }}
                    onClick={() => {
                      (item?.optional === true && item?.documentURL === "") ||
                        handleDownload(item?.id);
                    }}
                  >
                    <Grid container direction="row" alignItems={"center"}>
                      <Grid
                        item
                        sx={{
                          width: "8rem",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Image
                          src={Icons.pdfIcon}
                          alt="logo"
                          width="25"
                          height="25"
                          sx={{
                            flexGrow: 1,
                          }}
                        />
                      </Grid>
                      {item?.optional === true && item?.documentURL === "" ? (
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            textAlignLast: "start",
                          }}
                        >
                          <Typography
                            variant="textParagraph"
                            sx={{
                              fontSize: { xs: "1rem", md: "1.2rem" },
                              fontWeight: "500",
                              color: "black",
                            }}
                          >
                            No hay archivo adjunto
                          </Typography>
                          <Typography
                            variant="textParagraph"
                            sx={{
                              fontSize: { xs: "1rem", md: "1.2rem" },
                              fontWeight: "500",
                              color: "#AAAAAA",
                            }}
                          >
                            Requisito opcional
                          </Typography>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            textAlignLast: "start",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                            variant="textParagraph"
                            noWrap
                            sx={{
                              fontSize: { xs: "1rem", md: "1.2rem" },
                              fontWeight: "500",
                              color: "black",
                            }}
                          >
                            {dateYMD}-requisito_{item?.id}.pdf
                          </Typography>
                          <Typography
                            variant="textParagraph"
                            sx={{
                              fontSize: { xs: "1rem", md: "1.2rem" },
                              fontWeight: "500",
                              color: "#AAAAAA",
                            }}
                          >
                            Descargar
                          </Typography>
                        </Grid>
                      )}
                      <Grid sx={{ ml: "3rem", width: "2rem" }}>
                        {isDownloading[item.id] && (
                          <CircularProgress size={25} />
                        )}
                      </Grid>
                    </Grid>
                  </Card>
                </Card>
              ))}
            </Scrollbars>
            <div
              className="scrollbar-arrow"
              style={{
                background: isAtBottom ? "transparent" : primaryColor,
              }}
            >
              <ArrowDownwardRoundedIcon
                sx={{
                  color: isAtBottom ? "transparent" : "#FFF",
                  fontSize: "2.6rem",
                }}
              />
            </div>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        xs="auto"
        sx={{ minHeight: "200px", display: isFetching ? "none" : "block" }}
      >
        <Grid
          container
          direction="row"
          sx={{
            width: { xs: "100%", md: "80%" },
            display: { md: "flex" },
            alignSelf: "center",
            p: { xs: "1rem 0rem 1rem 0rem" },
          }}
        >
          <Grid
            item
            xs={12}
            align="center"
            sx={{
              display: isToValidate ? { md: "flex" } : "none",
              justifyContent: { md: "flex-start" },
              marginBlock: "1rem",
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    size="large"
                    checked={termsAccepted}
                    onChange={handleShowTermsModal}
                  />
                }
                label={
                  <div
                    style={{
                      textAlign: "start",
                      fontSize: "1.4rem",
                      fontWeight: "700",
                    }}
                  >
                    Requisitos Legales y Declaración Jurada
                  </div>
                }
              ></FormControlLabel>
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
            align="center"
            sx={{
              display: { md: "flex" },
              justifyContent: { md: "flex-start" },
            }}
          >
            <Button
              className="sticky-button"
              onClick={() => {
                handleShowModal(0);
              }}
              variant="bold"
              sx={{
                width: { xs: "100%", sm: "calc(20rem + 3%)" },
                mb: { xs: "1rem", lg: 0 },
                mr: { xs: 0, md: "0.5rem" },
                fontSize: "1.4rem",
              }}
              disabled={!isToValidate || buttonDisabled}
            >
              Rechazar
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
            align="center"
            sx={{
              display: { md: "flex" },
              justifyContent: { md: "flex-start" },
            }}
          >
            <Button
              className="sticky-button"
              onClick={() => {
                handleShowModal(1);
              }}
              variant="success"
              sx={{
                width: { xs: "100%", sm: "calc(20rem + 3%)" },
                mb: { xs: "1rem", md: 0 },
                ml: { xs: 0, md: "0.5rem" },
                fontSize: "1.4rem",
              }}
              disabled={!isToValidate || buttonDisabled || !termsAccepted}
            >
              Aceptar
            </Button>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 1 }}>
                Debe aceptar los términos para poder continuar.
              </Typography>
            </Popover>
          </Grid>
          <Grid
            item
            direction="column"
            xs={12}
            md={12}
            lg={12}
            align="center"
            sx={{
              display: { md: "flex" },
              justifyContent: { md: "flex-start" },
              borderTop: { md: "1px solid rgba(169, 169, 169, 1)" },
              mt: { md: "1.5rem" },
              pt: "1rem",
            }}
          >
            <Grid
              container
              direction="row"
              sx={{
                textAlign: "start",
                mb: "1rem",
              }}
            >
              <Grid
                item
                xs={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  src={Icons.infoIcon}
                  alt="logo"
                  width="32"
                  height="35"
                  sx={{
                    flexGrow: 1,
                  }}
                />
              </Grid>
              <Grid container direction="column" xs={11} sx={{ pl: "1rem" }}>
                <Typography
                  variant="textDescription"
                  sx={{ fontSize: "1.4rem", fontWeight: "700" }}
                >
                  ¿Considera que algún requisito debe ser modificado?
                </Typography>
                <Typography
                  variant="textDescription"
                  sx={{
                    fontSize: "1.4rem",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  Comuníquese con su gestor para que realice los cambios
                  pertinentes.
                </Typography>
              </Grid>
            </Grid>
            <Button
              className="sticky-button"
              onClick={() => {
                handleShowModal(2);
              }}
              variant="bold"
              sx={{
                width: { xs: "100%", sm: "calc(24rem)" },
                mr: { xs: 0, md: "2rem" },
                fontSize: "1.4rem",
              }}
              disabled={!isToValidate || buttonDisabled}
            >
              Solicitar modificaciones
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <RequirementsReviewModal
        open={true}
        optionSelected={option}
        handleFinish={() => handleFinish(option)}
      ></RequirementsReviewModal>
      <RequirementsTermsModal
        showModal={showTermsModal}
        onSubmit={() => {
          setTermsAccepted(true);
          setShowTermsModal(false);
        }}
        handleCloseModal={() => {
          setTermsAccepted(false);
          setShowTermsModal(false);
        }}
      ></RequirementsTermsModal>
    </Grid>
  );
}
