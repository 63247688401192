import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getLastestProcedures } from "../../services/userService";

const initialState = {
    transacts: [],
    isFetching: false,
    isError: false,
    selectedTransact: {},
    selectedTransactId: null
  };
  
export const fetchLastTransactsTable = createAsyncThunk(
    "lastTransactsTable",
    async (state, thunkAPI) => {
        try {
            const token = thunkAPI.getState().login?.authentication?.access_token;
            return await getLastestProcedures(token);           
        } catch (error) {
            console.log("error: ", error);            
        }
    }
);

const tableSlice = createSlice({
    name: "lastTransactsTable",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchLastTransactsTable.fulfilled, (state, action) => {
            state.transacts = action.payload;
            state.isError = false;
            state.isFetching = false;
        })
        builder.addCase(fetchLastTransactsTable.pending, (state, action) => {
            state.isFetching = true;
            state.isError = false;
        })
        builder.addCase(fetchLastTransactsTable.rejected, (state, action) => {
            state.isFetching = false;
            state.isError = false;
        })
    }
})

export default tableSlice.reducer;