import {
  Typography,
  Box,
  Button,
  Link,
  Input,
} from "@mui/material";
import React from "react";
import LoginStructure from "../../common/loginStructure";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { tryResendValidationToken, validateAccount } from "./registerSlice";
import { Container } from "@mui/system";
import { handleMaxLenght } from "../../../utils/utils";
import { Navigate } from "react-router-dom";
import { HOME } from "../../../routing/constants";

const RegisterValidationPage = (props) => {
  const dispatch = useDispatch();
  const isLogged = useSelector((x) => x?.login?.isLogged);
  const passHash = useSelector(state => state?.register?.passwordHash);
  const retryHash = useSelector(state => state?.login?.hash);

  const validationSchema = yup.object({
    token: yup
      .string()
      .required("Debe ingresar el código")
      .matches(/^(?=.{4,4})/, "El código debe ser de 4 dígitos"),
  });

  const formik = useFormik({
    initialValues: {
      token: "",
    },
    onSubmit: (values) => {
      const data = {
        token: values.token,
        hash: (passHash === "") ? retryHash : passHash,
      }
      dispatch(validateAccount(data));
    },
    validationSchema: validationSchema,
  });

  const handleResendToken = () => {
    const hash = (passHash === "") ? retryHash : passHash;
    dispatch(tryResendValidationToken(hash));
  }

  const inputStyleProps = {
    maxlenght: 4,
    style: {
    borderRadius: "10rem",
    borderColor:"rgba(0,0,0,0)",
    fontFamily: "Open sans",
    fontSize: "3rem",
    fontWeight: "bolder",
    textAlign: "center",
    color: "#1A3762",
    height: "4rem",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    letterSpacing: "2rem",
    },
  }

  if (isLogged) {
    console.info("Ya se encuentra con una sesión activa.");
    return <Navigate to={HOME} />;
  }

  return (
    <LoginStructure>
      <Box
        noValidate
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{ mb: "4rem" }}
      >
        <Typography variant="title">Habilitar cuenta</Typography>
        <br />
        <Container sx={{ width: "80%", textAlign: "left", mt: "1.5rem" }}>
          <Typography variant="textParagraph" sx={{ width: "60%", mt: "1.5rem" }}>
            Ingrese el código de verificación que fue enviado al correo
            electrónico con el que se acaba de registrar.
          </Typography>
        </Container>

        <Input
          id="token"
          name="token"
          type="number"
          inputProps={inputStyleProps}
          variant="codeInput"
          value={formik.values.token}
          onChange={formik.handleChange}
          error={formik.touched.token && Boolean(formik.errors.token)}
          sx={{
            width: "50%",
            mt: "4rem",
            boxShadow: "inset 0rem 0.2rem 0.6rem rgba(0, 0, 0, 0.2)",
              '&:before': {
                display: 'none',
              },
          }}
          onInput={(e) => {
            handleMaxLenght(e, 4);
          }}
        />
        <Typography
            variant="h6"
            textAlign="center"
            sx={{ fontSize: "1rem", color:"red" }}
          >
           {formik.touched.token && formik.errors.token}
          </Typography>
        <Container>
          <Button
            id="submitButton"
            type="submit"
            variant="success"
            sx={{ mt: 5, mb: 2, width: "60%" }}
          >
            <Typography
              variant="title2"
              textAlign="left"
              sx={{ fontWeight: 400, color: "white", fontSize: "1.6rem" }}
            >
              Validar
            </Typography>
          </Button>
        </Container>
        <Container>
          <Typography variant="textDescription">
            <Link variant="custom" onClick={()=>handleResendToken()} sx={{"&:hover": { cursor: "pointer" },}}>Reenviar código</Link>
          </Typography>
        </Container>
      </Box>
    </LoginStructure>
  );
};

export default RegisterValidationPage;
