import {
  Typography,
  Grid,
  Box,
  Button,
  Container,
  Link,
  TextField,
} from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import LoginStructure from "../../common/loginStructure";
import { postSendTokenRecoveryAccountAsync } from "./passwordRecoverySlice";
import { useDispatch, useSelector } from "react-redux";
import AppLoading from "../../common/appLoading";
import { LOGIN_URI } from "../../../routing/constants";

const ForgotPasswordPage = (props) => {
  const dispatch = useDispatch();
  const isFetching = useSelector(
    (state) => state?.passwordRecovery?.isFetching
  );

  const emailValidationSchema = yup.object({
    email: yup
      .string()
      .email("Ingrese un correo electrónico válido")
      .required("Debe ingresar un correo electrónico"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      dispatch(postSendTokenRecoveryAccountAsync(values));
    },
    validationSchema: emailValidationSchema,
  });

  return (
    <LoginStructure>
      <Typography variant="title">¿Olvidó su contraseña?</Typography>
      <br></br>
      <Container sx={{ width: "80%", textAlign: "left", mt: "1.5rem" }}>
        <Typography variant="textParagraph" sx={{ width: "60%", mt: "1.5rem" }}>
          Ingrese su correo electrónico registrado. Allí le enviaremos el código
          para recuperar su contraseña.
        </Typography>
      </Container>
      <Box
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
        sx={{ mt: 1, mb: 6 }}
      >
        <Grid textAlign="left" sx={{ width: "60%", mt: "3rem" }}>
          <TextField
            name="email"
            label="Correo electrónico"
            id="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            type="email"
            inputProps={{color:"red"}}
          />
        </Grid>
        <br />
        <br />
        <Button
          variant="success"
          type="submit"
          sx={{ mt: 1, mb: 2, width: "60%" }}
        >
          <Typography
            variant="title2"
            textAlign="left"
            sx={{ fontWeight: 400, color: "white", fontSize: "1.6rem" }}
          >
            {isFetching ? <AppLoading size={3} /> : "Recuperar Contraseña"}
          </Typography>
        </Button>
        <br />
        <Grid item>
          <Typography variant="textDescription">
            <Link href={LOGIN_URI} variant="custom" sx={{}}>
              {"Volver a inicio de sesión"}
            </Link>
          </Typography>
        </Grid>
      </Box>
    </LoginStructure>
  );
};

export default ForgotPasswordPage;
