import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAvailableTicketsTypes,
  getProviderConstancy,
} from "../../services/providersService";
import { downloadStreamFile } from "../../utils/downloadStreamFile";
import { showError, showInfo } from "../../utils/utils";
import { getProvinces, getLocalities } from "../../services/provincesService";
import { postProcedures } from "../../services/proceduresService";
import { loadState } from "../../localStorage";
import { history } from "../../_helpers/history";
import { getManagedPersons } from "../../services/personsService";
import { getAddresses } from "../../services/addressesService";
import { getEconomicActivities } from "../../services/economicActivitiesService";
import { getEmails } from "../../services/emailsService";
import { getLocalityInfo } from "../../services/localitiesService";
import { NEW_TRANSACT_STEP_THREE } from "../../routing/constants";
import { clearMembers } from "../../components/pages/membersUploadPage/membersUploadSlice";

const initialState = loadState()?.newTransact ?? {
  persons: [],
  isFetching: false,
  isError: false,
  selectedPerson: {},
  selectedProcedureId: null,
  provinces: [],
  localities: [],
  availableTransactTypes: [],
  misionesLocalities: [],
  realAddress: {},
  fiscalAddress: {},
  constancyURL: "",
  procedureId: "",
  kindOfPerson: "",
};

export const fetchManagedPersons = createAsyncThunk(
  "newTransact/managedPersons",
  async (state, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await getManagedPersons(token);
      if (response.hasOwnProperty("error") && response?.error != null) {
        return thunkAPI.rejectWithValue(
          response?.response?.response?.data?.error?.message
        );
      }
      return response;
    } catch (error) {
      console.log("error: ", error);
    }
  }
);

export const getLocalitiesList = createAsyncThunk(
  "newTransact/getLocalities",
  async (state, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await getLocalities(token, state);
      if (response.hasOwnProperty("error") && response?.error != null) {
        return thunkAPI.rejectWithValue(
          response?.response?.response?.data?.error?.message
        );
      }
      return response;
    } catch (error) {
      console.log("error: ", error);
    }
  }
);

export const getProvincesList = createAsyncThunk(
  "newTransact/getProvinces",
  async (provinceId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await getProvinces(token);
      if (response.hasOwnProperty("error") && response?.error != null) {
        return thunkAPI.rejectWithValue(
          response?.response?.response?.data?.error?.message
        );
      }
      if (provinceId) {
        thunkAPI.dispatch(getLocalitiesList(provinceId));
      }
      return response?.data;
    } catch (error) {
      console.log("error: ", error);
    }
  }
);

export const getMisionesLocalitiesList = createAsyncThunk(
  "newTransact/getMisionesLocalities",
  async (state, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await getLocalities(token, 19);
      if (response.hasOwnProperty("error") && response?.error != null) {
        return thunkAPI.rejectWithValue(
          response?.response?.response?.data?.error?.message
        );
      }
      return response;
    } catch (error) {
      console.log("error: ", error);
    }
  }
);

export const sendPdfData = createAsyncThunk(
  "newTransact/sendPdfData",
  async (key, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await getProviderConstancy(token, key);
      if (response.hasOwnProperty("error") && response?.error != null) {
        return thunkAPI.rejectWithValue(
          response?.response?.response?.data?.error?.message
        );
      }
      downloadStreamFile(response, "constancia");
      return response;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const getAvailableTransactTypes = createAsyncThunk(
  "newTransact/getTransactTypes",
  async (cuit, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await getAvailableTicketsTypes(token, cuit);
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          "No se han podido recuperar los trámites disponibles...",
          response?.error?.response?.data?.error?.message
        );

        return thunkAPI.rejectWithValue(
          response?.response?.response?.data?.error?.message
        );
      }
      return response;
    } catch (error) {
      console.log("error: ", error);
    }
  }
);

export const finalizeNewTransact = createAsyncThunk(
  "newTransact/finalizeNewTransact",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await postProcedures(token, data);
      if (response.hasOwnProperty("error") && response?.error != null) {
        if (response?.error?.code === "ERR_CANCELED") {
          showInfo(
            "Lo sentimos, el trámite que esá intentando completar está tardando más de lo esperado",
            "Por favor, verifique en unos minutos si su trámite se ve reflejado en la pantalla de inicio, de lo contrario intente nuevamente."
          );
          history.navigate("/");
          return;
        }
        showError(
          "No se pudo finalizar el trámite, intente nuevamente en unos minutos.",
          response?.error?.message
        );
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      thunkAPI.dispatch(cleanNewTransactState());
      thunkAPI.dispatch(clearMembers());
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryGetAddresses = createAsyncThunk(
  "newTransact/getAddresses",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const personId = thunkAPI.getState().newTransact?.selectedPerson?.id;
      const response = await getAddresses(token, personId);
      const realAddress =
        response?.find((element) => element.addressTypeId === 2) ||
        response?.find((element) => element.addressTypeId === 4) ||
        response?.find((element) => element.addressTypeId === 1);
      const fiscalAddress =
        response?.find((element) => element.addressTypeId === 1) ||
        response?.find((element) => element.addressTypeId === 4);
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          "No se han podido recuperar las direcciones de la persona",
          response?.error?.response?.data?.error?.message
        );

        return thunkAPI.rejectWithValue(
          response?.response?.response?.data?.error?.message
        );
      }
      const responseLocalityInfo = await getLocalityInfo(
        token,
        realAddress?.localityId
      );
      return {
        addressesList: [response],
        realAddress: {
          ...realAddress,
          provinceId: responseLocalityInfo?.provinceId,
        },
        fiscalAddress,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryGetLocalityInfo = createAsyncThunk(
  "newTransact/getLocalityInfo",
  async (localityId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await getLocalityInfo(token, localityId);
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          `No se pudo recuperar la información sobre la localidad id ${localityId}`,
          response?.error?.response?.data?.error?.message
        );
        return thunkAPI.rejectWithValue(
          response?.response?.response?.data?.error?.message
        );
      }
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryGetEconomicActivities = createAsyncThunk(
  "newTransact/getEconomicActivities",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const personId = thunkAPI.getState().newTransact?.selectedPerson?.id;
      const response = await getEconomicActivities(token, personId);
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          "No se han podido recuperar las actividades económicas de la persona",
          response?.error?.response?.data?.error?.message
        );

        return thunkAPI.rejectWithValue(
          response?.response?.response?.data?.error?.message
        );
      }
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryGetEmails = createAsyncThunk(
  "newTransact/getEmails",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const personId = thunkAPI.getState().newTransact?.selectedPerson?.id;
      const response = await getEmails(token, personId);
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          "No se han podido recuperar los correos electrónicos de la persona",
          response?.error?.response?.data?.error?.message
        );

        return thunkAPI.rejectWithValue(
          response?.response?.response?.data?.error?.message
        );
      }
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const newTransactSlice = createSlice({
  name: "newTransact",
  initialState,
  reducers: {
    selectPerson: (state, action) => {
      state.selectedPerson = action.payload;
    },
    selectProcedure: (state, action) => {
      state.selectedProcedureId = action.payload;
    },
    realAddress: (state, action) => {
      state.realAddress = action.payload;
    },
    fiscalAddress: (state, action) => {
      state.fiscalAddress = action.payload;
    },
    cleanNewTransactState: (state, action) => {
      state.persons = [];
      state.provinces = [];
      state.localities = [];
      state.availableTransactTypes = [];
      state.misionesLocalities = [];
      state.realAddress = {};
      state.fiscalAddress = {};
      state.kindOfPerson = "";
    },
    clearNewTransactState: (state, action) => {
      state.persons = [];
      state.isFetching = false;
      state.isError = false;
      state.selectedPerson = {};
      state.selectedProcedureId = null;
      state.provinces = [];
      state.localities = [];
      state.availableTransactTypes = [];
      state.misionesLocalities = [];
      state.realAddress = {};
      state.fiscalAddress = {};
      state.constancyURL = "";
      state.procedureId = "";
      state.kindOfPerson = "";
    },
  },
  extraReducers: (builder) => {
    // FETCH MANAGED PERSONS
    builder.addCase(fetchManagedPersons.fulfilled, (state, action) => {
      state.persons = action.payload;
      state.isError = false;
      state.isFetching = false;
    });
    builder.addCase(fetchManagedPersons.pending, (state, action) => {
      state.isError = false;
      state.isFetching = true;
    });
    builder.addCase(fetchManagedPersons.rejected, (state, action) => {
      state.isError = true;
      state.isFetching = false;
    });

    // SEND PDF DATA
    builder.addCase(sendPdfData.fulfilled, (state, action) => {
      state.isError = false;
      state.isFetching = false;
    });
    builder.addCase(sendPdfData.pending, (state, action) => {
      state.isError = true;
      state.isFetching = true;
    });
    builder.addCase(sendPdfData.rejected, (state, action) => {
      state.isError = true;
      state.isFetching = false;
    });

    // GET AVAILABLE TRANSACT TYPES
    builder.addCase(getAvailableTransactTypes.fulfilled, (state, action) => {
      state.availableTransactTypes = action.payload;
      state.isFetching = false;
      state.isError = false;
    });
    builder.addCase(getAvailableTransactTypes.pending, (state, action) => {
      state.isFetching = true;
      state.isError = false;
    });
    builder.addCase(getAvailableTransactTypes.rejected, (state, action) => {
      state.isError = true;
      state.isFetching = false;
    });
    // GET PROVINCES
    builder.addCase(getProvincesList.fulfilled, (state, action) => {
      state.provinces = action.payload;
    });
    builder.addCase(getProvincesList.rejected, (state, action) => {
      state.provinces = null;
    });

    // GET LOCALITIES
    builder.addCase(getLocalitiesList.fulfilled, (state, action) => {
      state.localities = action.payload;
    });
    builder.addCase(getLocalitiesList.rejected, (state, action) => {
      state.localities = null;
    });

    // GET MISIONES LOCALITIES
    builder.addCase(getMisionesLocalitiesList.fulfilled, (state, action) => {
      state.misionesLocalities = action.payload;
    });

    // FINALIZE NEW TRANSACT
    builder.addCase(finalizeNewTransact.fulfilled, (state, action) => {
      state.constancyURL = action.payload?.data?.constancyURL;
      state.procedureId = action.payload?.data?.procedureId;
      state.kindOfPerson = action.payload?.data?.provider?.person?.kindOfPerson;
      state.isFetching = false;
      state.isError = false;
    });
    builder.addCase(finalizeNewTransact.pending, (state, action) => {
      state.isFetching = true;
      state.isError = false;
    });
    builder.addCase(finalizeNewTransact.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = true;
    });

    // GET ADDRESSES - STEP THREE
    builder.addCase(tryGetAddresses.fulfilled, (state, action) => {
      state.selectedPerson.addresses = action.payload;
      history.navigate(NEW_TRANSACT_STEP_THREE);
      state.isFetching = false;
      state.isError = false;
    });
    builder.addCase(tryGetAddresses.pending, (state, action) => {
      state.isFetching = true;
      state.isError = false;
    });
    builder.addCase(tryGetAddresses.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = true;
    });

    // GET ECONOMIC ACTIVITIES - STEP FOUR
    builder.addCase(tryGetEconomicActivities.fulfilled, (state, action) => {
      state.selectedPerson.economicActivities = action.payload;
      state.isFetching = false;
      state.isError = false;
    });
    builder.addCase(tryGetEconomicActivities.pending, (state, action) => {
      state.isFetching = true;
      state.isError = false;
    });
    builder.addCase(tryGetEconomicActivities.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = true;
    });

    // GET EMAILS
    builder.addCase(tryGetEmails.fulfilled, (state, action) => {
      state.selectedPerson.emails = action.payload;
      state.isFetching = false;
      state.isError = false;
    });
    builder.addCase(tryGetEmails.pending, (state, action) => {
      state.isFetching = true;
      state.isError = false;
    });
    builder.addCase(tryGetEmails.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = true;
    });

    // GET LOCALITY INFO
    builder.addCase(tryGetLocalityInfo.fulfilled, (state, action) => {
      state.selectedPerson.localityInfo = action.payload;
      state.isFetching = false;
      state.isError = false;
    });
    builder.addCase(tryGetLocalityInfo.pending, (state, action) => {
      state.isFetching = true;
      state.isError = false;
    });
    builder.addCase(tryGetLocalityInfo.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = true;
    });
  },
});

export const {
  selectPerson,
  selectProcedure,
  realAddress,
  fiscalAddress,
  cleanNewTransactState,
  clearNewTransactState,
} = newTransactSlice.actions;

export default newTransactSlice.reducer;
