import { handleHttpError, httpGet } from "./httpService";

// import config from "../config";
import { env } from "../env";
const URL = `${env.API__BACKEND}/api`;

export const getAfipPerson = async (cuit) => {
  let uri = `${URL}/v1/AfipInformation/persons/${cuit}`;

  try {
    let response = await httpGet(uri);
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const getAfipPersonName = async (cuit) => {
  let uri = `${URL}/v1/AfipInformation/personsName/${cuit}`;
  try {
    let response = await httpGet(uri);
    return response.data?.data;
  } catch (error) {
    return handleHttpError(error);
  }
};
