import barrier from "../../assets/images/barrier.png";
import LoginStructure from "../common/loginStructure";
import { Grid, Typography } from "@mui/material";
import React from "react";
import { Image } from "react-bootstrap";

export default function MaintenancePage() {
  return (
    <LoginStructure>
      <Grid sx={{ padding: "2rem", textAlign: "center" }}>
        <Grid item xs={12}>
          <Image fluid src={barrier} width="30%" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="title" sx={{ fontWeight: 800, mb: "2rem" }}>
            Página en mantenimiento
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="title2"
            sx={{ fontSize: "2.4rem", fontWeight: 400 }}
          >
            El sistema volverá a estar disponible en breve.
          </Typography>
        </Grid>
      </Grid>
    </LoginStructure>
  );
}
