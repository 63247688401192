import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  showError,
  showSuccess,
  showWarning,
} from "../../../utils/utils";
import {
  deleteRepresentative,
  getAllRepresentatives,
  getRepresentativeByProcedureId,
  postRepresentative,
} from "../../../services/representativesService";
import { loadState } from "../../../localStorage";

const initialState = loadState()?.representatives ?? {
  representativesList: [],
  procedureId: "",
  canFinish: "",
  isFetching: "",
  hasError: "",
};

export const tryPostRepresentative = createAsyncThunk(
  "representatives/postRepresentative",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const representativesList = thunkAPI.getState().representatives?.representativesList;
      const exists = representativesList.find((obj) => obj?.cuit === data?.cuit);
      if (!exists) {
        const response = await postRepresentative(token, data);
        if (response.hasOwnProperty("error") && response?.error != null) {
          showWarning("No se ha podido cargar el apoderado.", "");
          return thunkAPI.rejectWithValue(response?.error?.message);
        }
        if (response?.status === 418 || response?.status === 500) {
          showError("Error del servidor.", response?.error?.message);
          return thunkAPI.rejectWithValue({
            error: response?.error?.message,
          });
        }
        showSuccess("Apoderado añadido con éxito", "");
        thunkAPI.dispatch(tryGetRepresentativeByProcedureId(data?.procedureId));
        return response.data;
      } else {
        showWarning("El apoderado ya existe.", "Debe ingresar un apoderado diferente.")
        return thunkAPI.rejectWithValue({
          error: "El apoderado ya existe.",
        });
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryGetAllRepresentatives = createAsyncThunk(
  "representatives/modifyRepresentative",
  async (data, thunkAPI) => {
    try {
      data = {
        ...data,
        id: thunkAPI.getState().municipalAuthorization?.selectedAuthorization
          ?.id,
      };
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await getAllRepresentatives(token, data);
      if (response.hasOwnProperty("error") && response?.error != null) {
        showWarning("No se han podido recuperar los apoderados.", "");
        return thunkAPI.rejectWithValues(response?.error?.message);
      }
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryGetRepresentativeByProcedureId = createAsyncThunk(
  "representatives/getRepresentativeByProcedureId",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await getRepresentativeByProcedureId(token, id);
      if (response.hasOwnProperty("error") && response?.error != null) {
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      if (response?.data?.data[0]) {
        thunkAPI.dispatch(setCanFinish(true));
      } else {
        thunkAPI.dispatch(setCanFinish(false));
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tryDeleteRepresentative = createAsyncThunk(
  "representatives/deleteRepresentative",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().login?.authentication?.access_token;
      const response = await deleteRepresentative(token, data?.id);
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          "No se pudo eliminar el apoderado.",
          response?.error?.message
        );
        return thunkAPI.rejectWithValue(response?.error?.message);
      }
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message);
        return thunkAPI.rejectWithValue({
          error: response?.error?.message,
        });
      }
      showSuccess("Apoderado eliminado con éxito", "");
      thunkAPI.dispatch(tryGetRepresentativeByProcedureId(data?.procedureId));
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const representativesSlice = createSlice({
  name: "representatives",
  initialState,
  reducers: {
    setSelectedAuthorization: (state, action) => {
      state.selectedAuthorization = action.payload;
    },
    persistProcedureId: (state, action) => {
      state.procedureId = action.payload;
    },
    clearRepresentativesState: (state, action) => {
      state.representativesList = [];
      state.procedureId = "";
      state.canFinish = "";
      state.isFetching = "";
      state.hasError = "";
    },
    setModificationBoolean: (state, action) => {
      state.isModification = action.payload;
    },
    setCanFinish: (state, action) => {
      state.canFinish = action.payload;
    },
  },
  extraReducers: (builder) => {
    // TRY POST REPRESENTATIVE
    builder.addCase(tryPostRepresentative.fulfilled, (state, action) => {
      state.canFinish = true;
      state.hasError = false;
      state.isFetching = false;
    });
    builder.addCase(tryPostRepresentative.pending, (state, action) => {
      state.canFinish = false;
      state.hasError = false;
      state.isFetching = true;
    });
    builder.addCase(tryPostRepresentative.rejected, (state, action) => {
      state.canFinish = false;
      state.hasError = true;
      state.isFetching = false;
    });

    // GET REPRESENTATIVE BY PROCEDURE ID
    builder.addCase(
      tryGetRepresentativeByProcedureId.fulfilled,
      (state, action) => {
        state.representativesList = action.payload?.data;
        state.hasError = false;
        state.isFetching = false;
      }
    );
    builder.addCase(
      tryGetRepresentativeByProcedureId.pending,
      (state, action) => {
        state.hasError = false;
        state.isFetching = true;
      }
    );
    builder.addCase(
      tryGetRepresentativeByProcedureId.rejected,
      (state, action) => {
        state.hasError = true;
        state.isFetching = false;
      }
    );

    // MODIFY REPRESENTATIVE
    builder.addCase(tryGetAllRepresentatives.fulfilled, (state, action) => {
      state.isModification = false;
      state.hasError = false;
      state.isFetching = false;
    });
    builder.addCase(tryGetAllRepresentatives.pending, (state, action) => {
      state.hasError = false;
      state.isFetching = true;
    });
    builder.addCase(tryGetAllRepresentatives.rejected, (state, action) => {
      state.hasError = true;
      state.isFetching = false;
    });
  },
});

export const { persistProcedureId, clearRepresentativesState, setCanFinish } =
  representativesSlice.actions;

export default representativesSlice.reducer;
