import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles.css';
import { store } from './store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import appSettings from './appSettings';

const root = ReactDOM.createRoot(document.getElementById('root'));
const clientId = appSettings.appSettings.clientId;

console.warn = console.error = () => {};

root.render(
    <Provider store={store}>
      <BrowserRouter>
      <GoogleOAuthProvider clientId={clientId}>
        <App />
      </GoogleOAuthProvider>
      </BrowserRouter>
    </Provider>
);