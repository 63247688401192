import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import loginReducer from "./components/pages/loginPage/loginSlice";
import newTransactReducer from "./features/newTransact/newTransactSlice";
import associateCuitReducer from "./features/associateCuit/associateCuitSlice";
import modalReducer from "./components/modals/modalSlice.js";
import modalChildReducer from "./components/modals/modalChildSlice.js";
import requirementsReducer from "./components/pages/requirementsPage/requirementsSlice.js";
import lastTransactsTableReducer from "./features/lastTransactsTable/tableSlice";
import registerReducer from "./components/pages/registerPage/registerSlice";
import passwordRecoveryReducer from "./components/pages/passwordRecoveryPage/passwordRecoverySlice";
import passwordChangeReducer from "./components/pages/passwordChangePage/passwordChangeSlice";
import municipalAuthorizationReducer from "./components/pages/municipalAuthorizationPage/municipalAuthorizationSlice";
import membersUploadReducer from "./components/pages/membersUploadPage/membersUploadSlice";
import commonReducer from "./components/common/commonSlice";
import representativesReducer from "./components/pages/representativesPage/representativesSlice";
import associateDetailsReducer from "./components/pages/associateDetailsPage/associateDetailsSlice.js";
import requirementsReviewReducer from "./components/pages/requirementsReviewPage/requirementsReviewSlice.js";
import deleteAccountReducer from "./components/pages/deleteAccount/deleteAccountSlice.js";
//import { loadState } from "./localStorage";

//debería leerse de localstorage
const preloadedState = {};

const reducers = {
  login: loginReducer,
  newTransact: newTransactReducer,
  associateCuit: associateCuitReducer,
  modal: modalReducer,
  modalChild: modalChildReducer,
  requirements: requirementsReducer,
  lastTransactsTable: lastTransactsTableReducer,
  register: registerReducer,
  passwordRecovery: passwordRecoveryReducer,
  passwordChange: passwordChangeReducer,
  municipalAuthorization: municipalAuthorizationReducer,
  membersUpload: membersUploadReducer,
  common: commonReducer,
  representatives: representativesReducer,
  associateDetails: associateDetailsReducer,
  requirementsReview: requirementsReviewReducer,
  deleteAccount: deleteAccountReducer,
};

const saveStateMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  if (action.type !== 'login/logout') {
    const localState = store.getState();
    localStorage.setItem("LocalStorage", JSON.stringify(localState));
  }
  return result;
};

export const store = configureStore({
  reducer: reducers,
  middleware: (getdefaultMiddleware) => getdefaultMiddleware(
    {
      serializableCheck: false,
    }
  ).concat([
      logger, 
      saveStateMiddleware
  ]),
  preloadedState,
});