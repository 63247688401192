import { httpPost, handleHttpError, httpGet } from "./httpService";
import { buildDefaultOptions } from "./authService";
import { showError } from "../utils/utils";

import {env} from "../env";
const URL = `${env.API__BACKEND}/api`;
const PortalURI = `${env.API__PORTAL}`;

//get people managed by the logged user - DEPRECATED
export const getManagedPersons = async (accessToken, cancelation) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v2/Users/logged/person`;

  try{
    let response = await httpGet(uri, getOptions);
    return response.data?.data;
  } catch(error){
    showError("No se pudo cargar las personas asociadas.","");
    return handleHttpError(error);
  }
};

//send the email with the token for associate a person
export const postSendTokenToAssociatePerson = async (token, cuit) => {
  let getOptions = buildDefaultOptions(token);
  let uri = `${URL}/v1/Token/manageperson/sendvalidation/${cuit}`;

  try {
    let response = await httpPost(uri, null, getOptions);
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
}

//verify the token sent to the user email for associate
export const postValidateTokenToAssociatePerson = async (tokenAccess, data) => {
  let getOptions = buildDefaultOptions(tokenAccess);
  let uri = `${URL}/v1/Token/manageperson/validationToken/`;

  let validationBody = {
    "cuit": data?.cuit,
    "token": data?.token
  }

  try {
    let response = await httpPost(uri, validationBody, getOptions);
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
}

export const getCurrentProcedures = async (accessToken) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v2/Users/logged/currentProcedures`;

  try {
    let response = await httpGet(uri, getOptions);
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
}

export const getLastestProcedures = async (accessToken) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v2/Users/logged/lastProcedures`;

  try {
    let response = await httpGet(uri, getOptions);
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
}

export async function getProviderDetails(cuit) {
  const data = {
    cuit: cuit,
    companyName: "",
    economicActivityTypeId: "",
    economicActivitySubTypeId: "",
    statusIds: [2,3,4,5,6,7],
  }
  const encodedData = encodeURIComponent(JSON.stringify(data));
  const params = {
    getListProvider: "getListProvider",
    pageId: "1",
    pageSize: "1",
  };
  const url = `${PortalURI}/?getListProvider=${params.getListProvider}&pageId=${params.pageId}&pageSize=${params.pageSize}&filter=${encodedData}`;
  const response = await httpGet(url);
  return (response.data.data[0]) ? response?.data?.data[0] : {status: response.status, cuit: cuit, data: false};
}

export const getActivitiesById = async (id) => {
  const uri = `${PortalURI}/?activitiesById=ActivitiesById&id=${id}`;
  try {
    let response = await httpGet(uri);
    return response !== "" ? response.data : null;
  } catch (error) {
    return handleHttpError(error);    
  }
}

export const requestAccountDeletion = async (email) => {
  const uri = `${URL}/v1/Users/DeleteAccount/${email}`;
  try {
    let response = await httpPost(uri);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
}