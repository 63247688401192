import { buildDefaultOptions } from "./authService";
import { httpPost, httpPut, httpGet, handleHttpError, httpDelete } from "./httpService";

import {env} from "../env";
const URL = `${env.API__BACKEND}/api`;

export const postMember = async (accessToken, data) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v2/Members`;

  try {
    let response = await httpPost(uri, data, getOptions);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const getMembers = async (accessToken) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v2/Members`;

  try {
    let response = await httpGet(uri, getOptions);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
};

//XXX: no se esta utilizando
export const getMembersById = async (accessToken, id) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v2/Members/${id}`;

  try {
    let response = await httpGet(uri, getOptions);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
};

//XXX: no se esta utilizando
export const putMember = async (accessToken, data) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v2/Members/${data?.id}`;

  try {
    let response = await httpPut(uri, data, getOptions);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const getMembersByProcedureId = async (accessToken, id) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v2/Members/procedureId/${id}`;

  try {
    let response = await httpGet(uri, getOptions);
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const deleteMember = async (accessToken, id) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v2/Members/${id}`;

  try {
    let response = await httpDelete(uri, getOptions);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
}