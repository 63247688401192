import { handleHttpError, httpGet } from "./httpService";
import { buildDefaultOptions } from "./authService";

// import config from "../config";
import {env} from "../env";
const URL = `${env.API__BACKEND}/api`;

export const getRequirementsByProcedureId = async (accessToken, id) => {
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v2/requirements/pending/procedureId/${id}`;

  try{
    let response = await httpGet(uri, getOptions);
    return response.data;
  } catch(error){
    return handleHttpError(error);
  }
};

export const getDownloadRequirement = async (accessToken, id) => {
  let getOptions = buildDefaultOptions(accessToken);
  getOptions.responseType = "blob";

  let uri = `${URL}/v1/Requirements/DownloadRequirement/${id}`;

  try{
    let response = await httpGet(uri, getOptions);
    return response;
  } catch(error){
    return handleHttpError(error);
  }
};

export const getDownloadRequirementToValidate = async (requirement) => {
  const options = { responseType: "blob" };

  let uri = `${URL}/v1/Requirements/DownloadRequirementToValidate/${requirement.id}/${requirement.checkCode}`;

  try {
    let response = await httpGet(uri, options);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const getRequirementsToValidateList = async (checkCode) => {
  let uri = `${URL}/v2/Requirements/RequirementToValidateList/procedureId/${checkCode}`;

  try {
    let response = await httpGet(uri);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
};