import { Typography, Grid, Button, TextField, InputAdornment, SvgIcon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../_helpers/history";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import AppLoading from "../../common/appLoading";
import { putChangePassword } from "./passwordChangeSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const PasswordChangePage = (props) => {
  const dispatch = useDispatch();
  const isFetching = useSelector((state) => state?.passwordChange?.isFetching);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordConfirm, setShowNewPasswordConfirm] = useState(false);

  const pswValidationSchema = yup.object({
    oldPsw: yup.string().required("Debe ingresar su contraseña actual"),
    newPsw: yup
      .string()
      .required("Debe ingresar una contraseña")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "Debe contener 8 caracteres, al menos una mayúscula, una minúscula y un número."
      ),
    confirmPsw: yup
      .string()
      .required("Debe ingresar una contraseña")
      .oneOf([yup.ref("newPsw"), null], "Las contraseñas deben coincidir"),
  });

  const formik = useFormik({
    initialValues: {
      oldPsw: "",
      newPsw: "",
      confirmPsw: "",
    },
    onSubmit: (values) => {
      dispatch(putChangePassword(values));
    },
    validationSchema: pswValidationSchema,
  });

  const handleVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleVisibilityNewPass = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleVisibilityNewPassConfirm = () => {
    setShowNewPasswordConfirm(!showNewPasswordConfirm);
  };

  return (
    <Grid
      component="form"
      onSubmit={formik.handleSubmit}
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      variant="custom"
      sx={{ pt: "2rem", mb: "5rem" }}
    >
      <Grid
        container
        sx={{ width: "60vw", justifyContent: "left", textAlign: "left" }}
      >
        <Grid item xs={12} sx={{ ml: { xs: "0rem", md: "2rem", lg: "5rem" } }}>
          <Typography variant="title">Cambiar contraseña</Typography>
        </Grid>
        <br></br>
        <Grid
          item
          xs={12}
          sx={{ ml: { xs: "0rem", md: "2rem", lg: "5rem" }, mb: "2rem" }}
        >
          <Typography variant="textParagraph">
            La nueva contraseña debe tener al menos 8 caracteres y debe incluir
            al menos una letra mayúscula, una minúscula y un número.
          </Typography>
        </Grid>

        <Grid container>
          <Grid
            item
            xs={12}
            sx={{ pt: "2rem", ml: { xs: "0rem", md: "2rem", lg: "5rem" } }}
          >
            <TextField
              name="oldPsw"
              value={formik.values.oldPsw}
              onChange={formik.handleChange}
              error={formik.touched.oldPsw && Boolean(formik.errors.oldPsw)}
              helperText={formik.touched.oldPsw && formik.errors.oldPsw}
              margin="dense"
              id="oldPsw"
              label="Contraseña actual"
              size="normal"
              sx={{ width: "25rem" }}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SvgIcon
                      component={showPassword ? Visibility : VisibilityOff}
                      onClick={handleVisibility}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ pt: "1.5rem", ml: { xs: "0rem", md: "2rem", lg: "5rem" } }}
          >
            <TextField
              name="newPsw"
              value={formik.values.newPsw}
              onChange={formik.handleChange}
              error={formik.touched.newPsw && Boolean(formik.errors.newPsw)}
              helperText={formik.touched.newPsw && formik.errors.newPsw}
              margin="dense"
              id="newPsw"
              label="Nueva Contraseña"
              size="normal"
              sx={{ width: "25rem" }}
              type={showNewPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SvgIcon
                      component={showNewPassword ? Visibility : VisibilityOff}
                      onClick={handleVisibilityNewPass}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ pt: "1.5rem", ml: { xs: "0rem", md: "2rem", lg: "5rem" } }}
          >
            <TextField
              name="confirmPsw"
              value={formik.values.confirmPsw}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPsw && Boolean(formik.errors.confirmPsw)
              }
              helperText={formik.touched.confirmPsw && formik.errors.confirmPsw}
              margin="dense"
              id="confirmPsw"
              label="Confirme Contraseña"
              size="normal"
              sx={{ width: "25rem" }}
              type={showNewPasswordConfirm ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SvgIcon
                      component={showNewPasswordConfirm ? Visibility : VisibilityOff}
                      onClick={handleVisibilityNewPassConfirm}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            sx={{
              pt: "5rem",
              ml: { xs: "0rem", md: "2rem", lg: "4rem" },
              justifyContent: "center",
            }}
          >
            {isFetching ? (
              <AppLoading size={5} />
            ) : (
              <Grid
                container
                spacing={2}
                marginBottom={5}
                sx={{ textAlign: "center", justifyContent: "center" }}
              >
                <Grid item xs={12} md={6} align="center">
                  <Button
                    variant="bold"
                    sx={{
                      width: "20rem",
                    }}
                    onClick={() => history.navigate("/profile")}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} align="center">
                  <Button
                    type="submit"
                    variant="success"
                    sx={{ width: "20rem" }}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PasswordChangePage;
