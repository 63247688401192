import React, { useEffect } from "react";
import Header from "../components/common/header";
import { useDispatch, useSelector } from "react-redux";
import { Card, Grid, Grow, ThemeProvider } from "@mui/material";
import Theme from "../components/pages/themes";
import { history } from "../_helpers/history";
import { logout } from "../components/pages/loginPage/loginSlice";
import { Box } from "@mui/system";
import Footer from "../components/common/footer";
import { useLocation } from "react-router-dom";

// import appSettings from "../appSettings";
import {env} from "../env";
import EnvironmentCard from "../utils/environmentCard";
import RequirementsReviewPage from "../components/pages/requirementsReviewPage/requirementsReviewPage";
import CookieConsent from "../components/common/cookieConsent";

const Layout = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const isRequirementsReview = pathname.startsWith("/requirements-review");

  let checkCode = null;
  if (isRequirementsReview) {
    const parts = pathname.split("/");
    if (parts.length > 2) {
      checkCode = parts[2];
    }
  }
  const dispatch = useDispatch();
  let isMaintenance = env.MAINTENANCE;
  const isLogged =
    useSelector((state) => state?.login?.isLogged) && !isMaintenance;

  const tokenExpires = useSelector(
    (state) => state?.login?.authentication?.expires_dates
  );

  let actualDate = new Date().toISOString();
  let isExpired = actualDate >= tokenExpires ? true : false;
  useEffect(() => {
    if (isExpired) {
      dispatch(logout());
      history.navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      {checkCode ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          variant="custom"
        >
          <Card
            variant="homeCard"
            sx={{
              minHeight: "100vh",
              width: {
                xs: "100vw",
                sm: "100vw",
                md: "90vw",
                lg: "80vw",
                xl: "70vw",
              },
              maxWidth: "100vw",
              marginTop: "0rem",
              overflowX: "visible",
              overflowY: "visible",
              overflow: "visible"
            }}
          >
            <RequirementsReviewPage checkCode={checkCode} />
          </Card>
        </Grid>
      ) : isLogged ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          variant="custom"
        >
          <Card
            variant="homeCard"
            sx={{
              minHeight: "100vh",
              width: {
                xs: "100vw",
                sm: "100vw",
                md: "90vw",
                lg: "80vw",
                xl: "70vw",
              },
              maxWidth: "100vw",
              marginTop: "0rem",
            }}
          >
            {isLogged && <Header />}
            {props.children}
          </Card>
        </Grid>
      ) : (
        <Grow in={true} timeout={1000}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            variant="custom"
          >
            <Card
              variant="homeCard"
              sx={{
                minHeight: "85vh",
                marginTop: "7.5vh",
                marginBottom: "7.5vh",
                width: {
                  xs: "100vw",
                  sm: "100vw",
                  md: "90vw",
                  lg: "80vw",
                  xl: "70vw",
                },
                justifyContent: "center",
              }}
            >
              {props.children}
            </Card>
          </Grid>
        </Grow>
      )}
      {(isLogged && !checkCode) ? (
        <Box sx={{ textAlign: "-webkit-center" }}>
          <Footer></Footer>
        </Box>
      )
        : null
      }
      <EnvironmentCard />
      <CookieConsent />
    </ThemeProvider>
  );
};

export default Layout;
