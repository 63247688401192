import {
  Typography,
  Grid,
  Box,
  Button,
  Link,
  Input,
  SvgIcon,
} from "@mui/material";
import LoginStructure from "../../common/loginStructure";
import { useDispatch, useSelector } from "react-redux";
import { postValidateTokenRecoveryAccountAsync } from "./passwordRecoverySlice";
import { postSendTokenRecoveryAccountAsync } from "./passwordRecoverySlice";
import * as yup from "yup";
import { useFormik } from "formik";
import AppLoading from "../../common/appLoading";
import { handleMaxLenght } from "../../../utils/utils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { history } from "../../../_helpers/history";

const ForgotPasswordPageStepTwo = (props) => {
  const dispatch = useDispatch();
  const isFetching = useSelector(
    (state) => state?.postValidateTokenRecoveryAccountAsync?.isFetching
  );
  const userID = useSelector((state) => state?.passwordRecovery?.UserID);
  const emailStep1 = useSelector((state) => state?.passwordRecovery?.email);

  const validationSchema = yup.object({
    token: yup
      .string()
      .required("Debe ingresar el código")
      .matches(/^(?=.{4,4})/, "El codigo debe ser de 4 dígitos"),
  });

  const formik = useFormik({
    initialValues: {
      token: "",
    },
    onSubmit: (values) => {
      const data = {
        token: values?.token,
        UserID: userID,
      };
      dispatch(postValidateTokenRecoveryAccountAsync(data));
    },
    validationSchema: validationSchema,
  });

  const onClickSendNewCode = (event) => {
    dispatch(
      postSendTokenRecoveryAccountAsync({
        email: emailStep1,
      })
    );
  };

  const inputStyleProps = {
    style: {
      borderRadius: "10rem",
      borderColor: "rgba(0,0,0,0)",
      fontFamily: "Open sans",
      fontSize: "3rem",
      fontWeight: "bolder",
      textAlign: "center",
      color: "#1A3762",
      height: "4rem",
      marginLeft: "0.5rem",
      marginRight: "0.5rem",
      letterSpacing: "2rem",
    },
  };

  const handleBack = () => {
    history.navigate("/forgot-password");
  };

  return (
    <Grid container spacing={2}>
      <Grid item sx={{ marginLeft: "5rem" }} id="btnVolver">
        <Link variant="blackLink" underline="none" onClick={handleBack}>
          <SvgIcon
            component={ArrowBackIcon}
            sx={{ fontSize: "2.4rem" }}
          ></SvgIcon>
          volver
        </Link>
      </Grid>
      <Grid item xs={12} md={12}>
        <LoginStructure>
          <Grid container spacing={4} sx={{ justifyContent: "center" }}>
            <Grid item textAlign="left">
              <Typography variant="title">Ingrese el código</Typography>
            </Grid>
            <Grid item xs={8} md={8} sx={{ textAlign: "center" }}>
              <Typography
                variant="textParagraph"
                sx={{ width: "60%", mt: "1.5rem" }}
              >
                Un código de verificación fue enviado al correo que acaba de
                ingresar.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={8}
            md={8}
            sx={{ textAlign: "center"}}
          >
            <Box
              component="form"
              noValidate
              sx={{ mt: "0rem", mb: "0rem", justifyContent: "center" }}
              onSubmit={formik.handleSubmit}
              container
              spacing={4}
            >
              <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
                <Input
                  id="token"
                  name="token"
                  type="number"
                  variant="codeInput"
                  inputProps={inputStyleProps}
                  value={formik.values.token}
                  onChange={formik.handleChange}
                  error={formik.touched.token && Boolean(formik.errors.token)}
                  sx={{
                    width: "80%",
                    mt: "4rem",
                    mb: "2rem",
                    boxShadow: "inset 0rem 0.2rem 0.6rem rgba(0, 0, 0, 0.2)",
                    "&:before": {
                      display: "none",
                    },
                  }}
                  onInput={(e) => {
                    handleMaxLenght(e, 4);
                  }}
                />
                <Typography
                  variant="h6"
                  textAlign="center"
                  sx={{ fontSize: "1rem", color: "red" }}
                >
                  {formik.touched.token && formik.errors.token}
                </Typography>
              </Grid>
              <Button
                variant="success"
                type="submit"
                sx={{ mt: 1, mb: 2, width: "60%" }}
              >
                {isFetching ? <AppLoading size={3} /> : "Validar"}
              </Button>
              <br />
              <Grid item>
                <Typography variant="textDescription">
                  <Link
                    onClick={(event) => onClickSendNewCode(event)}
                    variant="custom"
                  >
                    {"Reenviar código"}
                  </Link>
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </LoginStructure>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordPageStepTwo;
