import { handleHttpError, httpGet } from "./httpService";
import { buildDefaultOptions } from "./authService";

import { env } from "../env";

export const getLocalityInfo = async (accessToken, id) => {
  const URL = `${env.API__BACKEND}/api`;
  let uri = `${URL}/v1/Localities/${id}`;
  let getOptions = buildDefaultOptions(accessToken);
  try {
    let response = await httpGet(uri, getOptions);
    return response?.data;
  } catch (error) {
    return handleHttpError(error);
  }
};
