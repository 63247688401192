import React, { useState } from "react";
import {
  Typography,
  Grid,
  Box,
  Button,
  Link,
  Container,
  TextField,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
  FormControl,
  FormHelperText,
  SvgIcon,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchLogin, tryLoginWithGoogle } from "./loginSlice";
import AppLoading from "../../common/appLoading";
import LoginStructure from "../../common/loginStructure";
import { GoogleLogin } from "@react-oauth/google";
import { clearHash } from "../registerPage/registerSlice.js";
import { useFormik } from "formik";
import * as yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { primaryColor } from "../themes.js";
import { env } from "../../../env.js";
import { FORGOT_PASSWORD, HOME, REGISTER_URI } from "../../../routing/constants.js";
import { Navigate } from "react-router-dom";

const LoginPage = (props) => {
  const isFetching = useSelector((x) => x?.login?.isFetching);
  const isLogged = useSelector((x) => x?.login?.isLogged);
  const isError = useSelector((x) => x?.login?.isError);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const onSuccess = (response) => {
    console.log("exito: ", response);
    dispatch(tryLoginWithGoogle(response?.credential));
  };
  const onFailure = () => {
    console.log("error al iniciar sesion con Google");
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const loginValidationSchema = yup.object({
    email: yup
      .string()
      .trim()
      .email("Ingrese un email válido")
      .required("Debe ingresar un correo electrónico"),
    password: yup
      .string()
      .trim()
      .min(8, "Contraseña muy corta")
      .required("Debe ingresar su contraseña"),
  });

  function validatePassword(value) {
    let error;
    if (!value) {
      error = "Debe ingresar su contraseña";
    }
    return error;
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      dispatch(
        fetchLogin({
          username: values.email,
          password: values.password,
        })
      );
      dispatch(clearHash());
    },
    validationSchema: loginValidationSchema,
  });

  if (isLogged) {
    console.info("Ya se encuentra con una sesión activa.");
    return <Navigate to={HOME} />;
  }

  return (
    <LoginStructure>
      <Grid
        item
        sx={{ marginLeft: { xs: "3rem", md: "5rem", lg: "10rem" } }}
        id="btnVolver"
      >
        <Link
          variant="blackLink"
          underline="none"
          // TODO : Agregar URL de Portal de Proveedores correspondiente en el .env
          href={
            env?.ENVIRONMENT__NAME === "DESARROLLO"
              ? "https://dev.portalv2.proveedores.cgmisiones.gob.ar/"
              : env?.ENVIRONMENT__NAME === "STAGE"
              ? "https://stage.portalv2.proveedores.cgmisiones.gob.ar/"
              : "https://new.proveedores.cgmisiones.gob.ar/"
          }
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <SvgIcon
            component={ArrowBackIcon}
            sx={{ fontSize: "2.4rem", color: primaryColor }}
          ></SvgIcon>
          <Typography variant="title3">Ir al Portal de Proveedores</Typography>
        </Link>
      </Grid>
      <Grid sx={{ padding: "2rem" }}>
        <Typography variant="title">Inicio de sesión</Typography>
        <br />
        <Typography variant="title2">¡Bienvenido!</Typography>
      </Grid>
      {isFetching ? (
        <AppLoading size={5} />
      ) : (
        <Box
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
          sx={{ mt: 1, mb: 6 }}
        >
          <Grid textAlign="left" sx={{ width: { xs: "80%", sm: "60%" } }}>
            <TextField
              autoFocus
              name="email"
              type="email"
              label="Correo electrónico"
              id="email"
              autoComplete="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid
            textAlign="left"
            variant="inputText"
            sx={{ width: { xs: "80%", sm: "60%" }, mt: "1.5rem" }}
          >
            <FormControl
              error={formik.touched.password && Boolean(formik.errors.password)}
              sx={{ width: "100%" }}
            >
              <InputLabel htmlFor="outlined-adornment-password" shrink>
                Contraseña
              </InputLabel>
              <OutlinedInput
                id="password"
                name="password"
                autoComplete="current-password"
                margin="dense"
                label="Contraseña"
                size="normal"
                type={showPassword ? "text" : "password"}
                value={formik.values.password}
                onChange={formik.handleChange}
                validate={validatePassword}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText>
                {formik.touched.password && formik.errors.password}
              </FormHelperText>
            </FormControl>
          </Grid>
          {isError && (
            <Container
              sx={{
                textAlign: "center",
                mt: "0.5rem",
                mb: "0.5rem",
                width: { xs: "80%", sm: "60%" },
                padding: "0rem 1.4rem !important",
              }}
            >
              <Typography
                textAlign="left"
                sx={{ fontSize: "1.2rem", fontWeight: 200, color: "red" }}
              >
                Usuario y/o contraseña incorrectos.
              </Typography>
            </Container>
          )}
          <Grid>
            <Grid
              textAlign="right"
              sx={{ width: { xs: "80%", sm: "60%" }, mt: "0.5rem" }}
            >
              <Typography variant="textDescription">
                <Link href={FORGOT_PASSWORD} variant="custom">
                  {"Olvidé mi contraseña"}
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Button type="submit" variant="success" sx={{ mt: "2rem" }}>
            Ingresar
          </Button>
          <Grid
            textAlign="center"
            sx={{ width: { xs: "80%", sm: "60%" }, paddingTop: "2.5rem" }}
          >
            <div style={{ marginTop: "2rem", textAlign: "-webkit-center" }}>
              <GoogleLogin
                onSuccess={onSuccess}
                onError={onFailure}
                shape="pill"
                width={250}
                logo_alignment="center"
                locale="es-AR"
              />
            </div>

            <Grid item sx={{ mt: "2rem" }}>
              <Typography variant="textDescription">
                ¿No tiene cuenta aún?
                <Link href={REGISTER_URI} variant="custom">
                  {" Regístrese aquí"}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </LoginStructure>
  );
};

export default LoginPage;
