import * as React from 'react';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';



export default function SimpleGrow(props) {

  return (

      <Box sx={{ display: 'flex', width:"100%", placeContent:"center" }}>
        <Grow
          in={true}
          style={{ transformOrigin: '50% 50% 50% 50%' }}
          {...(true ? { timeout: props.timeout } : {})}
        >
          {props.children}
        </Grow>
      </Box>
  );
}