import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { history } from '../../_helpers/history';
import { useDispatch } from 'react-redux';
import { tryCloseModal } from '../modals/modalSlice';

export default function Menus() {
  const dispatch = useDispatch();

  const handleAssociateMenu = () => {
    history.navigate("/associate-cuit");
    dispatch(tryCloseModal());
  }

    const locations = [
    {value: 0, path:"/"},
    {value: 1, path:"/new-transact"},
    {value: 2, path:"/associate-cuit"},
  ];

  const actualLocation = window.location.pathname;  
  const indexPage = locations.filter(element => element.path === actualLocation ? element : "");

  return (
    <Box sx={{ width: "90%" }}>
      <Box>
        <Tabs value={indexPage[0]?.value} aria-label="basic tabs example">
          <Tab
            variant="menuTab"
            sx={{ width: "90px" }}
            label="Inicio"
            onClick={() => history.navigate("/")}
          />
          <Tab
            variant="menuTab"
            sx={{ width: "155px" }}
            label="Iniciar trámite"
            onClick={() => history.navigate("/new-transact")}
          />
          <Tab
            variant="menuTab"
            sx={{ width: "200px" }}
            label="Personas asociadas"
            onClick={handleAssociateMenu}
          />
        </Tabs>
      </Box>
    </Box>
  );
}
