import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NEW_TRANSACT_STEP_FOUR,
  NEW_TRANSACT_STEP_THREE,
} from "../../../routing/constants";
import { history } from "../../../_helpers/history";
import { addMember } from "./membersUploadSlice";
import MembersUploadTable from "./membersUploadTable";
import {
  clearPersonName,
  getPersonName,
} from "../../../features/associateCuit/associateCuitSlice";
import SearchIcon from "@mui/icons-material/Search";
import InputMask from "react-input-mask";
import { cuitIsValid } from "../../../utils/utils";
import { memberTypes } from "../../../utils/utils";
import AppLoading from "../../common/appLoading";
import { maskedInputStyle } from "../../../utils/utils";
import { lightPrimaryColor, primaryColor } from "../themes";
import { getLocalitiesList } from "../../../features/newTransact/newTransactSlice";

const MembersUploadPage = () => {
  const dispatch = useDispatch();

  const [cuit, setCuit] = useState("");
  const [cuitValid, setCuitValid] = useState(false);
  const name = useSelector((state) => state?.associateCuit?.personName);
  
  const provincias = useSelector((state) => state?.newTransact?.provinces);
  const localidades = useSelector(
    (state) => state?.newTransact?.localities?.data?.data || null
  );
  
  let onlyProvincias = provincias?.data?.map((item) => ({
    label: item?.name,
    id: item?.id,
  }));

  let onlyLocalidades = localidades?.map((item) => ({
    label: item?.name,
    id: item?.id,
  }));

  const defaultProvince = onlyProvincias?.find(
    (element) => element.id === parseInt(name?.domiciles?.provinceId)
  );

  const defaultRealLocality = onlyLocalidades?.find(
    (element) => element.id === parseInt(name?.domiciles?.localityId)
  );

  const isFetchingCuit = useSelector(
    (state) => state?.associateCuit?.isFetching
  );
  const isFetching = useSelector((state) => state?.membersUpload?.isFetching);
  const membersList = useSelector((state) => state?.membersUpload?.membersList);

  const validationSchema = yup.object({
    memberName: yup.string().required("Debe ingresar nombre y apellido"),
    memberType: yup.object().required("Debe seleccionar un cargo"),
    cuit: yup.string().required("Debe ingresar un CUIT"),
    real_province: yup.object().required("Debe ingresar una provincia"),
    real_locality: yup
      .object()
      .required("Debe ingresar una localidad")
      .test("not-empty-object", "Debe seleccionar una localidad", (value) => {
        // Verificar que el objeto no sea igual a { label: "", id: 0 }
        return value.label !== "" && value.id !== 0;
      }),
    real_address: yup.string().required("Debe ingresar un domicilio"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cuit: cuit,
      memberName: name?.name,
      memberType: "",
      real_province: defaultProvince ? defaultProvince : { label: "", id: 0 },
      real_locality: defaultRealLocality
        ? defaultRealLocality
        : { label: "", id: 0 },
      real_address: name?.domiciles?.description || "",
    },
    onSubmit: (values, { resetForm }) => {
      const data = {
        id: membersList ? membersList?.length + 1 : 0,
        cuit: values?.cuit,
        name: values?.memberName,
        memberType: values?.memberType,
        domiciles: {
          provinceId: values?.real_province,
          localityId: values?.real_locality,
          address: values?.real_address,
        },
      };
      dispatch(addMember(data));
      resetForm({ values: "" });
      handleCleanFields();
    },
    validationSchema: validationSchema,
  });

  const handleContinue = () => {
    history.navigate(NEW_TRANSACT_STEP_FOUR);
  };

  const handleValidateCuit = (cuit) => {
    dispatch(getPersonName(cuit));
  };

  const handleChangeCuit = (event) => {
    let unmaskedCuit = event.target.value.replace("-", "");
    let validateCuit = cuitIsValid(event.target.value);
    setCuit(unmaskedCuit.replace("-", ""));
    setCuitValid(validateCuit);
  };

  const handleChange = (value) => {
    dispatch(getLocalitiesList(value.id));
  };

  const handleCleanFields = () => {
    dispatch(clearPersonName());
    setCuit("");
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={formik.handleSubmit}
      enableReinitialize
    >
      <Grid container direction="column" sx={{ width: "60vw" }}>
        <Grid item xs={12} sx={{ ml: { xs: "0rem", md: "2rem", lg: "5rem" } }}>
          <Typography variant="title2">Integrantes de la Sociedad</Typography>
        </Grid>
        <Grid item xs={12} sx={{ ml: { xs: "0rem", md: "2rem", lg: "5rem" } }}>
          <Typography variant="textParagraph">
            Integrantes del Órgano de Administración / Dirección
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ ml: { xs: "0rem", md: "2rem", lg: "5rem" } }}>
          <Typography variant="textParagraph">
            <b>Recuerde: </b> Para finalizar este trámite, debe tener al menos
            un integrante cargado.
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          sx={{ placeContent: "center", mt: "2rem" }}
          spacing={2}
        >
          {name?.kindOfPerson === "JURIDICA" && (
            <Alert
              severity="warning"
              sx={{ width: { xs: "90%", md: "70%" }, alignItems: "center" }}
            >
              <Typography variant="textParagraph" sx={{ mb: "1rem" }}>
                El CUIT ingresado corresponde a una persona jurídica, debe
                ingresar una persona física.
              </Typography>
            </Alert>
          )}
          <Grid item sx={{ mt: 0, width: "50rem", mb: "" }}>
            <Grid item sx={{ mb: "1rem" }}>
              <Typography
                variant="textDescription"
                sx={{ color: "rgba(0,0,0,1)" }}
              >
                Ingrese el CUIT y presione Buscar:
              </Typography>
            </Grid>
            <Grid item display="flex">
              <InputMask
                mask="99-99999999-9"
                maskChar=""
                onChange={handleChangeCuit}
                type="number"
                value={cuit}
              >
                {() => (
                  <TextField
                    label="CUIT"
                    inputProps={maskedInputStyle}
                    error={formik.touched.cuit && Boolean(formik.errors.cuit)}
                    helperText={formik.touched.cuit && formik.errors.cuit}
                  />
                )}
              </InputMask>
              <button
                type="button"
                disabled={!cuitValid}
                aria-label="search"
                sx={{
                  backgroundColor: "#0869AB",
                  width: "4rem",
                  height: "4rem",
                  "&:hover": {
                    background: primaryColor,
                    color: "white",
                  },
                  "&:disabled": {
                    background: lightPrimaryColor,
                  },
                }}
                onClick={() => handleValidateCuit(cuit)}
                class="searchButton"
              >
                {isFetchingCuit ? (
                  <AppLoading size={2.5} />
                ) : (
                  <SearchIcon
                    fontSize="inherit"
                    sx={{
                      fontSize: "3rem",
                      color: !cuitValid ? "#045285" : "white",
                    }}
                  />
                )}
              </button>

            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          spacing={2}
          sx={{ placeContent: "center", mt: "0rem" }}
        >
          <Grid item sx={{ mt: 0, width: "30rem" }}>
            <TextField
              required
              id="memberName"
              name="memberName"
              type="text"
              placeholder="Nombre y Apellido"
              label="Nombre y Apellido"
              value={formik.values.memberName}
              onChange={formik.handleChange}
              error={
                formik.touched.memberName && Boolean(formik.errors.memberName)
              }
              helperText={formik.touched.memberName && formik.errors.memberName}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid item sx={{ mt: 0, width: "20rem" }}>
            <Autocomplete
              key={(option) => option.id}
              required
              disablePortal
              id="memberType"
              name="memberType"
              placeholder="Cargo"
              label="Cargo"
              value={formik.values?.memberType}
              options={memberTypes}
              onChange={(event, value) => {
                formik.setFieldValue(
                  "memberType",
                  value !== null ? value : { label: "", id: 0 }
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cargo"
                  error={
                    formik.touched.memberType &&
                    Boolean(formik.errors.memberType)
                  }
                  helperText={
                    formik.touched.memberType && formik.errors.memberType
                  }
                />
              )}
              noOptionsText="No se encontraron resultados"
            />
          </Grid>
          <Grid container direction="column" alignItems="center">
            <Grid
              container
              direction="row"
              spacing={2}
              sx={{ placeContent: "center", mt: "0rem" }}
            >
              <Grid item sx={{ mt: 0, width: "25rem", ml: "1.5rem" }}>
                <Autocomplete
                  id="real_province"
                  name="real_province"
                  disablePortal
                  value={formik.values.real_province}
                  key={(option) => option.id}
                  onChange={(event, value) => {
                    handleChange(value);
                    formik.setFieldValue(
                      "real_province",
                      value !== null ? value : { label: "", id: 0 }
                    );
                    formik.setFieldValue("real_locality", {
                      label: "",
                      id: 0,
                    });
                  }}
                  options={onlyProvincias || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        formik.touched.real_province &&
                        Boolean(formik.errors.real_province)
                      }
                      helperText={
                        formik.touched.real_province &&
                        formik.errors.real_province
                      }
                      label="Provincia"
                    />
                  )}
                  noOptionsText="No se encontraron resultados"
                />
              </Grid>
              <Grid item sx={{ mt: 0, width: "25rem" }}>
                <Autocomplete
                  id="real_locality"
                  name="real_locality"
                  disablePortal
                  value={formik.values?.real_locality}
                  key={(option) => option.id}
                  onChange={(event, newValue) => {
                    formik.setFieldValue(
                      "real_locality",
                      newValue !== null ? newValue : { label: "", id: 0 }
                    );
                  }}
                  options={onlyLocalidades || []}
                  renderOption={(props, option) => {
                    return (
                      <Box {...props} key={props?.id}>
                        {props?.key}
                      </Box>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        formik.touched.real_locality &&
                        Boolean(formik.errors.real_locality)
                      }
                      helperText={
                        formik.touched.real_locality &&
                        formik.errors.real_locality
                      }
                      label="Localidad"
                    />
                  )}
                  noOptionsText="No se encontraron resultados"
                />
              </Grid>
            </Grid>
            <Grid item sx={{ width: "50rem", mt: "1.7rem", pl: "1.5rem" }}>
              <TextField
                id="real_address"
                name="real_address"
                label="Domicilio"
                variant="outlined"
                value={formik.values.real_address}
                error={
                  formik.touched.real_address &&
                  Boolean(formik.errors.real_address)
                }
                helperText={
                  formik.touched.real_address && formik.errors.real_address
                }
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </Grid>
        {isFetching ? (
          <AppLoading size={5} />
        ) : (
          <Grid
            container
            direction="row"
            sx={{
              width: "60%",
              textAlign: "center",
              my: "3rem",
              ml: "20%",
              mr: "20%",
            }}
          >
            <Grid item xs={12} md={6} align="center">
              <Button
                variant="lightBlueBtn"
                sx={{
                  width: "12rem",
                  boxShadow: "0rem 0.4rem 0.4rem rgba(0, 0, 0, 0.25)",
                }}
                onClick={handleCleanFields}
              >
                Limpiar
              </Button>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <Button
                variant="blueBtn"
                disabled={
                  name?.kindOfPerson === "FISICA" &&
                  formik.values?.memberType?.label !== "" &&
                  formik.values?.memberType?.id !== 0
                    ? false
                    : true
                }
                sx={{
                  width: "12rem",
                  backgroundColor: "#0869AB",
                  boxShadow: "0rem 0.4rem 0.4rem rgba(0, 0, 0, 0.25)",
                }}
                type="submit"
              >
                Añadir
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          sx={{
            width: "90%",
            textAlign: "center",
            mb: "1rem",
            mr: "5%",
            ml: "5%",
          }}
        >
          <MembersUploadTable membersList={membersList} />
        </Grid>
        <Grid
          container
          direction="row"
          sx={{ width: "100%", textAlign: "center", mt: "2rem", mb: "2rem" }}
        >
          <Grid item xs={12} md={6} align="center">
            <Button
              variant="bold"
              onClick={() => {
                history.navigate(NEW_TRANSACT_STEP_THREE);
              }}
              sx={{
                align: "center",
                width: "20rem",
                mb: "2rem",
                mt: "2rem",
              }}
            >
              Volver
            </Button>
          </Grid>
          <Grid item xs={12} md={6} align="center">
            <Button
              disabled={membersList === null || membersList.length === 0}
              variant="success"
              sx={{ width: "20rem", mb: "2rem", mt: "2rem" }}
              onClick={handleContinue}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MembersUploadPage;
