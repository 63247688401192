import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../../../utils/utils";
import {
  validateTokenRecoveryAccount,
  sendTokenRecoveryAccountAsync,
  recoveryAccountAsync,
} from "../../../services/authService";
import { history } from "../../../_helpers/history";

const initialState = {
  isFetching: false,
  isError: false,
  token: "",
  UserID: "",
  email: {},
};

export const postValidateTokenRecoveryAccountAsync = createAsyncThunk(
  "passwordRecovery/validateTokenRecoveryAccountAsync",
  async (state, thunkAPI) => {
    try {
      const response = await validateTokenRecoveryAccount(state);
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message)
        return thunkAPI.rejectWithValue({
            error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          "Ocurrió un error validando el código...",
          response?.error?.response?.data?.error?.message
        );

        return thunkAPI.rejectWithValue(
          response?.response?.response?.data?.error?.message
        );
      }
      return response;
    } catch (error) {
      console.error("error: ", error);
    }
  }
);

export const postSendTokenRecoveryAccountAsync = createAsyncThunk(
  "passwordRecovery/sendTokenRecoveryAccount",
  async (state, thunkAPI) => {
    try {
      const response = await sendTokenRecoveryAccountAsync(state.email);
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message)
        return thunkAPI.rejectWithValue({
            error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError(
          "No se ha podido enviar el correo...",
          response?.error?.response?.data?.error?.message
        );

        return thunkAPI.rejectWithValue(
          response?.response?.response?.data?.error?.message
        );
      } else {
        showSuccess("Éxito al enviar el código",
          "Se ha enviado el código al email ingresado");
      }

      return response;
    } catch (error) {
      console.error("error: ", error);
    }
  }
);

export const putRecoveryAccountAsync = createAsyncThunk(
  "passwordRecovery/recoveryAccount",
  async (state, thunkAPI) => {
    try {
      const response = await recoveryAccountAsync(state);
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message)
        return thunkAPI.rejectWithValue({
            error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError("Ocurrió un error al cambiar la contraseña...", response?.error?.message);

        return thunkAPI.rejectWithValue(
          response?.error?.response?.data?.error?.message
        );
      }else{
        showSuccess("Éxito al cambiar contraseña",
          "Se ha cambiado la contraseña correctamente.");
      }
      return response;
    } catch (error) {
      console.error("error: ", error);
    }
  }
);

const passwordRecoverySlice = createSlice({
  name: "passwordRecovery",
  initialState,
  reducers: {
    userData: (state, action) => {
      state.UserID = action.payload.UserID;
      state.token = action.payload.token;
      state.email = action.payload.email;
    },
  },
  extraReducers: (builder) => {
    //POST validate code 4 digits
    builder.addCase(
      postValidateTokenRecoveryAccountAsync.fulfilled,
      (state, action) => {
        state.token = action.payload;
        state.isFetching = false;
        state.isError = false;
        history.navigate("/forgot-password-step-three");
      }
    );
    builder.addCase(
      postValidateTokenRecoveryAccountAsync.pending,
      (state, action) => {
        state.isFetching = true;
      }
    );
    builder.addCase(
      postValidateTokenRecoveryAccountAsync.rejected,
      (state, action) => {
        state.isError = true;
        state.isFetching = false;
      }
    );
    //POST send token code 4 digits validation
    builder.addCase(
      postSendTokenRecoveryAccountAsync.fulfilled,
      (state, action) => {
        //fetching the user id
        state.UserID = action?.payload;
        //fetching the user email
        state.email = action?.meta?.arg?.email;
        state.isFetching = false;
        state.isError = false;
        history.navigate("/forgot-password-step-two");
      }
    );
    builder.addCase(
      postSendTokenRecoveryAccountAsync.pending,
      (state, action) => {
        state.isFetching = true;
      }
    );
    builder.addCase(
      postSendTokenRecoveryAccountAsync.rejected,
      (state, action) => {
        state.isError = true;
        state.isFetching = false;
      }
    );

    //PUT recovery account, change password
    builder.addCase(putRecoveryAccountAsync.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isError = false;

      history.navigate("/login");
    });
    builder.addCase(putRecoveryAccountAsync.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(putRecoveryAccountAsync.rejected, (state, action) => {
      state.isError = true;
      state.isFetching = false;
    });
  },
});

export const { userData } = passwordRecoverySlice.actions;

export default passwordRecoverySlice.reducer;