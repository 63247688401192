import { httpPost, handleHttpError, httpGet, httpPatch } from "./httpService";
import { buildDefaultOptions } from "./authService";

// import config from "../config";
import {env} from "../env";
const URL = `${env.API__BACKEND}/api`;


export const patchFinishProcedure = async (checkCode) => { 
    let uri = `${URL}/v2/Procedures/finish/${encodeURIComponent(checkCode)}`;
  
    try{
      let response = await httpPatch(uri, {});
      return response.data;
    } catch(error){
      return handleHttpError(error);
    }
  };

export const postProcedures = async (accessToken, data) => {
  let getOptions = buildDefaultOptions(accessToken);
  let uri = `${URL}/v3/Procedures`;

  try {
    let response = await httpPost(uri, data, getOptions);
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const getProcedureById = async (accessToken, id) => {
  let getOptions = buildDefaultOptions(accessToken);
  let uri = `${URL}/v2/Procedures/${id}`;

  try {
    let response = await httpGet(uri, getOptions);
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const changeProcedureState = async ( accessToken, procedureId ) => {
  let getOptions = buildDefaultOptions(accessToken);
  let uri = `${URL}/v2/Procedures/${procedureId}/state/7`;

  try {
    let response = await httpPatch(uri, {}, getOptions);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
}

export const patchDesistProcedure = async (checkCode) => {
  let uri = `${URL}/v2/Procedures/desist/${encodeURIComponent(checkCode)}`;

  try {
    let response = await httpPatch(uri, {});
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
}

export const patchRequestChanges = async (checkCode) => {
  let uri = `${URL}/v2/Procedures/draft/${encodeURIComponent(checkCode)}`;

  try {
    let response = await httpPatch(uri, {});
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
}

// Resend email to validate requirements in Requirements Review 
export const resendEmailToValidate = async(
  accessToken,
  procedureId
) => {
  let getOptions = buildDefaultOptions(accessToken);
  const URL = `${env.API__BACKEND}/api`;
  let uri = `${URL}/v2/Procedures/SendEmailToValidate/${procedureId}`;

  try {
    let response = await httpPatch(uri, null, getOptions);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
}