import { isNull, isUndefined } from 'lodash';
import moment from 'moment';

export const isWeekday = (date) => {
    date = moment(date);
    const day = date.isoWeekday();
    return day !== 6 && day !== 7;
  };

export  const isNumeric = (num) => {
    return !isNaN(num);
}

export const isEmptyObject = (obj) => {
  for(var prop in obj) {
      if(obj.hasOwnProperty(prop)) return false;
  }
  return true;
}

export const isNonEmptyArray = value => (Array.isArray(value) && value.length>0);

export const isBoolean = value => ( typeof value === 'boolean' );

export const isEmptyString = value => !(value && typeof value === 'string' && value.trim() && value.trim().length>0);

export const idAdminProfile = (profile) => {
  if ( isUndefined(profile) || isNull(profile) ){return false;}
  return profile.toUpperCase() === "Admin BackOffice".toUpperCase();
}