const config = {
  API__AUTHAPI: "https://dev.api.auth.proveedores.misionescontaduria.com/v2",
  API__BACKEND: "https://dev.v2.api.proveedores.misionescontaduria.com",
  API__PORTAL: "https://dev.api.backend.proveedores.misionescontaduria.com",
  MAINTENANCE: false,
  ENVIRONMENT__NAME: "LOCAL", //DEVELOPMENT|Entorno de Pruebas|PREPRODUCTION|PRODUCCION
  AFIP__STATUS: {
    hasError: false, // true, false
    type: "warning", // warning, error, info
    title: "AFIP presenta problemas",
    description:
      "AFIP presenta demoras al consultar su servicio, por favor intente más tarde.",
  },
};

export const env = { ...config, ...window["env"] };
