import { mapToHttpBody } from "./formatService";
import { httpPost, httpPut, handleHttpError, httpGet } from "./httpService";

import {env} from "../env";

const AUTH_API_APPLICATION_ID = 4
const AUTH_API_CLIENT_ID = 1
const AUTH_API_HEADERS = {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
}
const AUTHAPI_REGISTER_ENDPOINT = "/users/CreateUser"
const AUTHAPI_AUTHENTICATION_ENDPOINT = "/authentication"
const USERS = "/users";

export const buildAuthorization = (accessToken) => `Bearer ${accessToken}`;

export const buildDefaultOptions = (accessToken) => {
  return {
    headers: {
      Authorization: buildAuthorization(accessToken),
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS, PATCH",
      "Access-Control-Allow-Headers": "Origin, Accept, Content-Type, Authorization, X-Requested-With",
    },
  };
};

export const authenticate = async (username, password) => {
  const URL = env.API__AUTHAPI + AUTHAPI_AUTHENTICATION_ENDPOINT;
  const details = {
    mode: "no-cors",
    grant_type: "password",
    email: username.toLowerCase(),
    password: password,
    client_id: AUTH_API_CLIENT_ID,
    app_id: AUTH_API_APPLICATION_ID,
  };

  const body = mapToHttpBody(details);

  var request = {
    headers: AUTH_API_HEADERS,
  };
  
  try {
    let response = await httpPost(URL, body, request);
    return response?.data;
  } catch (error) {
    return handleHttpError(error);
  }
};

//POST validar codigo de 4 digitos de usuario con backend
export const validateTokenRecoveryAccount = async (props) => {
  
  const AppId = AUTH_API_APPLICATION_ID;
  const {token, UserID} = props;

  var options = {
    headers: {
      ...AUTH_API_HEADERS,
      "Content-Type": "application/json",
    },
  };

  const params = {
    clientId: AUTH_API_CLIENT_ID,
    appId: AppId,
  }

  const URL = env?.API__AUTHAPI + USERS + `/ValidateTokenRecoveryAccount?UserID=${UserID}&token=${encodeURIComponent(token)}&appId=${params.appId}&clientId=${params.clientId}`

  try {
    let response = await httpPost(URL, options);
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
}

//POST Enviar codigo 4 digitos al email de usuario
export const sendTokenRecoveryAccountAsync = async (email) => {
  const AppId = AUTH_API_APPLICATION_ID;
  const params = {
      appId: AppId,
      UserEmail: email.toLowerCase(),
      clientId: AUTH_API_CLIENT_ID,
    };

  const URL = env.API__AUTHAPI + USERS + `/SendTokenRecoveryAccount?UserEmail=${encodeURIComponent(email)}&appId=${params.appId}&clientId=${params.clientId}`;

  var options = {
    headers: {
      ...AUTH_API_HEADERS,
      "Content-Type": "application/json",
    },
  };

  try {
    let response = await httpPost(URL, options);    
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const changePassword = async (accessToken, userID, oldPsw, newPsw) => {
  const AppId = AUTH_API_APPLICATION_ID;
  const URL = `${env.API__AUTHAPI}/users/ChangePassword`;
  
  let options = buildDefaultOptions(accessToken);

  let pswReqBody = {
    "userId" : userID,
    "oldPass" : oldPsw,
    "newPass" : newPsw,
    "appId": AppId,
  }

  try {
    let response = await httpPut(URL, pswReqBody, options);
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const recoveryAccountAsync = async (props) => {
  const { UserID, NewPassword } = props;
  const AppId = AUTH_API_APPLICATION_ID;

  const URL = env.API__AUTHAPI + USERS + "/RecoveryAccount";

  const options = {
    headers: {
      ...AUTH_API_HEADERS, 
      "Content-Type":"application/json"
    }
  };

  let pswReqBody = {
    "userId": UserID,
    "newPass": NewPassword,
    "appId":AppId
  }

  try {
    let response = await httpPut(URL, pswReqBody, options);
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const createAccount = async (user) => {
  const URL = `${env.API__AUTHAPI}${AUTHAPI_REGISTER_ENDPOINT}?profileId=49&clientID=1&appID=4`;

  var options = {
    headers: {
      ...AUTH_API_HEADERS,
      "Content-Type": "application/json",
    },
  };

  try {
    let response = await httpPost(URL, user, options);
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const sendValidationToken = async (token, hash) => {
  const URL = `${env.API__AUTHAPI}/users/validation-Token-Email?identifier=${encodeURIComponent(hash)}&token=${encodeURIComponent(token)}`;

  var options = {
    headers: {
      ...AUTH_API_HEADERS,
      "Content-Type": "application/json",
    },
  };

  try {
    let response = await httpGet(URL, options);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
}

export const resendValidationToken = async (hash) => {
  const URL = `${env.API__AUTHAPI}/users/SendEmailValidationToken?Identifier=${encodeURIComponent(hash)}`;

  var options = {
    headers: {
      ...AUTH_API_HEADERS,
      "Content-Type": "application/json",
    },
  };

  try {
    let response = await httpPost(URL, null, options);
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
}

export const googleLogin = async (credential) => {
  const URL = env.API__AUTHAPI + AUTHAPI_AUTHENTICATION_ENDPOINT + "/ExternalLogin";

  var options = {
    headers: {
      ...AUTH_API_HEADERS,
      "Content-Type": "application/json",
    }
  }

  try {
    let response = await httpPost(URL, credential, options);
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
}