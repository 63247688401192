import {
  Typography,
  Grid,
  Button,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Container,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../_helpers/history";
import { capitalizeTitle, maskCUIT } from "../../utils/utils.js";
import {
  selectProcedure,
  tryGetAddresses,
  tryGetEconomicActivities,
  tryGetEmails,
} from "../../features/newTransact/newTransactSlice";
import AppLoading from "../common/appLoading";
import { NEW_TRANSACT } from "../../routing/constants";

function createData(name, data) {
  return { name, data };
}

export default function NewTransactStepTwo(props) {
  const dispatch = useDispatch();
  const [selectedTransact, setSelectedTransact] = useState("");
  const selectedPerson = useSelector(
    (state) => state?.newTransact?.selectedPerson
  );
  const isFetching = useSelector((state) => state?.newTransact?.isFetching);
  const isError = useSelector((state) => state?.newTransact?.isError);
  const availableTransactTypes = useSelector(
    (state) => state?.newTransact?.availableTransactTypes
  );

  const rows = [
    createData("CUIT", maskCUIT(selectedPerson?.cuit)),
    createData("Apellido y Nombre / Razón Social", selectedPerson?.name),
    createData("Tipo de Persona", selectedPerson?.kindOfPerson),
    createData("Nombre de Fantasía", selectedPerson?.name),
  ];

  const handleSelectTransact = (transact) => {
    setSelectedTransact(transact);
  };

  const handleContinue = () => {
    dispatch(selectProcedure(selectedTransact));
    dispatch(tryGetAddresses());
    dispatch(tryGetEconomicActivities());
    dispatch(tryGetEmails());
  };

  const handleCancel = () => {
    history.navigate(NEW_TRANSACT);
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      variant="custom"
    >
      <Grid
        sx={{ width: "60vw", justifyContent: "left", textAlign: "left" }}
        container
        spacing={0}
      >
        <Grid item xs={8} md={10} sx={{ padding: "2rem" }}>
          <Typography
            variant="title"
            sx={{ fontSize: "2.4rem", fontWeight: 400 }}
          >
            Detalle de la persona
          </Typography>
        </Grid>
        <TableContainer>
          <Table align="center" sx={{ width: "60%" }}>
            <TableHead></TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontSize: "1.6rem", fontWeight: 400 }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "1.4rem", color: "#1A3762" }}
                  >
                    {row.data}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ textAlign: "center", justifyContent: "center" }}
      >
        <br></br>
        <br></br>
        <Grid
          sx={{
            justifyContent: "center",
            textAlign: "center",
            padding: "1rem",
          }}
          container
          spacing={0}
        >
          <Grid item xs={8} md={10} sx={{ padding: "2rem", mt: "2rem" }}>
            <Typography
              variant="title"
              sx={{ fontSize: "2rem", fontWeight: 400 }}
            >
              Trámites Disponibles
            </Typography>
          </Grid>
          {
            //Loading case
            isFetching ? (
              <Container align="center">
                <AppLoading size={5} />
              </Container>
            ) : //Error case
            isError ? (
              <Typography variant="title3">
                No se han podido recuperar los trámites disponibles.
              </Typography>
            ) : //Empty case
            availableTransactTypes === undefined ||
              availableTransactTypes?.length < 1 ? (
              <Grid item xs={8} md={10} sx={{ padding: "2rem" }}>
                <Typography variant="title3">
                  El proveedor se encuentra con un trámite activo.
                </Typography>
              </Grid>
            ) : (
              //Normal case
              availableTransactTypes?.map((availableTransactTypes) => (
                <Grid item xs={8} md={10} key={availableTransactTypes?.id}>
                  <Button
                    key={availableTransactTypes?.id}
                    onClick={() =>
                      handleSelectTransact(availableTransactTypes?.id)
                    }
                    variant={
                      selectedTransact === availableTransactTypes?.id
                        ? "blueBtn"
                        : "lightBlueBtn"
                    }
                    sx={{ width: "27.5rem", height: "4rem", my: "0.5rem" }}
                  >
                    {capitalizeTitle(availableTransactTypes?.name)}
                  </Button>
                </Grid>
              ))
            )
          }
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        marginTop={5}
        marginBottom={5}
        sx={{
          textAlign: "center",
          align: "center",
          justifyContent: "-webkit-center",
          placeContent: { xs: "center" },
        }}
      >
        <Grid item xs={8} md={10}>
          <Button
            onClick={handleCancel}
            variant="bold"
            sx={{
              width: "20rem",
              position: { md: "fixed" },
              left: { md: "18vw" },
              bottom: { md: "5vh" },
            }}
          >
            Volver
          </Button>
        </Grid>
        <Grid item xs={8} md={10}>
          <Button
            onClick={handleContinue}
            variant="success"
            sx={{
              width: "20rem",
              position: { md: "fixed" },
              right: { md: "18vw" },
              bottom: { md: "5vh" },
            }}
            disabled={
              !selectedTransact ||
              isFetching ||
              !(availableTransactTypes?.length > 0)
            }
          >
            Continuar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
