import { handleHttpError, httpGet } from "./httpService";
import { buildDefaultOptions } from "./authService";

import { env } from "../env";
const URL = `${env.API__BACKEND}/api`;

export const getProvinces = async (
  accessToken,
  sortOptions = { pageSize: 50, page: 1, sortCriteria: "name", sortOrder: "ASC" }
) => {
  let uri = `${URL}/v2/Provinces?pageSize=${sortOptions?.pageSize}&page=${sortOptions?.page}&sortCriteria=${sortOptions?.sortCriteria}&sortOrder=${sortOptions?.sortOrder}`;
  let getOptions = buildDefaultOptions(accessToken);

  try {
    let response = await httpGet(uri, getOptions);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const getLocalities = async (
  accessToken,
  id,
  sortOptions = { pageSize: 100000, page: 1, sortCriteria: "name", sortOrder: "ASC" }
) => {
  let uri = `${URL}/v2/Provinces/${id}/localities?pageSize=${sortOptions?.pageSize}&page=${sortOptions?.page}&sortCriteria=${sortOptions?.sortCriteria}&sortOrder=${sortOptions?.sortOrder}`;
  let getOptions = buildDefaultOptions(accessToken);
  try {
    let response = await httpGet(uri, getOptions);
    return response;
  } catch (error) {
    return handleHttpError(error);
  }
};
