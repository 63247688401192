import { Typography, Grid, Card, SvgIcon, Button } from "@mui/material";
import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { CloudUpload } from "@mui/icons-material";
import { showWarning } from "../../utils/utils";
import {
  postRequirementFile,
  tryDownloadMunicipalAuthorization,
  tryDownloadRequirement,
} from "../pages/requirementsPage/requirementsSlice";
import { useDispatch, useSelector } from "react-redux";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AppLoading from "./appLoading";
import { Box } from "@mui/system";
import { postMunicipalAuthorizationFile } from "../pages/municipalAuthorizationPage/municipalAuthorizationSlice";
import WarningIcon from "../../assets/icons/warningIcon.png";

function mockData(files) {
  return {
    DataTransfer: {
      files,
      items: files.map((file) => ({
        kind: "file",
        type: file.type,
        getAsFile: () => file,
      })),
      types: ["Files"],
    },
  };
}

export default function DragAndDrop(props) {
  const dispatch = useDispatch();
  const [readerFile, setReaderFile] = useState("");
  const { fileId, requirementId, fileExists, isMunicipal, observation } = props;
  const [isLoading, setIsLoading] = useState({});
  const hasAuthorizationFile = useSelector(
    (state) => state?.municipalAuthorization?.fileId
  );

  const onDropAccepted = useCallback((acceptedFiles) => {
    const reader = new FileReader();
    var bodyFormData = new FormData();

    reader.onabort = () => console.log("Lectura de archivo abortada");
    reader.onerror = () => console.log("Lectura de archivo fallida");
    reader.readAsArrayBuffer(acceptedFiles[0]);
    const mockFile = mockData([acceptedFiles[0]]);
    setReaderFile(mockFile?.DataTransfer?.files[0].path);
    // TODO: revisar esto
    //var blob = new Blob([binaryStr], {});
    if (isMunicipal) {
      bodyFormData.append(
        "file",
        acceptedFiles[0],
        mockFile?.DataTransfer?.files[0].path
      );
    } else {
      bodyFormData.append("id", fileId);
      bodyFormData.append(
        "file",
        acceptedFiles[0],
        mockFile?.DataTransfer?.files[0].path
      );
    }

    reader.onload = () => {
      if (isMunicipal) {
        setIsLoading({ ...isLoading, [fileId]: true });
        dispatch(postMunicipalAuthorizationFile(bodyFormData)).then(() => {
          setIsLoading({ ...isLoading, [fileId]: false });
        });
      } else {
        setIsLoading({ ...isLoading, [fileId]: true });
        dispatch(postRequirementFile(bodyFormData)).then(() => {
          setIsLoading({ ...isLoading, [fileId]: false });
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      // "image/jpeg": [".jpg", ".jpeg", ".png"],
      text: [".pdf"],
    },
    multiple: false,
    maxSize: 15000000,
    onDropAccepted,
    onDropRejected: () => {
      showWarning("Formato o tamaño de archivo inválido", "");
    },
  });

  const handleDownloadFile = (id) => {
    if (isMunicipal) {
      dispatch(tryDownloadMunicipalAuthorization(hasAuthorizationFile));
    } else {
      dispatch(tryDownloadRequirement(id));
    }
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      variant="custom"
      sx={{ mb: "2rem" }}
    >
      <Grid sx={{ mb: "1rem" }}>
        {observation ? (
          <Typography
            variant="title3"
            sx={{
              fontWeight: 400,
              textAlign: "left",
              color: "#FE0000",
              fontSize: "1.6rem",
            }}
          >
            <img
              src={WarningIcon}
              alt="error icon"
              width="5%"
              height="5%"
              style={{ paddingBottom: "0.5rem" }}
            />
            {` - ${observation}`}
          </Typography>
        ) : (
          ""
        )}
      </Grid>

      <Card
        variant="dragCard"
        sx={{
          background:
            readerFile &&
            "radial-gradient(circle, rgba(178,231,185,0.3) 0%, rgba(143,193,123,0.3) 100%)",
        }}
      >
        <Grid item xs={12} md={12} sx={{ pt: "1rem", textAlign: "center" }}>
          <SvgIcon
            component={CloudUpload}
            sx={{
              fontSize: "4rem",
              color: readerFile ? "#1A3762" : "#1A3762",
            }}
          ></SvgIcon>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ padding: "10px", textAlign: "center" }}
        >
          <div
            {...getRootProps({ className: "dropzone" })}
            id={`fileDiv${fileId}`}
          >
            <input {...getInputProps()} type="file" id={`fileItem${fileId}`} />
            {fileExists ? (
              <Typography variant="textDescription" sx={{ fontSize: "1.6rem" }}>
                Existe un archivo cargado, arrastre para subir uno nuevo
              </Typography>
            ) : (
              <Typography variant="textDescription" sx={{ fontSize: "1.6rem" }}>
                Arrastre aquí el archivo para subirlo
              </Typography>
            )}
            <br />
            <Typography variant="textDescription" sx={{ fontSize: "1.6rem" }}>
              o
            </Typography>
            <br />
            <Button variant="blueBtn">Seleccionar archivo</Button>
          </div>
        </Grid>
        <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
          <Typography
            variant="textDescription"
            sx={{ fontSize: "1.6rem", color: "#677890" }}
          >
            Formato admitido: PDF hasta 15MB
            {/* Formatos admitidos: PDF, PNG, JPG o JPEG hasta 15MB */}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ pb: "1rem", pt: "0.5rem", textAlign: "center" }}
        >
          <Typography
            variant="textDescription"
            sx={{
              fontSize: "1rem",
              border: readerFile && "0.1rem #1A3762 solid",
              borderRadius: "1rem",
              pl: "2rem",
              pr: "2rem",
            }}
          >
            {readerFile ? readerFile : ""}
          </Typography>
        </Grid>
      </Card>
      {fileExists && !isLoading[fileId] ? (
        <Button
          variant="bold"
          sx={{
            borderRadius: "10rem",
            mt: "2rem",
            background: "rgba(0, 100, 0, 0)",
            boxShadow: "0rem 0rem 0rem rgba(0, 0, 0, 0)",
            fontSize: "1.6rem",
          }}
          onClick={() => {
            handleDownloadFile(requirementId);
          }}
        >
          <SvgIcon
            component={FileDownloadIcon}
            sx={{
              mr: "1rem",
              fontSize: "2rem",
              color: "#1A3762",
            }}
          ></SvgIcon>
          Descargar archivo
        </Button>
      ) : (
        isLoading[fileId] && (
          <Box sx={{ mt: "0rem" }}>
            <AppLoading size={5} styles={{ margin: "2rem 0 -1rem 0" }} />
          </Box>
        )
      )}
      {hasAuthorizationFile !== "" && !isLoading[fileId] && isMunicipal ? (
        <Button
          variant="bold"
          sx={{
            borderRadius: "1rem",
            mt: "1rem",
            background: "rgba(0, 100, 0, 0)",
            boxShadow: "0rem 0rem 0rem rgba(0, 0, 0, 0)",
            fontSize: "1.6rem",
          }}
          onClick={() => {
            handleDownloadFile(hasAuthorizationFile);
          }}
        >
          <SvgIcon
            component={FileDownloadIcon}
            sx={{
              mr: "1rem",
              fontSize: "2rem",
              color: "#1A3762",
            }}
          ></SvgIcon>
          Descargar archivo
        </Button>
      ) : (
        isLoading[fileId] &&
        isMunicipal && (
          <Box sx={{ mt: "0rem" }}>
            <AppLoading size={5} styles={{ margin: "2rem 0 -1rem 0" }} />
          </Box>
        )
      )}
    </Grid>
  );
}
