import { handleHttpError, httpGet } from "./httpService";
import { buildDefaultOptions } from "./authService";

import {env} from "../env";
const URL = `${env.API__BACKEND}/api/v1`;

//TODO: Mismo servicio que getFile() refactorizar para consumir uno solo
export const getProviderConstancy = async (accessToken, key) => {
    let getOptions = buildDefaultOptions(accessToken);
    getOptions.responseType = "blob";
    let uri = `${URL}/Files/Download/${key}`;

    // let options = {
    //     responseType: "blob"
    // }

    try {
        let response = await httpGet(uri, getOptions);
        return response;
    } catch (error) {
        return handleHttpError(error);
    }
}

export const getAvailableTicketsTypes = async (accessToken, cuit) => {
    let getOptions = buildDefaultOptions(accessToken);

    let uri = `${URL}/Providers/AvailableTickets?cuit=${cuit}`;

    try {
        let response = await httpGet(uri, getOptions);
        return response?.data?.availableTickets;
    } catch (error) {
        return handleHttpError(error);
    }
}