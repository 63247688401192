import { handleHttpError, httpGet } from "./httpService";
import { buildDefaultOptions } from "./authService";

import {env} from "../env";
const URL = `${env.API__BACKEND}/api`;

export const getApiVersions = async(token) => {
  const uri = `${URL}/v1/AssamblyInfo`
  let getOptions = buildDefaultOptions(token);

  try {
    let response = await httpGet(uri, getOptions);
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
}