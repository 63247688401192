import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { changePassword } from "../../../services/authService";
import { showError, showSuccess } from "../../../utils/utils";
import { history } from "../../../_helpers/history";

const initialState = {
  id: "",
  newPassword: "",
  isFetching: false,
  hasError: false,
};

export const putChangePassword = createAsyncThunk(
  "passwordChange/tryChangePassword",
  async (state, thunkAPI) => {
    const token = thunkAPI.getState().login?.authentication?.access_token;
    const userId = thunkAPI.getState().login?.authentication?.userid;
    try {

      const response = await changePassword(
        token,
        userId,
        state.oldPsw,
        state.newPsw
      );
      if (response?.status === 418 || response?.status === 500) {
        showError("Error del servidor.", response?.error?.message)
        return thunkAPI.rejectWithValue({
            error: response?.error?.message,
        });
      }
      if (response.hasOwnProperty("error") && response?.error != null) {
        showError("No se ha podido cambiar la contraseña...",
          response?.error?.response?.data?.error?.message);
          return thunkAPI.rejectWithValue(response?.error?.message);
        }else{
          showSuccess(
          "Éxito al cambiar su contraseña",
          "Se ha cambiado su contraseña con éxito...");
        }

      return response;
    } catch (err) {}
  }
);

const passwordChangeSlice = createSlice({
  name: "passwordChange",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // PUT NEW PASSWORD
    builder.addCase(putChangePassword.fulfilled, (state, action) => {
      state.isFetching = false;
      state.hasError = false;
      history.navigate("/profile");
    });
    builder.addCase(putChangePassword.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(putChangePassword.rejected, (state, action) => {
      state.hasError = true;
      state.isFetching = false;
    });
  },
});

export default passwordChangeSlice.reducer;