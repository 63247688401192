import { handleHttpError, httpGet } from "./httpService";
import { buildDefaultOptions } from "./authService";
import { showError } from "../utils/utils";
import { env } from "../env";

//get the selected person economic activities
export const getEconomicActivities = async (
  accessToken,
  personId,
  sortOptions = { pageSize: 50, page: 1, sortCriteria: "code", sortOrder: "ASC" }
) => {
  const URL = `${env.API__BACKEND}/api`;
  let getOptions = buildDefaultOptions(accessToken);

  let uri = `${URL}/v1/EconomicActivities/personId/${personId}?pageSize=${sortOptions?.pageSize}&page=${sortOptions?.page}&sortCriteria=${sortOptions?.sortCriteria}&sortOrder=${sortOptions?.sortOrder}`;

  try {
    let response = await httpGet(uri, getOptions);
    return response.data?.data;
  } catch (error) {
    showError("No se pudieron obtener las actividades económicas.");
    return handleHttpError(error);
  }
};
