import { Typography, Grid, Box, Button, TextField } from "@mui/material";
import LoginStructure from "../../common/loginStructure";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import AppLoading from "../../common/appLoading";
import { history } from "../../../_helpers/history";
import { tryRequestAccountDeletion } from "./deleteAccountSlice";
import banner from "../../../assets/images/logo_contaduria.png";
import Icons from "../../../assets/icons/Icons";
import { Image } from "react-bootstrap";

const DeleteAccountPage = (props) => {
  const dispatch = useDispatch();
  const isFetching = useSelector((state) => state?.deleteAccount?.isFetching);
  const successRequest = useSelector(
    (state) => state?.deleteAccount?.successRequest
  );

  const validationSchema = yup.object({
    email: yup
      .string()
      .trim()
      .email("Ingrese un email válido")
      .required("Debe ingresar un correo electrónico"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      const data = {
        email: values?.email,
      };
      dispatch(tryRequestAccountDeletion(data?.email));
    },
    validationSchema: validationSchema,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <LoginStructure>
          <Grid
            sx={{
              "&:hover": { cursor: "pointer" },
              marginBottom: "0rem",
              height: "50px",
              width: "auto",
              overflow: "hidden",
              transition: "height 0.5s ease",
              display: { xs: "flex", md: "none" },
              justifyContent: "center",
              mb: "5rem",
            }}
          >
            <Image
              src={banner}
              alt="logo"
              width="80"
              height="50"
              sx={{
                flexGrow: 1,
              }}
            />
            <Image
              src={Icons.misionesIconAlt}
              alt="logo"
              width="35"
              sx={{
                flexGrow: 1,
              }}
            />
          </Grid>
          <Grid
            container
            spacing={4}
            sx={{
              justifyContent: "start",
              pl: { xs: "2rem", md: "0rem" },
              pr: { xs: "2rem", md: "8rem" },
            }}
          >
            <Grid item textAlign="left">
              <Typography
                variant="title"
                sx={{ fontSize: { xs: "1.6rem", md: "2.4rem" } }}
              >
                Solicitud de Eliminación de Cuenta
              </Typography>
            </Grid>
            <Grid item sx={{ textAlign: "start" }}>
              <div>
                <Typography
                  variant="textParagraph"
                  sx={{ width: "60%", mt: "1.5rem", fontSize: "1.4rem" }}
                >
                  Lamentamos que quiera irse. Antes de proceder con la
                  eliminación de su cuenta, verificaremos si existen trámites
                  pendientes vinculados a las personas asociadas a su cuenta.
                  Una vez que dichos trámites hayan sido resueltos—ya sea
                  aceptados, denegados, anulados o desistidos—se procederá con
                  la eliminación definitiva de su cuenta. Los proveedores que
                  hayan sido aceptados permanecerán en ese estado.
                </Typography>
              </div>
              <div style={{ marginTop: "2rem" }}>
                <Typography
                  variant="textParagraph"
                  sx={{ width: "60%", mt: "1.5rem", fontSize: "1.4rem" }}
                >
                  El correo electrónico registrado en AFIP se asociará como
                  gestor, incluso si no cuenta con un usuario en el sistema, y
                  luego se procederá a eliminar su cuenta.
                </Typography>
              </div>
              <div style={{ marginTop: "2rem" }}>
                <Typography
                  variant="textParagraph"
                  sx={{ width: "60%", mt: "1.5rem", fontSize: "1.4rem" }}
                >
                  Por favor, ingrese su correo electrónico para procesar su
                  solicitud de eliminación de cuenta:
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid item sx={{ textAlign: "center" }}>
            <Box
              component="form"
              noValidate
              sx={{
                mt: "3rem",
                mb: "0rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: { xs: "center", md: "start" },
                px: { xs: "2rem", md: "0rem" },
              }}
              onSubmit={formik.handleSubmit}
              container
              spacing={4}
            >
              <Grid
                textAlign="left"
                sx={{ width: { xs: "100%", sm: "80%", md: "60%" } }}
              >
                <TextField
                  autoFocus
                  name="email"
                  type="email"
                  label="Correo electrónico"
                  id="email"
                  autoComplete="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  InputLabelProps={{ shrink: true }}
                  disabled={successRequest || isFetching}
                />
              </Grid>
              <Button
                disabled={successRequest || isFetching}
                variant="success"
                type="submit"
                sx={{
                  mt: 1,
                  mb: 2,
                  width: { xs: "100%", sm: "80%", md: "15rem" },
                }}
              >
                {isFetching ? <AppLoading size={3} /> : "Enviar"}
              </Button>
            </Box>
          </Grid>
        </LoginStructure>
      </Grid>
    </Grid>
  );
};

export default DeleteAccountPage;
