export const downloadStreamFile = (response, partialFileName) => {
    // Response data
    const headers = response?.headers;
    const streamFile = response?.data;
    const mimeType = getContentTypeHeader(headers);
    
    
    // File attributes
    const today = new Date();
    const dateString = `${today.getFullYear()}_${today.getMonth() + 1}_${today.getDate()}`;
    const fileExtension = extractFileExtensionFromContentType(mimeType);
    let fileNamePartial;
    
    if (fileExtension === 'xlsx') {
        fileNamePartial = `${dateString}-${partialFileName}`;
    } else {
        fileNamePartial = `${dateString}-${partialFileName}.${fileExtension}`;
    }
    const fileName = fileNamePartial;
    
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([streamFile], { type: mimeType })
    
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
    }
    
    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.click();
    setTimeout(function () {
        // For Firezox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
    }, 100);
};

export const openStreamPDFFile = streamPdf => {
    //Create a Blob from the PDF Stream
    const file = new Blob(
        [streamPdf],
        { type: 'application/pdf' });

    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);

    //Open the URL on new Window
    window.open(fileURL);
}

export const getContentTypeHeader = headers => (headers["Content-Type"] || headers["content-type"]);

export const extractFileExtensionFromContentType = mimeType => {
    switch (mimeType) {
        case 'application/pdf': {
            return 'pdf';
        }
        case 'application/vnd.ms-excel': {
            return 'xls';
        }
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
            return 'xlsx';
        }
        case 'image/png': {
            return 'png';
        }
        case 'image/jpeg': {
            return 'jpg';
        }
        case 'text/plain': {
            return 'txt';
        }
        case 'application/zip' : {
            return 'zip';
        }
        default: {
            return 'txt';
        }
    }
};
